import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken, isSupported } from "firebase/messaging";
import { checkFirebaseTokenRequest, setFirebaseTokenRequest } from "./api/controllers/account-controller";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_FIREBASE_API_URL,
    authDomain: "turmetre-a95be.firebaseapp.com",
    projectId: "turmetre-a95be",
    storageBucket: "turmetre-a95be.appspot.com",
    messagingSenderId: "425895938059",
    appId: "1:425895938059:web:24aa3bda0aa3eb34be1f0e",
    measurementId: "G-PZQQYPLJ1Q"
};

const firebaseApp = initializeApp(firebaseConfig);

const messaging = (async () => {
    try {
        const isSupportedBrowser = await isSupported();
        if (isSupportedBrowser) {
            return getMessaging(firebaseApp);
        }
        console.log('Firebase not supported this browser');
        return null;
    } catch (err) {
        console.log(err);
        return null;
    }
})();

export const onMessageListener = async () => {
    return new Promise((resolve) =>
        (async () => {
            const messagingResolve = await messaging;
            onMessage(messagingResolve, (payload) =>
                resolve(payload)
            );
        })()
    );
}

export const requestPermission = async () => {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
        Notification.requestPermission().then(async (permission) => {
            if (permission === "granted") {
                try {
                    const messagingResolve = await messaging;
                    const currentToken = await getToken(messagingResolve, {
                        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY,
                    });
                    if (currentToken) {
                        //console.log("firebaseToken: ", currentToken);
                        const hasToken = await checkFirebaseTokenRequest().then(v => v.data);
                        if (hasToken !== currentToken) {
                            //console.log("hasToken: ", hasToken);
                            await setFirebaseTokenRequest(currentToken)
                        }
                    }
                } catch (err) { };
            } else {
                console.log("Do not have permission!");
            }
        });
    }
}