import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import styles from '../../tabs.module.scss';
import { useCustomer } from '../../../../../context/CustomerContext';
import UpdateStudent from './UpdateStudent';
import UpdateEmployee from './UpdateEmployee';
import { getStudentByIdRequest } from '../../../../../api/controllers/student-controller';
import { getEmployeeByIdRequest } from '../../../../../api/controllers/employee-controller';
import { useParams } from 'react-router';

export default function UpdateTraveler() {
    const { customer } = useCustomer();
    const { travelerId } = useParams();
    const [traveller, setTraveller] = useState(null);

    const getTraveller = async () => {
        let res
        if (customer.customerType === "School") {
            res = await getStudentByIdRequest(travelerId);
        } else if (customer.customerType === "Company") {
            res = await getEmployeeByIdRequest(travelerId);
        }
        if (res) {
            setTraveller(res.data);
        }
    };

    useEffect(() => {
        getTraveller();
    }, [])


    return (
        <Box className={styles.create_user_page}>
            {
                traveller &&
                <>
                    {
                        customer.customerType === "School" &&
                        <UpdateStudent student={traveller} customer={customer} />
                    }
                    {
                        customer.customerType === "Company" &&
                        <UpdateEmployee employee={traveller} customer={customer} />
                    }
                </>
            }
        </Box >
    )
}