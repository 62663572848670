import axios from "axios";

export const getCompanyRequest = () => axios.get(`/company`);

export const getVehicleCostKmRequest = () => axios.get(`/company/VehicleCostKm`);

export const createCompanyRequest = (form) => axios.post(`/company`, form);

export const updateCompanyRequest = (form) => axios.put(`/company`, form);

export const updateVehicleCostKmRequest = (form) => axios.put(`/company/VehicleCostKm`, form);
