import { useEffect, useLayoutEffect, useState } from 'react';
import { Grid, TextField, Autocomplete } from '@mui/material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import CustomDialog from '../../../atoms/CustomDialog';
import { toast } from 'react-toastify';
import { getPlansByCustomerIdRequest } from '../../../../api/controllers/plan-controller';
import { getVehiclesRequest } from '../../../../api/controllers/vehicle-controller';
import { getWorkTimesByCustomerIdRequest } from '../../../../api/controllers/work-time-controller';
import { useParams } from 'react-router';
import { createDailyTaskByPlanIdRequest, updateDailyTaskRequest } from '../../../../api/controllers/daily-task-controller';
import { pickersPopperClasses } from '@mui/x-date-pickers/internals';
import { Draggable } from 'leaflet';
import StationCard from '../../../molecules/StationCard';
import moment from 'moment';

const UpdateDailyTask = (props) => {

    const { customerId } = useParams();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [lists, setLists] = useState({
        plans: [],
        vehicles: [],
        workTimes: [],
    })

    /* const getPlans = () => getPlansByCustomerIdRequest(customerId).then(res => setLists(prev => ({ ...prev, plans: res.data }))); */
    const getVehicles = () => getVehiclesRequest().then(res => setLists(prev => ({ ...prev, vehicles: res.data })));
    const getWorkTimes = () => getWorkTimesByCustomerIdRequest(customerId).then(res => setLists(prev => ({ ...prev, workTimes: res.data })));

    const handleSubmit = async () => {
        const newObj = { ...props.selectedDailyTask, vehicleId: props.selectedDailyTask.vehicle.id, workTimeId: props.selectedDailyTask.workTime.id };
        delete newObj.vehicle;
        delete newObj.workTime;
        delete newObj.id;
        delete newObj.date;
        delete newObj.distance;
        delete newObj.duration;

        setIsSubmitting(true);
        try {
            let res = await updateDailyTaskRequest(newObj, props.selectedDailyTask.id, props.selectedDailyTask.date)
            if (res) {
                toast.success("Günlük görev güncellendi");
                handleClose();
                props.getData();
            }
        } catch (error) { }
        setIsSubmitting(false);
    }

    console.log('props.selectedDailyTask', props.selectedDailyTask)

    const handleClose = () => {
        props.setOpen(false);
        props.setSelectedDailyTask({})
    }

    useEffect(() => {
        getVehicles();
        getWorkTimes();
    }, [])

    console.log('lists', lists)
    console.log('props.selectedDailyTask', props.selectedDailyTask)

    return (
        <CustomDialog
            size="md"
            open={props.open}
            closeFunc={handleClose}
            title="Günlük görev oluştur"
            buttons={[
                {
                    onClick: handleSubmit,
                    disabled: Object.values(props.selectedDailyTask).includes(null),
                    loading: isSubmitting,
                    variant: "contained",
                    text: "Kaydet",
                }
            ]}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} md={6} >
                    <TextField
                        fullWidth
                        size="medium"
                        placeholder="Güzergah ismini yazın"
                        value={props.selectedDailyTask.title || ""}
                        onChange={e => { props.setSelectedDailyTask({ ...props.selectedDailyTask, title: e.target.value }) } /* setUpdateForm(prev => ({ ...prev, props.selectedDailyTask: {...prev.props.selectedDailyTask, title: e.target.value } })) */}
                    />
                </Grid>
                <Grid item xs={12} md={6} >
                    <Autocomplete
                        options={lists.vehicles}
                        getOptionLabel={option => option.licensePlate}
                        value={props.selectedDailyTask.vehicle}
                        onChange={(event, newValue) => { props.setSelectedDailyTask({ ...props.selectedDailyTask, vehicle: newValue }) }}
                        isOptionEqualToValue={(option, value) => option?.id === value?.id}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label='Araç'
                                fullWidth
                            />}
                    />
                </Grid>
                {/*  <Grid item xs={12} md={3} >
                    <DatePicker
                        label="Ertelemek İstediğiniz Tarih"
                        value={props.selectedDailyTask.date}
                        onChange={(newValue) => { props.setSelectedDailyTask(prev => ({ ...prev, date: moment(newValue).format("yyyy-MM-DD") })) }}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                        minDate={new Date()}
                    />
                </Grid> */}
                <Grid item xs={12} md={6} >
                    <Autocomplete
                        options={lists.workTimes}
                        value={props.selectedDailyTask?.workTime}
                        onChange={(event, newValue) => { props.setSelectedDailyTask({ ...props.selectedDailyTask, workTime: newValue }) }}
                        getOptionLabel={option => `${option.title} (${option.startTime.slice(0, 5)} - ${option.endTime.slice(0, 5)})`}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label='Vardiya'
                                fullWidth
                            />}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TimePicker
                        label="Başlama Zamanı"
                        value={props.selectedDailyTask.startTime ? new Date(0, 0, 0, props.selectedDailyTask.startTime.slice(0, 2), props.selectedDailyTask.startTime.slice(3, 5)) : null}
                        onChange={(newValue) => {
                            props.setSelectedDailyTask(prev => ({ ...prev, startTime: moment(newValue).format("HH:mm:ss") }))
                            props.selectedDailyTask?.duration && props.setSelectedDailyTask(prev => ({ ...prev, endTime: moment(moment(newValue).add(prev.duration, 'minutes')).format("HH:mm:ss") }))
                        }}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TimePicker
                        label="Bitiş Zamanı"
                        value={props.selectedDailyTask.endTime ? new Date(0, 0, 0, props.selectedDailyTask.endTime.slice(0, 2), props.selectedDailyTask.endTime.slice(3, 5)) : null}
                        onChange={(newValue) => {
                            props.setSelectedDailyTask({ ...props.selectedDailyTask, endTime: moment(newValue).format("HH:mm:ss") })
                        }}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                    />
                </Grid>
                {props.selectedDailyTask && props.selectedDailyTask.stations && props.selectedDailyTask.stations.length > 0 &&
                    props.selectedDailyTask.stations.map((station, index) => (
                        <Grid key={index} item xs={12} md={6}>
                            <StationCard
                                station={station}
                                index={index}
                                deleteOnClick={() => {
                                    props.setSelectedDailyTask({ ...props.selectedDailyTask, stations: props.selectedDailyTask.stations.filter((v, i) => i !== index) })
                                }}
                            />
                        </Grid>
                    ))
                }
            </Grid>
        </CustomDialog>
    )
}

export default UpdateDailyTask;