import { BASE_URL } from "../api/ApiProvider";
import SockJS from "sockjs-client";
import { Stomp } from "@stomp/stompjs";

const WsHandler = ({ vehicles, setVehicles }) => {
    let url = `${BASE_URL}/ws`;
    let socket = SockJS(url)
    var stompClient = Stomp.over(function () {
        return socket
    });
    stompClient.debug = () => { }

    stompClient.connect({ token: `Bearer ${localStorage.getItem("token")}` }, async (frame) => {
        //console.log('Connected: ' + frame);
        stompClient.subscribe("vehicle", async (resp) => {
            let vehicleWs = JSON.parse(resp.body)
            //console.log(vehicleWs);
            //console.log("ws");
            if (vehicles && vehicleWs?.lastLog) {
                let vd = vehicles.findIndex(x => x?.id === vehicleWs?.id);
                vehicles[vd].lastLog = vehicleWs?.lastLog;
                setVehicles([...vehicles]);
            }
        }, {
            token: `Bearer ${localStorage.getItem("token")}`
        })
    })

    return stompClient
}

export default WsHandler;