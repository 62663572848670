import { Delete, Info, ShareLocationRounded } from '@mui/icons-material';
import { Typography, ListItemIcon, MenuItem, Tooltip } from '@mui/material';
import { Stack } from '@mui/system';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { deletePlanRequest, getPlansPageableRequest } from '../../../../api/controllers/plan-controller';
import CustomDataGrid from '../../../atoms/CustomDataGrid';
import CustomMenu from '../../../molecules/CustomMenu';
import CreateDailyTask from '../DailyTasks/CreateDailyTask';
import Filter from '../../../molecules/Filter';
import Search from '../../../molecules/Search';
import FilterList from '../../../molecules/FilterList';
import { getWorkTimesByCustomerIdRequest } from '../../../../api/controllers/work-time-controller';
import ConfirmModal from '../../../atoms/DeleteConfirmModal';
import { toast } from 'react-toastify';

export default function Plans() {
    const { customerId } = useParams();
    const navigate = useNavigate();
    const [deleteId, setDeleteId] = useState(false)

    const [isTableLoading, setIsTableLoading] = useState(true);
    const [data, setData] = useState([]);
    const [pageable, setPageable] = useState({ page: 0, size: 10, total: 0 });
    const [createDailyTaskModal, setCreateDailyTaskModal] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState(null);
    const [filter, setFilter] = useState({
        search: "",
        customerIdList: [customerId],
        workTimeIdList: [],
    });
    const [workTimes, setWorkTimes] = useState([]);

    const getData = async (type) => {
        if (type === "clear") {
            filter.workTimeIdList = [];
            setFilter(filter);
        }
        setIsTableLoading(true);
        try {
            let res = await getPlansPageableRequest(pageable, filter);
            if (res) {
                setData(res.data.content);
                setPageable({ ...pageable, total: res.data.totalElements });
            }
        } catch (error) { }
        setIsTableLoading(false);
    }

    const handleDelete = async () => {
        try {
            let res = await deletePlanRequest(deleteId);
            if (res) {
                getData();
                setDeleteId(false);
                toast.success("Güzergah silindi");
            }
        } catch (error) { }
    }

    useEffect(() => {
        getData();
    }, [pageable.page, pageable.size, filter.search])

    useEffect(() => {
        (async () => {
            let res = await getWorkTimesByCustomerIdRequest(customerId);
            if (res) {
                setWorkTimes(res.data);
            }
        })()
    }, [])

    //Tablo sütunları
    const columns = [
        {
            field: 'title',
            headerName: "Güzergah İsmi",
            flex: 1,
            minWidth: 200,
            renderCell: ({ row }) => <Tooltip title={row.title || "-"} >
                <p>
                    {row.title || "-"}
                </p>
            </Tooltip>
        },
        {
            field: 'vehicle',
            headerName: "Yolcu Sayısı (Mevcut/Kapasite)",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? value.capacity : "-",
        },
        {
            field: 'distance',
            headerName: "Toplam Mesafe",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? `~ ${new Intl.NumberFormat('tr-TR').format(value)} metre` : "-",
        },
        {
            field: 'duration',
            headerName: "Tahmini Süre",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? `${moment.duration(value).hours() ? moment.duration(value).hours() + " saat " : ""}${moment.duration(value).minutes() + " dakika"}` : "-",
        },
        {
            field: 'id',
            headerName: "",
            sortable: false,
            align: 'right',
            renderCell: ({ row, value }) => <CustomMenu>
                <MenuItem onClick={() => navigate(`/app/guzergahlar?customerId=${row?.workTime?.customer?.id}&workTimeId=${row?.workTime?.id}&planId=${row?.id}`)}>
                    <ListItemIcon>
                        <Info fontSize="small" />
                    </ListItemIcon>
                    Detaylar
                </MenuItem>
                <MenuItem onClick={() => {
                    setCreateDailyTaskModal(true);
                    setSelectedPlan(row);
                }}>
                    <ListItemIcon>
                        <ShareLocationRounded fontSize="small" />
                    </ListItemIcon>
                    Günlük Plan Oluştur
                </MenuItem>
                <MenuItem onClick={() => {
                    setDeleteId(value);
                }}>
                    <ListItemIcon>
                        <Delete fontSize="small" />
                    </ListItemIcon>
                    Sil
                </MenuItem>
            </CustomMenu>,
        },
    ]

    return (
        <>
            <Stack spacing={2}>
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">
                        | Güzergahlar
                    </Typography>
                    <Stack direction="row" spacing={2}>
                        <Search
                            onChange={e => setFilter(prev => ({ ...prev, search: e }))}
                        />
                        <Filter
                            isEmpty={filter.workTimeIdList.length === 0}
                            onClear={() => getData("clear")}
                            onSubmit={getData}
                        >
                            <FilterList
                                title="Vardiyalara göre"
                                data={workTimes}
                                getLabel={v => v.title}
                                filter={filter}
                                setFilter={setFilter}
                                filterKey="workTimeIdList"
                            />
                        </Filter>
                    </Stack>
                </Stack>
                <CustomDataGrid
                    data={data}
                    columns={columns}
                    loading={isTableLoading}
                    rowId={(params) => params?.id}
                    pageable={pageable}
                    setPageable={setPageable}
                />
            </Stack>
            {
                createDailyTaskModal && selectedPlan &&
                <CreateDailyTask
                    open={createDailyTaskModal}
                    setOpen={setCreateDailyTaskModal}
                    selectedPlan={selectedPlan}
                />
            }
            {
                deleteId &&
                <ConfirmModal
                    open={deleteId}
                    title={"Emin misiniz?"}
                    setOpen={setDeleteId}
                    message={"Güzergahı silmek istediğinize emin misiniz?"}
                    handleConfirm={handleDelete}
                />
            }
        </>
    )
}