import { useEffect, useState } from 'react';
import { Grid, Typography, TextField, Autocomplete } from '@mui/material';
import { useFormik } from 'formik';
import PhoneTextfield from '../../../../atoms/PhoneTextfield';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { updateEmployeeRequest } from '../../../../../api/controllers/employee-controller';
import { getWorkTimesByCustomerIdRequest } from '../../../../../api/controllers/work-time-controller';
import ActionPageTitle from '../../../../atoms/ActionPageTitle';
import AddressModal from '../../../../molecules/AddressModal';
import { getDepartmentsByCustomerIdRequest } from '../../../../../api/controllers/department-controller';

export default function UpdateEmployee({ employee, customer }) {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [workTimes, setWorkTimes] = useState([]);
    const [departments, setDepartments] = useState([])

    const formik = useFormik({
        validationSchema: yup.object({
            firstName: yup
                .string()
                .required('Bu alan gereklidir'),
            lastName: yup
                .string()
                .required('Bu alan gereklidir'),
            phone: yup.object({
                number: yup
                    .string()
                    .required('Bu alan gereklidir')
                    .length(10, 'Telefon numarası 10 haneli olmalıdır'),
            }),
            workTime: yup
                .object()
                .required('Bu alan gereklidir')
                .nullable(),
            address: yup.object({
                province: yup
                    .string()
                    .required('Bu alan gereklidir')
                    .nullable(),
                district: yup
                    .string()
                    .required('Bu alan gereklidir')
                    .nullable(),
                neighborhood: yup
                    .string()
                    .required('Bu alan gereklidir')
                    .nullable(),
                street: yup
                    .string()
                    .required('Bu alan gereklidir'),
                buildingInformation: yup
                    .string()
                    .required('Bu alan gereklidir'),
            }),
        }),         
        initialValues: employee,
        onSubmit: () => onSubmit()
    });

    const onSubmit = async () => {
        setIsSubmitting(true);
        try {
            let res = await updateEmployeeRequest(formik.values);
            if (res) {
                toast.success("Personel güncellendi");
            }
        } catch (error) { }
        setIsSubmitting(false);
    }

    useEffect(() => {
        (async () => {
            let res = await getWorkTimesByCustomerIdRequest(customer.id);
            if (res) {
                setWorkTimes(res.data);
            }
            let rex = await getDepartmentsByCustomerIdRequest(customer.id);
            if (rex) {
                setDepartments(rex.data);
            }
        })()
    }, [])

    return (
        <form onSubmit={formik.handleSubmit}>
            <ActionPageTitle
                title="Yolcu Düzenle"
                buttonText="Kaydet"
                buttonProps={{
                    loading: isSubmitting,
                    type: "submit",
                    variant: "contained",
                }}
            />

            <Typography variant="h6" my="1rem">
                | Personele ait bilgileri girin
            </Typography>

            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        name="firstName"
                        label="İsim"
                        placeholder="Personel ismini girin"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                        error={formik.touched.firstName && formik.errors.firstName}
                        helperText={formik.touched.firstName && formik.errors.firstName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        name="lastName"
                        label="Soyisim"
                        placeholder="Personel soyismini girin"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                        error={formik.touched.lastName && formik.errors.lastName}
                        helperText={formik.touched.lastName && formik.errors.lastName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <PhoneTextfield
                        name="phone.number"
                        phone={formik.values.phone}
                        onChange={({ value }) => formik.setFieldValue("phone.number", value)}
                        error={formik.touched.phone?.number && formik.errors.phone?.number}
                        helperText={formik.touched.phone?.number && formik.errors.phone?.number}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        options={workTimes}
                        value={formik.values.workTime}
                        onChange={(event, newValue) => formik.setFieldValue("workTime", newValue)}
                        getOptionLabel={option => `${option.title} (${option.startTime.slice(0, 5)} - ${option.endTime.slice(0, 5)})`}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label='Vardiya'
                                fullWidth
                                error={formik.touched.workTime && formik.errors.workTime}
                                helperText={formik.touched.workTime && formik.errors.workTime}
                            />}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        options={departments}
                        value={formik.values.department}
                        onChange={(event, newValue) => formik.setFieldValue("department", newValue || null)}
                        getOptionLabel={option => `${option.title}`}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label='Departman'
                                fullWidth
                                error={formik.touched.department && formik.errors.department}
                                helperText={formik.touched.department && formik.errors.department}
                            />}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <AddressModal formik={formik} />
                </Grid>
            </Grid>
        </form >
    )
}