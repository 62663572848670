import React from 'react'
import { InputAdornment, TextField } from '@mui/material';
import { PatternFormat } from 'react-number-format';

const PhoneTextfield = ({ size, name, phone, onChange, error, helperText }) => {
    return (
        <PatternFormat
            size={size}
            name={name}
            label={size ? "" : "Telefon"}
            placeholder="XXX XXX XX XX"
            format="### ### ## ##"
            fullWidth
            valueIsNumericString
            value={phone?.number}
            onValueChange={onChange}
            InputProps={{
                startAdornment: <InputAdornment position="start">{phone?.areaCode}</InputAdornment>,
            }}
            customInput={TextField}
            error={error}
            helperText={helperText}
        />
    )
}

export default PhoneTextfield;