import { Box, Typography } from '@mui/material';
import { AirlineSeatReclineNormalRounded } from '@mui/icons-material';
import styles from './style.module.scss';
import DetailTag from '../../atoms/DetailTag';

function VehicleCard({ vehicle, isActive, onClick }) {

    return (
        <button onClick={onClick} className={`${styles.container} ${isActive ? styles.active : ""}`}>
            <Typography className={styles.licensePlate}>
                {vehicle.licensePlate || "Servis"}
            </Typography>
            <Box className={styles.tags}>
                <DetailTag
                    icon={<AirlineSeatReclineNormalRounded fontSize="inherit" /> || null}
                    text={vehicle.capacity || 0}
                />
                <DetailTag
                    icon={<i className="bi bi-person-fill" /> || null}
                    text={vehicle.driver ? `${vehicle.driver?.firstName || ""} ${vehicle.driver?.lastName || ""}` : "Sürücü atanmamış"}
                />
            </Box>
        </button>
    )
}

export default VehicleCard;