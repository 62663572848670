import axios from "axios";

export const deletePlanRequest = (id) => axios.delete(`/plan/${id}`)

export const getPlansPageableRequest = (pageable, filter) => {
    let search_query = `title=like='${filter.search}'`;
    let customer_filter_query = `workTime.customer.id=in=${filter.customerIdList?.length > 0 ? `(${filter.customerIdList})` : "''"}`;
    let workTime_filter_query = `workTime.id=in=${filter.workTimeIdList?.length > 0 ? `(${filter.workTimeIdList})` : "''"}`;
    let query = `${search_query};${customer_filter_query};${workTime_filter_query}`;
    return axios.get(`/plan/pageable?page=${pageable.page}&size=${pageable.size}&query=${query}`)
}

export const getPlansByCustomerIdPageableRequest = (customerId, pageable) => axios.get(`/plan/customer/${customerId}/pageable?page=${pageable.page}&size=${pageable.size}`)

export const getPlansByCustomerIdRequest = (customerId) => axios.get(`/plan/customer/${customerId}`)

export const getPlansByDriverIdPageableRequest = (driverId, pageable) => axios.get(`/plan/driver/${driverId}?page=${pageable.page}&size=${pageable.size}`)

export const getPlansByVehicleIdPageableRequest = (vehicleId, pageable) => axios.get(`/plan/vehicle/${vehicleId}?page=${pageable.page}&size=${pageable.size}`)

export const getDriverPlanRequest = (pageable) => axios.get(`/plan/driver/myPlan?page=${pageable.page}&size=${pageable.size}`)

export const getPlansByWorkTimeIdRequest = (workTimeId) => axios.get(`/plan/arrivalAndDeparture/workTime/${workTimeId}`)

export const getPlanByIdRequest = (id) => axios.get(`/plan/${id}`)

export const getPlansByQueryRequest = (query) => axios.get(`/plan/list?query=${query}`)

export const getTravelerCountByPlanIdRequest = (id) => axios.get(`/plan/${id}/travelerCount`)

export const createPlanRequest = ({ vehicleId, workTimeId, form, isForced }) => axios.post(`/plan/vehicle/${vehicleId}/workTime/${workTimeId}?isForced=${isForced}`, form)

export const updatePlanRequest = (form, isForced) => axios.put(`/plan/${form.id}/vehicle/${form.vehicle.id}?isForced=${isForced}`, { ...form, stationIdList: form.stationList.map(v => v.id) })