import { Fragment, useState, useEffect } from "react"
import { Navigate, Outlet } from "react-router-dom"
import { getMeRequest } from "../api/controllers/account-controller"
import CustomLayout from "./templates/CustomLayout";
import SuspenseFallback from "./templates/SuspenseFallback";
import { useAuth } from "../context/AuthContext";
import { toast } from "react-toastify";

const AdminRouter = () => {
    const [AUTH_STATUS, setAUTH_STATUS] = useState("WAITING")
    const { setCurrentUser } = useAuth();

    useEffect(() => {
        const checkAuthentication = async () => {
            try {
                let res = await getMeRequest({ hideMessage: false });
                if (res && res.status === 200) {
                    setCurrentUser(res.data);
                    setAUTH_STATUS("SUCCESS");
                }
            } catch (error) {
                if (error?.response?.status === 401) {
                    toast.warn("Oturumunuz kapatıldı! Lütfen yeniden giriş yapın.")
                    setAUTH_STATUS("FAILED");
                    setCurrentUser(null);
                }
            }
        }
        checkAuthentication()
    }, [])
    return (
        <Fragment>
            {
                AUTH_STATUS === "WAITING" ? <SuspenseFallback />
                    : AUTH_STATUS === "FAILED" ? <Navigate to="/" />
                        : AUTH_STATUS === "SUCCESS" ? <CustomLayout><Outlet /></CustomLayout>
                            : <div>Error</div>
            }
        </Fragment>
    )
}

export default AdminRouter;