import axios from "axios";
import moment from "moment";

export const getDailyTasksByCustomerId = (customerId, date, pageable) => axios.get(`/dailyTask/customer/${customerId}?date=${date}&page=${pageable.page}&size=${pageable.size}`)

export const getDailyTasksByWorkTimeId = (workTimeId, date, pageable) => axios.get(`/dailyTask/workTime/${workTimeId}?date=${date}&page=${pageable.page}&size=${pageable.size}`)

export const getDailyTasksByPlanId = (planId, date, pageable) => axios.get(`/dailyTask/plan/${planId}?date=${date}&page=${pageable.page}&size=${pageable.size}`)

export const createDailyTaskByPlanIdRequest = (form) => {
    const data = {
        endDate: moment(form.endDate).format("YYYY-MM-DD"),
        startDate: moment(form.startDate).format("YYYY-MM-DD"),
        vehicleId: form.vehicleId.id,
        workTimeId: form.workTimeId.id,
    }
    return axios.post(`/dailyTask/plan/${form.planId.id}`, data)
}

export const getDailyTaskByUuidRequest = (form) => axios.get(`/dailyTask/plan/uuid/${form.uuid}?date=${form.date}`)

export const createDailyTaskforOverTime = (customerRequestId) => axios.post(`/dailyTask/overtime/${customerRequestId}`)

export const createDailyTaskforStudy = (customerRequestId) => axios.post(`/dailyTask/study/${customerRequestId}`)

export const delayDailyTaskRequest = (form) => axios.put(`/dailyTask/${form.planId}/delayed`, form)

export const updateDailyTaskRequest = (form, id, date) => axios.put(`/dailyTask/update/${id}?date=${date}`, form)

export const getDailyTaskCustomerReport = (id, date) => axios.get(`/dailyTask/customer/${id}/report?date=${date}`)

export const getDailyTaskVehicleReportAll = (date) => axios.get(`/dailyTask/vehicle/report?date=${date}`)

export const getDailyTaskCustomerReportByVehicleId = (vehicleId, date) => axios.get(`/dailyTask/vehicle/${vehicleId}/report?date=${date}`)

export const deleteDailyTaskRequest = (id) => axios.delete(`/dailyTask/${id}`)

