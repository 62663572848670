import { useEffect, useRef, useState } from "react";
import { Autocomplete, Box, Button, Divider, TextField, Typography } from "@mui/material";
import Leaflet, { MAP_DEFAUTLS } from "../../../components/atoms/Leaflet";
import PlannerNavigation from "../../../components/molecules/PlannerNavigation";
import { Helmet } from "react-helmet";
import ShowArea from "../../../components/atoms/ShowArea";
import { usePlans } from "../../../context/PlansContext";
import { useCustomer } from "../../../context/CustomerContext";
import CustomerMarker from "../../../components/atoms/CustomerMarker";
import CreateStationMarker from "../../../components/atoms/CreateStationMarker";
import { Tooltip } from "react-leaflet";
import StationMarker from "../../../components/atoms/StationMarker";
import RoutePolyline from "../../../components/atoms/RoutePolyline";
import { getCustomerByIdRequest } from "../../../api/controllers/customer-controller";
import { useAuth } from "../../../context/AuthContext";
import { toast } from "react-toastify";
import styles from "./style.module.scss";
import { Outlet, useNavigate, useParams } from "react-router";
import StudentTravelerMarker from "../../../components/atoms/StudentTravelerMarker";
import EmployeeTravelerMarker from "../../../components/atoms/EmployeeTravelerMarker";
import TravelerMarker from "../../../components/atoms/TravelerMarker";
import { getDailyTaskByUuidRequest } from "../../../api/controllers/daily-task-controller";
import moment from "moment";
import { getVehicleLogByVehicleIdRequest } from "../../../api/controllers/vehicle-log-controller";
import VehicleHistory from "../../../components/atoms/VehicleHistory";
import { getVehicleCostKmRequest } from "../../../api/controllers/company-controller";
import { createContext } from "react";
import EmployeeTravelerMarkerMultiple from "../../../components/atoms/EmployeeTravelerMarkerMultiple";
import StudentTravelerMarkerMultiple from "../../../components/atoms/StudentTravelerMarkerMultiple";
import { ContextType, StationForm, Traveler } from "../../../types/PlansContext";
import { CostAll, PlanType, Station } from "../../../types/CommonTypes";
import L from 'leaflet'
export const CostContext = createContext<ContextType | null>(null);

function Page() {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const { role } = currentUser;
    const map = useRef<L.Map | null>(null);
    const [mapReady, setMapReady] = useState(false);
    const [areaPolygon, setAreaPolygon] = useState([]);
    const {
        isLoading,
        filterMarker,
        travelers,
        setTravelers,
        showSidebar,
        setShowSidebar,
        stationForm,
        setStationForm,
        updateForm,
        setUpdateForm,
        getTravelers,
        plan,
        plans,
        setPlans,
        getPlan,
        setPlan,
        resetUpdateForm,
        getPlans,
    } = usePlans();
    const { customer, setCustomer } = useCustomer();
    type typeplan = "arrival" | "departure";
    type vehicleLog = {
        startDateTime: string,
        endDateTime: string,
        vehicleId: number,
    }
    type mapDefault = {
        center: number[],
        zoom: number,
    }
    const { customerId, planUuid, workTimeId, stationId, planType } = useParams<{ customerId: string, planUuid: string, workTimeId: string, stationId: string, planType: typeplan }>();
    const [history, setHistory] = useState({
        isLoading: false,
        date: new Date(),
        data: [],
        activeIndex: 0,
    });

    const [selectedTraveler, setSelectedTraveler] = useState<Traveler>()

    const [costs, setCosts] = useState<CostAll>({
        monthlyMaintenance: null,
        monthlyInspection: null,
        monthlyKasko: null,
        monthlyInsurance: null,
        monthlyDriver: null,
        monthlyTyre: null,
        monthlyTotalKm: null,
        fuelPrice: null,
        fuelConsumptionHundredKm: null,
        fuelCostKm: null,
        costKm: null
    });
    const [isHistory, setIsHistory] = useState(false);

    const getFilteredTravelers = () => {
        switch (true) {
            case filterMarker.traveler.available && filterMarker.traveler.unavailable:
                return travelers
            case filterMarker.traveler.available:
                return travelers.filter(v => v[planType as keyof Pick<Traveler, "arrival" | "departure">].enable)
            case filterMarker.traveler.unavailable:
                return travelers.filter(v => !v[planType as keyof Pick<Traveler, "arrival" | "departure">].enable)
            default:
                return [];
        }
    }

    const getHistory = async (type: typeplan) => {
        try {
            let res = await getDailyTaskByUuidRequest({
                uuid: planUuid,
                date: moment(history.date).format("yyyy-MM-DD"),
            });
            if (res) {
                setIsHistory(true);
                const daily = await res.data.find(
                    (v: any) =>
                        v.planDirectionType ===
                        (type && typeof type === "string"
                            ? type.toUpperCase()
                            : (planType as typeplan).toUpperCase())
                );
                if (daily) {
                    await setPlan({
                        ...daily,
                        workTime: {
                            ...daily.workTime,
                            customer: daily.workTime.customer,
                        },
                        stationList: daily.stations.map((v: Station) => ({
                            ...v,
                            travelers: v.travelers,
                        })),
                    });
                    const startDate = await moment(
                        `${daily.date}T${daily.startTime}.000+03:00`
                    ).toISOString();
                    const endDate = await moment(
                        `${daily.date}T${daily.endTime}.000+03:00`
                    ).toISOString();
                    if (daily.vehicle.id) {
                        getVehicleLog({
                            startDateTime: startDate,
                            endDateTime: endDate,
                            vehicleId: daily.vehicle.id,
                        });
                    }
                } else {
                    toast.error("Seçilen güne ait güzergah bulunamadı!");
                    resetHistory();
                }
            }
        } catch (error) {
            resetHistory();
        }
    };

    const getVehicleLog = async (data: vehicleLog) => {
        setHistory((prev) => ({ ...prev, isLoading: true, activeIndex: 0 }));
        try {
            let logs = await getVehicleLogByVehicleIdRequest(data);
            if (logs) {
                if (logs.data.length > 0) {
                    await setHistory((prev) => ({ ...prev, data: logs.data }));
                    const list = await logs.data.map((v: any) => [v.latLng.lat, v.latLng.lng]);
                    await map && map.current && map?.current.flyToBounds(list, { padding: [50, 50] });
                } else {
                    toast.error("Seçilen güne ait veri bulunamadı!");
                    setHistory((prev) => ({ ...prev, data: [] }));
                }
            }
        } catch (error) {
            setHistory((prev) => ({ ...prev, data: [] }));
        }
        setHistory((prev) => ({ ...prev, isLoading: false }));
    };

    const resetHistory = () => {
        setHistory({
            isLoading: false,
            date: new Date(),
            data: [],
            activeIndex: 0,
        });
        getPlan(planUuid as string, planType as PlanType);
        setIsHistory(false);
    };

    const getCostsData = async () => {
        let res = await getVehicleCostKmRequest();
        if (res) {
            setCosts(res.data);
        }
    };

    useEffect(() => {
        getCostsData();
    }, []);

    useEffect(() => {
        if (mapReady) {
            if (Array.isArray(areaPolygon) && areaPolygon.length > 0) {
                const list = areaPolygon.flatMap((v: any) => v.coordinates);
                map && map.current && map.current.flyToBounds(list, { padding: [50, 50] });
            } else {
                map && map.current && map.current.flyTo((MAP_DEFAUTLS as any).center, MAP_DEFAUTLS.zoom);
            }
        }
    }, [mapReady, areaPolygon]);

    useEffect(() => {
        if (workTimeId && customer) {
            getTravelers(Number(workTimeId));
        } else {
            setTravelers([]);
        }
    }, [workTimeId, customer]);

    useEffect(() => {
        if (!planUuid) {
            if (workTimeId) {
                getPlans(Number(workTimeId));
            } else {
                setPlans({
                    departure: [],
                    arrival: []
                });
            }
        }
    }, [workTimeId]);

    useEffect(() => {
        if (planUuid && customer && planType) {
            getPlan(planUuid, planType as PlanType);
        }
    }, [planUuid, customer]);

    useEffect(() => {
        const getCustomer = async () => {
            resetUpdateForm();
            let res = await getCustomerByIdRequest(customerId);
            if (res) {
                setCustomer(res.data);
            }
        };
        customerId && getCustomer();
    }, [customerId]);

    const handleClickTabs = (type: typeplan) => {
        window.location.pathname.includes("duzenle")
            ? toast.warning("Düzenleme yaparken rota değiştiremezsiniz!")
            : navigate(window.location.pathname.replace(planType as typeplan, type));
        if (isHistory) {
            getHistory(type);
        } else if (planUuid) {
            getPlan(planUuid, type as PlanType);
        }
    };

    return (
        <Box position="relative">
            <Helmet>
                <title>Güzergahlar | Turmetre Optimizasyon Sistemi</title>
            </Helmet>
            <PlannerNavigation
                setAreaPolygon={setAreaPolygon}
                setShowSidebar={setShowSidebar}
                history={history}
                setHistory={setHistory}
                getHistory={getHistory}
                resetHistory={resetHistory}
                isHistory={isHistory}
            />
            <Leaflet ref={map} setMapReady={setMapReady} isLoading={isLoading}>
                <Box
                    className={`${styles.tabs} ${showSidebar ? styles.sidebar_opened : ""
                        }`}
                >
                    <Button
                        onClick={() => handleClickTabs("departure")}
                        className={planType == "departure" ? styles.active : ""}
                    >
                        Gidiş
                    </Button>
                    <Button
                        onClick={() => handleClickTabs("arrival")}
                        className={planType == "arrival" ? styles.active : ""}
                    >
                        Dönüş
                    </Button>
                </Box>
                <Box
                    className={`${styles.searchTraveller} ${showSidebar ? styles.searchTravellerSideBarOpened : ""}`}
                >
                    <Autocomplete
                        className={styles.autocomplete_travelers}
                        size="small"
                        options={getFilteredTravelers()}
                        renderInput={(params) =>
                            <TextField {...params} placeholder='Yolcu arayın' />}
                        getOptionLabel={(option) => `${option?.firstName + " " + option?.lastName}` || "-"}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        value={selectedTraveler || null}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                setSelectedTraveler(newValue)
                                const MARKER_POSITION = [newValue?.address?.position?.latitude, newValue?.address?.position?.longitude + 0.004];
                                map.current.flyTo(MARKER_POSITION as any, 16);
                            } else {
                                map.current.setZoom(12)
                                setSelectedTraveler(null)
                            }
                        }}
                    />
                </Box>
                {mapReady && customer && (
                    <>
                        {(role === "ADMIN" || role === "MANAGER" || role === "CUSTOMER") && (
                            <>
                                {
                                    //Bölge polygon
                                    Array.isArray(areaPolygon) &&
                                    areaPolygon.length > 0 &&
                                    areaPolygon.map((v: any, i) => (
                                        <ShowArea
                                            key={i}
                                            coordinates={v.coordinates}
                                            text={v.text}
                                        />
                                    ))
                                }
                                {
                                    //Yolcular marker okul
                                    customer?.customerType === "School" &&
                                    Array.isArray(travelers) &&
                                    getFilteredTravelers().map((traveler, i) => {
                                        let sameTravelers = travelers.filter(v => JSON.stringify(v.address.position) === JSON.stringify(traveler.address.position))
                                        if (sameTravelers.length > 1) {
                                            return <StudentTravelerMarkerMultiple
                                                key={i}
                                                travelers={sameTravelers}
                                            />
                                        } else {
                                            return <StudentTravelerMarker
                                                key={i}
                                                traveler={traveler}
                                            />
                                        }
                                    })
                                }
                                {
                                    //Yolcular marker şirket
                                    customer?.customerType === "Company" &&
                                    Array.isArray(travelers) &&
                                    getFilteredTravelers().map((traveler, i) => {
                                        let sameTravelers = travelers.filter(v => JSON.stringify(v.address.position) === JSON.stringify(traveler.address.position))
                                        if (sameTravelers.length > 1) {
                                            return <EmployeeTravelerMarkerMultiple
                                                key={i}
                                                travelers={sameTravelers}
                                            />
                                        } else {
                                            return <EmployeeTravelerMarker
                                                key={i}
                                                traveler={traveler}
                                            />
                                        }
                                    })
                                }
                                {planUuid && plan &&
                                    plan.id &&
                                    (window.location.pathname.includes("duzenle") ? (
                                        <RoutePolyline
                                            plan={updateForm.plan}
                                            customer={customer}
                                            setUpdateForm={setUpdateForm}
                                        />
                                    ) : (
                                        <RoutePolyline plan={plan} customer={customer} />
                                    ))}
                                {!planUuid &&
                                    plans[planType as keyof Pick<Traveler, "arrival" | "departure">]?.length > 0 &&
                                    filterMarker.routes &&
                                    plans[planType as keyof Pick<Traveler, "arrival" | "departure">].map((plan) => (
                                        <RoutePolyline
                                            key={plan.id}
                                            plan={plan}
                                            customer={customer}
                                        />
                                    ))}
                                {history.data.length > 0 && planUuid && (
                                    <VehicleHistory
                                        vehicleHistory={history}
                                        setVehicleHistory={setHistory}
                                        resetHistory={resetHistory}
                                    />
                                )}
                            </>
                        )}
                        {
                            //Müşteri marker
                            customer?.address?.position && (
                                <CustomerMarker
                                    position={[
                                        customer?.address?.position.latitude,
                                        customer?.address?.position.longitude,
                                    ]}
                                />
                            )
                        }
                        {customer.customerType === "Company" && (
                            <>
                                {
                                    //Durak oluşturma marker
                                    window.location.pathname.includes("station/olustur") && (
                                        <CreateStationMarker planType={planType as typeplan} />
                                    )
                                }
                                {
                                    //Durak düzenle marker
                                    stationId &&
                                    stationForm.position.latitude &&
                                    stationForm.position.longitude && (
                                        <StationMarker
                                            planType={planType as typeplan}
                                            position={[
                                                stationForm.position.latitude,
                                                stationForm.position.longitude,
                                            ]}
                                            eventHandlers={{
                                                dragend: (e: any) => {
                                                    setStationForm((prev: StationForm) => ({
                                                        ...prev,
                                                        lat: e.target._latlng.lat,
                                                        lng: e.target._latlng.lng,
                                                        position: { ...prev.position, latitude: e.target._latlng.lat, longitude: e.target._latlng.lng }
                                                    }));
                                                },
                                            }}
                                            draggable
                                        />
                                    )
                                }
                                {
                                    //Durak marker
                                    !stationId &&
                                    planUuid &&
                                    plan &&
                                    plan.stationList?.length > 0 &&
                                    filterMarker.station &&
                                    plan?.stationList?.map((station) => (
                                        <StationMarker
                                            planType={planType as typeplan}
                                            key={station.id}
                                            position={[
                                                station.position.latitude,
                                                station.position.longitude,
                                            ]}
                                        >
                                            <Tooltip direction="bottom">
                                                <Box
                                                    display="flex"
                                                    flexDirection="column"
                                                    gap="0.5rem"
                                                >
                                                    <Typography
                                                        fontSize="0.75rem"
                                                        color="primary"
                                                        fontWeight={500}
                                                    >
                                                        {station?.title}
                                                    </Typography>
                                                    <Divider flexItem />
                                                    <Typography component="ul">
                                                        {station?.travelers?.length > 0 ? (
                                                            station.travelers.map((traveler) => (
                                                                <Typography
                                                                    fontSize="0.75rem"
                                                                    component="li"
                                                                    key={traveler.id}
                                                                >
                                                                    {`- ${traveler.firstName || ""} ${traveler.lastName || ""
                                                                        }`}{" "}
                                                                    <span style={{ color: "var(--success)" }}>
                                                                        {traveler.receiveDateTime
                                                                            ? "(Alındı)"
                                                                            : ""}
                                                                    </span>
                                                                </Typography>
                                                            ))
                                                        ) : (
                                                            <Typography fontSize="0.75rem">
                                                                Bağlanmış yolcu bulunamadı!
                                                            </Typography>
                                                        )}
                                                    </Typography>
                                                </Box>
                                            </Tooltip>
                                        </StationMarker>
                                    ))
                                }
                            </>
                        )}
                        {role === "CUSTOMER" &&
                            customer.customerType === "School" &&
                            plan &&
                            plan.id &&
                            plan.stationList.map((station) => (
                                <TravelerMarker
                                    key={station.id}
                                    position={[
                                        station.position.latitude,
                                        station.position.longitude,
                                    ]}
                                >
                                    <Tooltip direction="bottom">{`${station.title || ""
                                        }`}</Tooltip>
                                </TravelerMarker>
                            ))}
                    </>
                )}
            </Leaflet>
            <CostContext.Provider value={costs as any}>
                <Outlet />
            </CostContext.Provider>
        </Box>
    );
}

export default Page;
