import { Delete } from '@mui/icons-material';
import RunningWithErrorsIcon from '@mui/icons-material/RunningWithErrors';
import { Box, Button, ListItemIcon, MenuItem, TextField, Tooltip, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { deleteDailyTaskRequest, getDailyTasksByCustomerId } from '../../../../api/controllers/daily-task-controller';
import { useCustomer } from '../../../../context/CustomerContext';
import CustomDataGrid from '../../../atoms/CustomDataGrid';
import ConfirmModal from '../../../atoms/DeleteConfirmModal';
import CustomMenu from '../../../molecules/CustomMenu';
import CreateDailyTask from './CreateDailyTask';
import DelayDailyTask from './DelayDailyTask';
import styles from './style.module.scss';
import EditIcon from '@mui/icons-material/Edit';
import UpdateDailyTask from './UpdateDailyTask';


export default function DailyTasks() {
    const [createModal, setCreateModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [delayModal, setDelayModal] = useState(false);
    const { customer } = useCustomer();
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [data, setData] = useState([]);
    const [date, setDate] = useState(new Date());
    const [pageable, setPageable] = useState({ page: 0, size: 10, total: 0 });
    const [selectedId, setSelectedId] = useState(null)
    const [selectedWorkTimeEnd, setSelectedWorkTimeEnd] = useState(null)
    const [deleteId, setDeleteId] = useState(false)
    const [selectedDailyTask, setSelectedDailyTask] = useState({
        distance: 0,
        endTime: null,
        startTime: null,
        planDirectionType: null,
        stationList: [],
        title: "",
        vehicle: {},
        workTime: {},
    })

    const getData = async () => {
        setIsTableLoading(true);
        try {
            let res = await getDailyTasksByCustomerId(customer?.id, moment(date).format("yyyy-MM-DD"), pageable);
            if (res) {
                setData(res.data.content);
                setPageable({ ...pageable, total: res.data.totalElements });
            }
        } catch (error) { }
        setIsTableLoading(false);
    }

    const handleDelete = async () => {
        try {
            let res = await deleteDailyTaskRequest(deleteId);
            if (res) {
                getData();
                setDeleteId(false);
                toast.success("Günlük plan silindi");
            }
        } catch (error) { }
    }

    useEffect(() => {
        getData();
    }, [date, pageable.page, pageable.size])

    //Tablo sütunları
    const columns = [
        {
            field: 'planTitle',
            headerName: "Güzergah",
            flex: 1,
            minWidth: 170,
            renderCell: ({ row }) => <Tooltip title={row.planTitle || "-"} >
                <p>
                    {row.planTitle || "-"}
                </p>
            </Tooltip>
        },
        {
            field: 'vehicle',
            headerName: "Servis",
            flex: 1,
            minWidth: 170,
            valueGetter: ({ value }) => value.licensePlate || "-",
        },
        {
            field: 'surucu',
            headerName: "Sürücü",
            flex: 1,
            minWidth: 170,
            valueGetter: ({ row }) => row.vehicle.driver ? `${row.vehicle.driver.firstName} ${row.vehicle.driver.lastName}` : "-",
        },
        {
            field: 'workTime',
            headerName: "Kalkış Saati",
            flex: 1,
            minWidth: 170,
            valueGetter: ({ row }) => row.startTime.slice(0, 5) || "-",
        },
        {
            field: 'id',
            headerName: "",
            sortable: false,
            align: 'right',
            renderCell: ({ value, row }) => <CustomMenu>
                <MenuItem onClick={() => {
                    if (row.planDirectionType === 'ARRIVAL') {
                        if (row.dailyTaskType === 'OVERTIME') {
                            toast.warning("Ek mesai seferleri ertelenemez.")
                        } else {
                            setDelayModal(true)
                        }
                    } else {
                        toast.warning("Sadece dönüş planlarının seferleri ertelenebilir.")
                    }
                    setSelectedId(value)
                    setSelectedWorkTimeEnd(row.startTime)
                }}>
                    <ListItemIcon>
                        <RunningWithErrorsIcon fontSize="small" />
                    </ListItemIcon>
                    Sefer Dönüşünü Ertele
                </MenuItem>

                <MenuItem onClick={() => {
                    setUpdateModal(true)
                    setSelectedDailyTask({ ...selectedDailyTask, duration: row.duration, date: row.date, id: row.planId, distance: row.distance, startTime: row.startTime, endTime: row.endTime, planDirectionType: row.planDirectionType, stations: row.stations, vehicle: row.vehicle, title: row.planTitle, workTime: row.workTime })
                }}>
                    <ListItemIcon>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    Düzenle
                </MenuItem>

                <MenuItem onClick={() => {
                    setDeleteId(value);
                }}>
                    <ListItemIcon>
                        <Delete fontSize="small" />
                    </ListItemIcon>
                    Sil
                </MenuItem>

            </CustomMenu>,
        },
    ]

    return (
        <>
            <Box className={styles.header}>
                <Typography
                    variant="h6"
                >
                    | Günlük Görevler
                </Typography>
                <DatePicker
                    className={styles.picker}
                    value={date}
                    onChange={(newValue) => setDate(newValue)}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            size="small"
                            inputProps={{
                                ...params.inputProps,
                                placeholder: "GG.AA.YYYY"
                            }}
                        />
                    }
                />
                <Button className={styles.action} onClick={() => setCreateModal(true)} variant="contained">Yeni Oluştur</Button>
            </Box>
            <CustomDataGrid
                data={data}
                columns={columns}
                loading={isTableLoading}
                rowId={(params) => params?.id}
                pageable={pageable}
                setPageable={setPageable}
            />
            {
                createModal &&
                <CreateDailyTask
                    open={createModal}
                    setOpen={setCreateModal}
                    getData={getData}
                />
            }
            {
                updateModal &&
                <UpdateDailyTask
                    open={updateModal}
                    setOpen={setUpdateModal}
                    getData={getData}
                    selectedDailyTask={selectedDailyTask}
                    setSelectedDailyTask={setSelectedDailyTask}
                />
            }
            {
                delayModal &&
                <DelayDailyTask
                    open={delayModal}
                    setOpen={setDelayModal}
                    getData={getData}
                    selectedId={selectedId}
                    date={date}
                    selectedWorkTimeEnd={selectedWorkTimeEnd}
                />
            }
            {
                deleteId &&
                <ConfirmModal
                    open={deleteId}
                    title={"Emin misiniz?"}
                    setOpen={setDeleteId}
                    message={"Yolcuyu silmek istediğinize emin misiniz?"}
                    handleConfirm={handleDelete}
                />
            }
        </>
    )
}