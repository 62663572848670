import { Box, IconButton, Typography } from '@mui/material';
import styles from './style.module.scss';
import { LoadingButton } from '@mui/lab';

function ActionPageTitle({ title, buttonText, buttonProps, extraButton, extraButton2 }) {
    return (
        <Box className={styles.container}>
            <Box className={styles.left}>
                <IconButton onClick={() => window.history.back()}>
                    <i className="bi bi-arrow-left-circle" />
                </IconButton>
                <Typography>
                    {title}
                </Typography>
            </Box>
            <Box className={styles.right}>
                {
                    extraButton ?
                        extraButton
                        : <></>
                }
                {
                    extraButton2 ?
                        extraButton2
                        : <></>
                }
                {
                    buttonText ?
                        <LoadingButton {...buttonProps}>
                            {buttonText}
                        </LoadingButton> :
                        <></>
                }
            </Box>
        </Box>
    )
}

export default ActionPageTitle