import { useEffect, useMemo, useRef } from 'react'
import { Marker, useMap } from 'react-leaflet';
import L from 'leaflet';
import MOVING from '../../../assets/markers/vehicle-start.svg';
import STARTED from '../../../assets/markers/vehicle-idling.svg';
import STOPPED from '../../../assets/markers/vehicle-stop.svg';
import { renderToStaticMarkup } from 'react-dom/server';
import MapVehiclePopup from '../MapVehiclePopup';
import { useNavigate } from 'react-router';
import styles from './style.module.scss';
import { toast } from 'react-toastify';

const MemoizedVehicleMarker = ({ vehicle, isActive }) => {
    const navigate = useNavigate();
    const map = useMap();
    const markerRef = useRef(null);
    const MARKER_POSITION = [vehicle.lastLog.latLng.lat, vehicle.lastLog.latLng.lng];
    const status = vehicle.lastLog.status;

    const icon = renderToStaticMarkup(
        <div className={styles.marker_container}>
            <img src={status === "MOVING" ? MOVING : status === "STARTED" ? STARTED : status === "STOPPED" ? STOPPED : "NOSIGNAL"} alt="" />
            <span className={styles[status]}>{vehicle.licensePlate}</span>
        </div >
    )

    const markerIcon = new L.divIcon({
        iconAnchor: [0, 0],
        popupAnchor: [0, -24],
        shadowAnchor: null,
        className: styles.vehicle_marker,
        html: icon,
    });

    useEffect(() => {
        if (isActive) {
            if (markerRef.current) {
                if (markerRef.current.focused) {
                    let zoom = map.getZoom();
                    map.flyTo(MARKER_POSITION, zoom);
                } else {
                    map.flyTo(MARKER_POSITION, 17);
                    if (markerRef.current) {
                        markerRef.current.focused = true;
                        markerRef.current.openPopup();
                    }
                }
            }
        } else {
            if (markerRef.current) {
                markerRef.current.closePopup();
                markerRef.current.focused = false;
            }
        }
    }, [vehicle.lastLog, markerRef.current, isActive])

    return (
        <Marker
            ref={markerRef}
            eventHandlers={{
                click: () => {
                    navigate(`/app/servisler/${vehicle.id}`);
                },
            }}
            key={vehicle.id}
            position={MARKER_POSITION}
            icon={markerIcon}
        >
            <MapVehiclePopup
                vehicle={vehicle}
            />
        </Marker>
    )
}

const VehicleMarker = ({ vehicle, isActive, navigate }) => useMemo(() => {
    if (isActive && !vehicle?.lastLog) {
        toast.warn(`${vehicle.licensePlate} plakalı araca ait veri bulunamadı!`);
        navigate("/app/servisler")
    }
    return (
        vehicle?.lastLog &&
        <MemoizedVehicleMarker
            vehicle={vehicle}
            isActive={isActive}
        />
    )
}, [vehicle.lastLog, isActive]);


export default VehicleMarker;

