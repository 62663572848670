import { useEffect, useLayoutEffect, useState } from 'react';
import { Typography, } from '@mui/material';
import { useNavigate } from 'react-router';
import { deleteTravelerRequest, getTravelersPageableRequest } from '../../../../api/controllers/traveler-controller';
import { useCustomer } from '../../../../context/CustomerContext';
import CustomDataGrid from '../../../atoms/CustomDataGrid';
import PopoverMenu from '../../../atoms/PopoverMenu';
import { Delete, Edit } from '@mui/icons-material';
import { getWorkTimesByCustomerIdRequest } from '../../../../api/controllers/work-time-controller';
import ChangeWorkTime from './ChangeWorkTime';
import { Stack } from '@mui/system';
import Search from '../../../molecules/Search';
import Filter from '../../../molecules/Filter';
import FilterList from '../../../molecules/FilterList';
import { toast } from 'react-toastify';
import ConfirmModal from '../../../atoms/DeleteConfirmModal';

export default function Travelers() {
    const { customer, setActionButtons } = useCustomer();
    const navigate = useNavigate();
    const [deleteId, setDeleteId] = useState(false)

    const [isTableLoading, setIsTableLoading] = useState(true);
    const [data, setData] = useState([]);
    const [pageable, setPageable] = useState({ page: 0, size: 10, total: 0 });
    const [selectedRows, setSelectedRows] = useState([]);
    const [changeWorkTimeModal, setChangeWorkTimeModal] = useState(false);
    const [workTimes, setWorkTimes] = useState([]);
    const [filter, setFilter] = useState({
        search: "",
        customerIdList: [customer.id],
        workTimeIdList: [],
    });


    const getData = async (type) => {
        if (type === "clear") {
            filter.workTimeIdList = [];
            setFilter(filter);
        }
        setIsTableLoading(true);
        try {
            let res = await getTravelersPageableRequest(pageable, filter);
            if (res) {
                setData(res.data.content);
                setPageable({ ...pageable, total: res.data.totalElements });
            }
        } catch (error) { }
        setIsTableLoading(false);
    }

    const handleDelete = async () => {
        try {
            let res = await deleteTravelerRequest(deleteId);
            if (res) {
                getData();
                setDeleteId(false);
                toast.success("Yolcu silindi");
            }
        } catch (error) { }
    }

    useEffect(() => {
        getData();
    }, [pageable.page, pageable.size, filter.search])

    useLayoutEffect(() => {
        setActionButtons([
            {
                onClick: () => setChangeWorkTimeModal(true),
                variant: "contained",
                color: "secondary",
                disabled: true,
                startIcon: <i className="bi bi-clock-history" />,
                text: "Vardiya Değiştir",
            },
            {
                onClick: () => navigate(`ekle`),
                variant: "contained",
                startIcon: <i className="bi bi-plus-lg" />,
                text: "Yolcu Ekle",
            }
        ])
        if (selectedRows.length > 0) {
            setActionButtons(prev => {
                prev[0].disabled = false;
                return (prev)
            })
        }

        return () => {
            setActionButtons([])
        };
    }, [selectedRows.length])

    useEffect(() => {
        (async () => {
            let res = await getWorkTimesByCustomerIdRequest(customer.id);
            if (res) {
                setWorkTimes(res.data);
            }
        })()
    }, [])


    //Tablo sütunları
    const EmployeeColumns = [
        {
            field: 'firstName',
            headerName: "İsim Soyisim",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value, row }) => value ? `${row.firstName} ${row.lastName}` : "-",
        },
        {
            field: 'phone',
            headerName: "Telefon",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value?.number ? `${value?.areaCode} ${value?.number}` : "-",
        },
        {
            field: 'workTime',
            headerName: "Vardiya",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => `${value.title} (${value.startTime.slice(0, 5)} - ${value.endTime.slice(0, 5)})` || "-",
        },
        {
            field: 'id',
            headerName: "",
            sortable: false,
            align: 'right',
            renderCell: ({ value }) =>
                <PopoverMenu
                    items={[
                        {
                            icon: <Edit fontSize="inherit" />,
                            text: "Düzenle",
                            onClick: () => navigate(`duzenle/${value}`),
                        },
                        {
                            icon: <Delete fontSize="inherit" />,
                            text: "Sil",
                            onClick: () => setDeleteId(value)
                        }
                    ]}
                />,
        },
    ]

    const StudentColumns = [
        {
            field: 'firstName',
            headerName: "İsim Soyisim",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value, row }) => value ? `${row.firstName} ${row.lastName}` : "-",
        },
        {
            field: 'parent',
            headerName: "Veli İsim Soyisim",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value, row }) => value ? `${row.parent.firstName} ${row.parent.lastName}` : "-",
        },
        {
            field: 'parent_phone',
            headerName: "Veli Telefon",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ row }) => row.parent?.phone?.number ? `${row.parent.phone?.areaCode} ${row.parent.phone?.number}` : "-",
        },
        {
            field: 'workTime',
            headerName: "Vardiya",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => `${value.title} (${value.startTime.slice(0, 5)} - ${value.endTime.slice(0, 5)})` || "-",
        },
        {
            field: 'id',
            headerName: "",
            sortable: false,
            align: 'right',
            renderCell: ({ value }) =>
                <PopoverMenu
                    items={[
                        {
                            icon: <Edit fontSize="inherit" />,
                            text: "Düzenle",
                            onClick: () => navigate(`duzenle/${value}`),
                        },
                        {
                            icon: <Delete fontSize="inherit" />,
                            text: "Sil",
                            onClick: () => setDeleteId(value)
                        }
                    ]}
                />,
        },
    ]

    return (
        <>

            <Stack spacing={2}>
                <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">
                        | Yolcular
                    </Typography>
                    <Stack direction="row" spacing={2}>
                        <Search
                            onChange={e => setFilter(prev => ({ ...prev, search: e }))}
                        />
                        <Filter
                            isEmpty={filter.workTimeIdList.length === 0}
                            onClear={() => getData("clear")}
                            onSubmit={getData}
                        >
                            <FilterList
                                title="Vardiyalara göre"
                                data={workTimes}
                                getLabel={v => v.title}
                                filter={filter}
                                setFilter={setFilter}
                                filterKey="workTimeIdList"
                            />
                        </Filter>
                    </Stack>
                </Stack>
                <CustomDataGrid
                    data={data}
                    columns={customer.customerType === "Company" ? EmployeeColumns : StudentColumns}
                    loading={isTableLoading}
                    rowId={(params) => params?.id}
                    pageable={pageable}
                    setPageable={setPageable}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                />
            </Stack>

            {
                changeWorkTimeModal &&
                <ChangeWorkTime
                    customer={customer}
                    changeWorkTimeModal={changeWorkTimeModal}
                    setChangeWorkTimeModal={setChangeWorkTimeModal}
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    workTimes={workTimes}
                    getData={getData}
                />
            }

            {
                deleteId &&
                <ConfirmModal
                    open={deleteId}
                    title={"Emin misiniz?"}
                    setOpen={setDeleteId}
                    message={"Yolcuyu silmek istediğinize emin misiniz?"}
                    handleConfirm={handleDelete}
                />
            }
        </>
    )
}