import { useEffect, useMemo, useRef } from 'react'
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import MOVING from '../../../assets/markers/vehicle-start.svg';
import STARTED from '../../../assets/markers/vehicle-idling.svg';
import STOPPED from '../../../assets/markers/vehicle-stop.svg';
import { renderToStaticMarkup } from 'react-dom/server';
import MapVehiclePopup from '../MapVehiclePopupForHistory';
import styles from './style.module.scss';

const MemoizedVehicleMarker = ({ log, resetHistory }) => {
    const markerRef = useRef(null);
    const MARKER_POSITION = [log.latLng.lat, log.latLng.lng];
    const status = log.status;

    const icon = renderToStaticMarkup(
        <div className={styles.marker_container}>
            <img src={status === "MOVING" ? MOVING : status === "STARTED" ? STARTED : status === "STOPPED" ? STOPPED : "NOSIGNAL"} alt="" />
            <span className={styles[status]}>{log.vehicle.licensePlate}</span>
        </div >
    )

    const markerIcon = new L.divIcon({
        iconAnchor: [0, 0],
        popupAnchor: [0, -24],
        shadowAnchor: null,
        className: styles.vehicle_marker,
        html: icon,
    });

    useEffect(() => {
        if (markerRef.current) {
            markerRef.current.openPopup();
        }
    }, [markerRef])


    return (
        <Marker
            ref={markerRef}
            position={MARKER_POSITION}
            icon={markerIcon}
        >
            <MapVehiclePopup
                log={log}
                resetHistory={resetHistory}
            />
        </Marker>
    )
}

const VehicleMarker = ({ log, resetHistory }) => useMemo(() => {
    return (
        log?.latLng &&
        <MemoizedVehicleMarker
            log={log}
            resetHistory={resetHistory}
        />
    )
}, [log?.id]);

export default VehicleMarker;

