import { Box, Fade } from '@mui/material'
import { default as Logo } from '../../assets/logo.svg'

const SuspenseFallback = () => {
    return (
        <Fade in={true} >
            <Box style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}>
                <img src={Logo} alt="" style={{ maxWidth: "300px" }} />
            </Box>
        </Fade>
    )
}

export default SuspenseFallback;
