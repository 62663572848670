import React from "react";
import { Box, Typography } from "@mui/material";
import { DirectionsBusRounded } from "@mui/icons-material";
import styles from "./style.module.scss";
import DetailTag from "../../atoms/DetailTag";
import PlansTypes from "../../../types/PlansContext";
import OverTimeTypes from "../../../types/OverTimeContext";

type Props = {
  plan: OverTimeTypes.Plan | PlansTypes.Plan;
  show?: boolean;
  onClick?: () => void;
};

function PlanCard({ plan, show, onClick }: Props) {
  return (
    <button onClick={onClick} className={styles.container}>
      <Box className={styles.header}>
        {/*  <IconButton size="small" color="inherit">
                    {
                        show ?
                            <VisibilityOffRounded fontSize="inherit" color="inherit" />
                            :
                            <VisibilityRounded fontSize="inherit" color="inherit" />
                    }
                </IconButton> */}
        <Typography className={styles.title}>
          {plan.title || "Güzergah"}
        </Typography>
      </Box>
      <Box className={styles.tags}>
        <DetailTag
          icon={<DirectionsBusRounded fontSize="inherit" />}
          text={plan.vehicle?.licensePlate || "Bilinmiyor"}
        />
        <DetailTag
          icon={<i className="bi bi-person-fill" />}
          text={
            plan?.vehicle?.driver
              ? `${plan.vehicle.driver.firstName || ""} ${plan.vehicle.driver?.lastName || ""
              }`
              : "Bilinmiyor"
          }
        />
      </Box>
    </button>
  );
}

export default PlanCard;
