import { Box, Typography } from '@mui/material';
import styles from './style.module.scss';

function Subtitle({ title }) {
    return (
        <Box className={styles.container}>
            <Typography variant="h6" className={styles.title}>{title}</Typography>
        </Box>
    )
}

export default Subtitle