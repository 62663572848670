import { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { Box, Autocomplete, TextField, Divider, Button, IconButton } from '@mui/material';
import { getCustomersRequest } from '../../../api/controllers/customer-controller';
import { getWorkTimesByCustomerIdRequest } from '../../../api/controllers/work-time-controller';
import MapDisplayButton from '../../atoms/MapDisplayButton';
import traveler_icon from '../../../assets/markers/traveler.svg';
import traveler_gray_icon from '../../../assets/markers/traveler_gray.svg'
import station_icon from '../../../assets/markers/station.svg';
import { CloseRounded, MenuRounded, RouteRounded, ShareLocationRounded } from '@mui/icons-material';
import { useCustomer } from '../../../context/CustomerContext';
import { usePlans } from '../../../context/PlansContext';
import CustomPopup from '../CustomPopup';
import { useAuth } from '../../../context/AuthContext';
import { useNavigate, useParams } from 'react-router';
import moment from 'moment';
import { DatePicker } from '@mui/x-date-pickers';
import { LoadingButton } from '@mui/lab';
import FilterArea from '../FilterArea';

const PlannerNavigation = ({ setAreaPolygon, setShowSidebar, history, setHistory, getHistory, resetHistory, isHistory }) => {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const { role } = currentUser;
    const [customers, setCustomers] = useState([]);
    const [workTimes, setWorkTimes] = useState([]);
    const { customerId, workTimeId, planUuid } = useParams();
    const { travelers, plan, plans, filterMarker, setFilterMarker } = usePlans();
    const { customer } = useCustomer();
    const { planType } = useParams()

    useEffect(() => {
        if (role === "ADMIN" || role === "MANAGER") {
            (async () => {
                let res = await getCustomersRequest();
                if (res) {
                    setCustomers(res.data);
                    if (!customerId) {
                        navigate(`/app/guzergahlar/customer/${res.data[0].id}`)
                    }
                }
            })()
        } else if (role === "CUSTOMER") {
            navigate(`/app/guzergahlar/customer/${currentUser.id}`)
        }
    }, [])

    useEffect(() => {
        if (customerId) {
            (async () => {
                let res = await getWorkTimesByCustomerIdRequest(customerId);
                if (res) {
                    setWorkTimes(res.data);
                    if (!workTimeId && res.data.length > 0) {
                        navigate(`/app/guzergahlar/customer/${customerId}/workTime/${res.data[0].id}/departure`)
                    }
                }
            })()
        }
    }, [customerId])

    return (
        <Box component="nav" className={styles.navbar}>
            <Box className={styles.content}>
                {
                    (role === "ADMIN" || role === "MANAGER") &&
                    <Autocomplete
                        className={styles.autocomplete_province}
                        size="small"
                        options={customers}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                label='Müşteri'
                                placeholder='Listeden seçin'
                            />}
                        getOptionLabel={(option) => option.title}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        value={customerId ? customer : null}
                        onChange={(event, newValue) => {
                            if (newValue) {
                                navigate(`/app/guzergahlar/customer/${newValue.id}`)
                            } else {
                                navigate(`/app/guzergahlar`)
                            }
                        }}
                    />
                }
                <Autocomplete
                    className={styles.autocomplete_workTime}
                    size="small"
                    options={workTimes}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label='Vardiya'
                            placeholder='Listeden seçin'
                        />}
                    getOptionLabel={(option) => `${option.title} (${option.startTime.slice(0, 5)} - ${option.endTime.slice(0, 5)})` || "-"}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    value={workTimes.find(v => v.id == workTimeId) || null}
                    onChange={(event, newValue) => {
                        if (newValue) {
                            navigate(`/app/guzergahlar/customer/${customerId}/workTime/${newValue.id}/departure`)
                        } else {
                            navigate(`/app/guzergahlar/customer/${customerId}`)
                        }
                    }}
                />
                {
                    (role === "ADMIN" || role === "MANAGER") &&
                    <MapDisplayButton
                        icon={<img src={traveler_icon} alt="" />}
                        count={Array.isArray(travelers) ? travelers.filter(v => v[planType]?.enable).length : 0}
                        filterFunc={() => setFilterMarker(prev => ({ ...prev, traveler: { ...prev.traveler, available: !prev.traveler.available } }))}
                        show={!filterMarker.traveler.available}
                    />
                }
                {
                    (role === "ADMIN" || role === "MANAGER") &&
                    <MapDisplayButton
                        icon={<img src={traveler_gray_icon} alt="" />}
                        count={Array.isArray(travelers) ? travelers.filter(v => !v[planType]?.enable).length : 0}
                        filterFunc={() => setFilterMarker(prev => ({ ...prev, traveler: { ...prev.traveler, unavailable: !prev.traveler.unavailable } }))}
                        show={!filterMarker.traveler.unavailable}
                    />
                }
                {
                    (role === "ADMIN" || role === "MANAGER") && planUuid &&
                    <MapDisplayButton
                        icon={<img src={station_icon} alt="" />}
                        count={plan?.stationList?.length || 0}
                        filterFunc={() => setFilterMarker(prev => ({ ...prev, station: !prev.station }))}
                        show={!filterMarker.station}
                    />
                }
                {
                    !planUuid &&
                    <MapDisplayButton
                        icon={<RouteRounded fontSize="inherit" />}
                        count={plans[planType]?.length || 0}
                        filterFunc={() => setFilterMarker(prev => ({ ...prev, routes: !prev.routes }))}
                        show={!filterMarker.routes}
                    />
                }
                {
                    (role === "ADMIN" || role === "MANAGER") &&
                    <Divider orientation="vertical" flexItem />
                }
                {
                    (role === "ADMIN" || role === "MANAGER") &&
                    <FilterArea setAreaPolygon={setAreaPolygon} />
                }
                {
                    (role === "ADMIN" || role === "MANAGER" || role == "CUSTOMER") && planUuid &&
                    <CustomPopup
                        buttonText={isHistory ? `${moment(history.date).format('L')}` : "Güzergah Geçmişi"}
                        buttonProps={{
                            startIcon: <ShareLocationRounded fontSize="inherit" />,
                            endIcon: history.date && history.data.length > 0 && <IconButton size="small" onClick={resetHistory}><CloseRounded fontSize="inherit" /></IconButton>
                        }}>
                        <DatePicker
                            renderInput={(props) => <TextField {...props} />}
                            label="Tarih"
                            value={history.date}
                            onChange={(newValue) => {
                                setHistory({ ...history, date: newValue || null })
                            }}
                            maxDate={new Date()}
                        />
                        <LoadingButton
                            loading={history.isLoading}
                            disabled={!history.date}
                            sx={{ width: "100%" }}
                            variant="contained"
                            onClick={getHistory}
                        >
                            Geçmişi göster
                        </LoadingButton>
                    </CustomPopup>
                }
            </Box>
            <Button startIcon={<MenuRounded fontSize="inherit" />} variant="contained" onClick={() => setShowSidebar(true)}>
                Menü
            </Button>
        </Box>
    )
}

export default PlannerNavigation;