import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styles from './ExcelUpload.module.scss';
import { toast } from 'react-toastify';

function ExcelUpload({ handleUpload }) {

    const onDrop = useCallback(acceptedFiles => {
        if (acceptedFiles.length > 0) {
            handleUpload(acceptedFiles[0]);
        } else {
            toast("Ekleme yapabilmek için yalnızca Excel dosyası yükleyin! (.csv, .xls, .xlsx)", { type: "warning" })
        }
    }, [])
    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        multiple: false,
        accept: {
            'text/csv': ['.csv'],
            'application/vnd.ms-excel': ['.xls'],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
        },
        onDrop
    })

    return (
        <div className={styles.drop_area} {...getRootProps()}>
            <input {...getInputProps()} />
            <i className="bi bi-file-earmark-plus" />
            {
                isDragActive ?
                    <p>Belgeyi bu alana bırakın.</p> :

                    <p>Belgeyi bu alana sürükleyebilir veya tıklayarak seçebilirsiniz.</p>
            }
        </div>
    );
};

export default ExcelUpload;