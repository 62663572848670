import {
  ContextType,
  CREATE_FORM_DEFAULT_TYPE,
  CREATE_STATION_FORM_DEFAULT_TYPE,
  OverTime,
  Plan,
  Plans,
  Traveler,
  UpdateForm,
  UPDATE_FORM_DEFAULT_TYPE,
} from "../types/OverTimeContext";
import { createContext, useContext, useState } from "react";
import { getOverTimeRequest } from "../api/controllers/overtime-controller";
import { getPlansByQueryRequest } from "../api/controllers/plan-controller";
import { getStationByPlanIdRequest } from "../api/controllers/station-controller";
import { FilterMarker, PlanType, Station, Address2 } from "../types/CommonTypes";

type Props = {
  children: React.ReactNode;
};

const Context = createContext<ContextType | null>(null);

export default function Provider({ children }: Props) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [filterMarker, setFilterMarker] = useState<FilterMarker>({
    traveler: {
      available: true,
      unavailable: true,
    },
    station: true,
    routes: true,
  });
  const [areaPolygon, setAreaPolygon] = useState([]);
  const [showSidebar, setShowSidebar] = useState<boolean>(true);
  const [travelers, setTravelers] = useState<Traveler[]>([]);
  const [overTime, setOverTime] = useState<OverTime>();
  const [plan, setPlan] = useState<Plan>();
  const [plans, setPlans] = useState<Plans>({
    departure: [],
    arrival: [],
  });
  const [station, setStation] = useState<Station>();
  const [createForm, setCreateForm] =
    useState<CREATE_FORM_DEFAULT_TYPE>(CREATE_FORM_DEFAULT);
  const [updateForm, setUpdateForm] = useState<UpdateForm>({
    step: 1,
    plan: {
      id: null,
      distance: null,
      duration: null,
      endTime: null,
      startTime: null,
      vehicle: null,
      workTime: null,
      stations: [],
      title: null,
      uuid: null,
      planDirectionType: null,
      isVoyage: null,
    },
    route: null,
  });
  const [stationForm, setStationForm] = useState(CREATE_STATION_FORM_DEFAULT);

  const resetCreateForm = () => setCreateForm(CREATE_FORM_DEFAULT);
  const resetUpdateForm = () => setUpdateForm(UPDATE_FORM_DEFAULT);
  const resetStationForm = () => setStationForm(CREATE_STATION_FORM_DEFAULT);

  const getPlan = async (planUuid: string, planType: PlanType) => {
    setIsLoading(true);
    let res = await getPlansByQueryRequest(
      `uuid=='${planUuid}';planDirectionType=='${planType ? planType.toUpperCase() : "ARRIVAL"
      }'`
    );
    if (res) {
      setIsLoading(false);
      let stations = await getStations(res.data[0].id);
      res.data[0].stationList = stations;
      return res.data[0] as Plan;
    }
    setIsLoading(false);
  };

  const getStations = async (planId: string) => {
    setIsLoading(true);
    let res = await getStationByPlanIdRequest(planId);
    if (res) {
      setIsLoading(false);
      return res.data as Station[];
    }
    setIsLoading(false);
  };

  const getOverTime = async (mesaiId: number) => {
    let res = await getOverTimeRequest(mesaiId);
    if (res) {
      setOverTime(res.data);
      setPlans({
        arrival: Array.isArray(res.data.plans)
          ? res.data.plans.filter(
            (v: Plan) => v.planDirectionType === "ARRIVAL"
          )
          : [],
        departure: Array.isArray(res.data.plans)
          ? res.data.plans.filter(
            (v: Plan) => v.planDirectionType === "DEPARTURE"
          )
          : [],
      });

      return res.data as OverTime;
    }
  };

  const data: ContextType = {
    isLoading,
    setIsLoading,
    filterMarker,
    setFilterMarker,
    showSidebar,
    setShowSidebar,
    travelers,
    setTravelers,
    overTime,
    setOverTime,
    plan,
    setPlan,
    plans,
    setPlans,
    station,
    setStation,
    createForm,
    setCreateForm,
    updateForm,
    setUpdateForm,
    stationForm,
    setStationForm,
    resetCreateForm,
    resetUpdateForm,
    resetStationForm,
    getPlan,
    getStations,
    getOverTime,
    areaPolygon,
    setAreaPolygon,
    CREATE_STATION_FORM_DEFAULT
  };

  return <Context.Provider value={data}>{children}</Context.Provider>;
}

export const useOverTime = () => useContext(Context) as ContextType;

const CREATE_FORM_DEFAULT: CREATE_FORM_DEFAULT_TYPE = {
  planDirectionType: "DEPARTURE",
  title: "",
  vehicleId: null,
};

const CREATE_STATION_FORM_DEFAULT: CREATE_STATION_FORM_DEFAULT_TYPE = {
  position: {
    latitude: null,
    longitude: null,
  },
  title: "",
  travelersId: [],
};

const UPDATE_FORM_DEFAULT: UPDATE_FORM_DEFAULT_TYPE = {
  step: 1,
  plan: {
    id: null,
    title: null,
    uuid: null,
    vehicle: null,
    workTime: null,
    stations: [],
    endTime: null,
    duration: null,
    cost: null,
    distance: null,
    startTime: null,
    planDirectionType: null,
    isVoyage: null,
  },
  route: null,
}
