import axios from "axios";

export const deleteStationStudentRequest = (id) => axios.delete(`/stationStudent/${id}`)

export const getStationsStudentRequest = (pageable) => axios.get(`/stationStudent/allStations?page=${pageable.page}&size=${pageable.size}`)

export const getStationStudentForManagerRequest = (pageable) => axios.get(`/stationStudent/myStations?page=${pageable.page}&size=${pageable.size}`)

export const getStationStudentByPlanIdRequest = (planId) => axios.get(`/stationStudent/plan/${planId}`)

export const getStationStudentByWorkTimeIdRequest = (workTimeId) => axios.get(`/stationStudent/workTime/${workTimeId}`)

export const getStationSturdentIdRequest = (id) => axios.get(`/stationStudent/${id}`)

export const saveStationStudentRequest = ( planId, form) => axios.post(`/station/plan/${planId}`, form)