import React from 'react'
import { Checkbox, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { usePlans } from '../../context/PlansContext';
import { useParams } from 'react-router';

function EmployeeTravelerMarkerItemMultiple({ traveler }) {
    const { stationForm, setStationForm } = usePlans();
    const { planType } = useParams();

    const selected = stationForm.travelerIds.some(v => v.id == traveler.id);
    const editable = window.location.pathname.includes("station")

    const handleClick = () => {
        if (editable) {
            if (selected) {
                setStationForm(prev => ({ ...prev, travelerIds: prev.travelerIds.filter(v => v.id !== traveler.id) }));
            } else {
                setStationForm(prev => ({ ...prev, travelerIds: [...prev.travelerIds, traveler] }));
            }
        }
    }

    return (
        <ListItem
            secondaryAction={
                <Checkbox
                    size="small"
                    edge="end"
                    checked={!editable ? !traveler[planType]?.enable : selected}
                    onClick={() => editable ? handleClick(traveler) : {}}
                    disabled={selected ? false : !traveler[planType]?.enable}
                />
            }
            //onClick={() => editable ? handleClick(traveler) : {}}
            disablePadding
        >
            <ListItemButton
                disabled={selected ? false : !traveler[planType]?.enable}
                onClick={() => editable ? handleClick(traveler) : {}}
            >
                <ListItemText primary={traveler?.firstName + " " + traveler?.lastName} />
            </ListItemButton>
        </ListItem>
    )
}

export default EmployeeTravelerMarkerItemMultiple;