import { useEffect, useState } from 'react';
import { getManagersPageableRequest, deleteManagerRequest} from '../../../api/controllers/manager-controller';
import PageLayout from '../../../components/templates/PageLayout';
import { MenuItem, ListItemIcon} from '@mui/material';
import CustomDataGrid from '../../../components/atoms/CustomDataGrid';
import CustomMenu from '../../../components/molecules/CustomMenu';
import { Info, Delete} from '@mui/icons-material';
import { useNavigate} from 'react-router';
import Search from '../../../components/molecules/Search';
import CreateManagerModal from './yonetici-ekle';
import UpdateManagerModal from './yonetici-duzenle';
import { Stack } from '@mui/system';
import ConfirmModal from '../../../components/atoms/DeleteConfirmModal';
import {useSearchParams} from 'react-router-dom';
import { toast } from 'react-toastify';

function Page() {
    const navigate = useNavigate();

    //States
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [data, setData] = useState([]);
    const [pageable, setPageable] = useState({ page: 0, size: 10, total: 0 });

    const [createModal, setCreateModal] = useState(false);
    const [updateModal, setUpdateModal] = useState(false);
    const [selectedManagerId, setSelectedManagerId] = useState(null);
    const [filter, setFilter] = useState({
        search: "",
    });

    const [searchParams, setSearchParams] = useSearchParams();
    const deleteId = searchParams.get("deleteId");
    
    //Tablo sütunları
    const columns = [
        {
            field: 'firstName',
            headerName: "İsim",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value || "-",
        },
        {
            field: 'lastName',
            headerName: "Soyisim",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value || "-",
        },
        {
            field: 'phone',
            headerName: "Telefon",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? `${value.areaCode || ""} ${value.number || ""}` : "-",
        },
        {
            field: 'email',
            headerName: "Mail Adresi",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value || "-",
        },
        {
            field: 'id',
            headerName: "",
            sortable: false,
            align: 'right',
            minWidth: 200,
            renderCell: ({ value }) => <CustomMenu>
                <MenuItem onClick={async () => navigate(`/app/yoneticiler/detay/${value}`)}>
                    <ListItemIcon>
                        <Info fontSize="small" />
                    </ListItemIcon>
                    Detaylar
                </MenuItem>

                <MenuItem onClick={() =>setSearchParams({deleteId:value})}>
                    <ListItemIcon>
                        <Delete fontSize="small" />
                    </ListItemIcon>
                    Sil
                </MenuItem>
            </CustomMenu >
        },
    ]


    //Yöneticileri getirir
    const getManagers = async () => {
        setIsTableLoading(true);
        try {
            let res = await getManagersPageableRequest(pageable, filter);
            if (res) {
                setData(res.data.content);
                setPageable({ ...pageable, total: res.data.totalElements });
            }
        } catch (error) { }
        setIsTableLoading(false);
    }

    const deleteManager = async (deleteId) => {
        let res = await deleteManagerRequest(deleteId);
        if (res) {
            setData(data.filter(item => item.id?.toString() !== deleteId));
            setSearchParams(false);
            toast.success("Yönetici silindi");
        }
    }

    useEffect(() => {
        getManagers();
    }, [pageable.page, pageable.size, filter.search])

    return (
        <PageLayout
            title="Yöneticiler"
            buttons={[
                {
                    onClick: () => setCreateModal(true),
                    variant: "contained",
                    startIcon: <i className="bi bi-plus-lg"></i>,
                    text: "Yönetici Ekle",
                }
            ]}>
            <Stack spacing={2}>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                    <Search
                        onChange={e => setFilter(prev => ({ ...prev, search: e }))}
                    />
                </Stack>
                <CustomDataGrid
                    data={data}
                    columns={columns}
                    loading={isTableLoading}
                    rowId={(params) => params?.id}
                    pageable={pageable}
                    setPageable={setPageable}
                />
            </Stack>


            {/* Ekleme modalı */}
            {
                createModal &&
                <CreateManagerModal
                    createModal={createModal}
                    setCreateModal={setCreateModal}
                    getManagers={getManagers}
                    setSelectedManagerId={setSelectedManagerId}
                />
            }

            {/* Güncelleme modalı */}
            {
                updateModal &&
                <UpdateManagerModal
                    updateModal={updateModal}
                    setUpdateModal={setUpdateModal}
                    getManagers={getManagers}
                    selectedManagerId={selectedManagerId}
                    setSelectedManagerId={setSelectedManagerId}
                />
            }
            {/* Delete modal */}
            {
                deleteId && 
                <ConfirmModal 
                    open={deleteId}
                    title={"Emin misiniz?"}
                    setOpen={setSearchParams}
                    message={"Yöneticiyi silmek istediğinize emin misiniz?"}
                    handleConfirm = {()=>deleteManager(deleteId)}
                />
            }
        </PageLayout>
    )
}

export default Page;

