import { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { getAddress } from '../../components/Utils';
import CustomPopup from '../molecules/CustomPopup';
import { mapSearchListRequest } from '../../api/controllers/map-controller';

function FilterArea({ setAreaPolygon }) {

    const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [neighborhoods, setNeighborhoods] = useState([]);
    const [form, setForm] = useState({
        province: "Denizli",
        country: "Türkiye",
        district: [],
        neighborhood: []
    })


    const getPolygon = async () => {
        let res = await mapSearchListRequest(form?.district.map((districtName) => ({
            districtName,
            neighbourhoodNames: neighborhoods
                .filter((neighborhood) => form?.neighborhood.includes(neighborhood?.neighborhoodName) && neighborhood?.districtName === districtName)
                .map((neighborhood) => neighborhood?.neighborhoodName),
        })), form.province
        )
        if (res) {
            if (res.data.length > 0) {
                const list = form.neighborhood.map((v, i) => (
                    { text: v, coordinates: res.data[i].map(v => ([v.lat, v.lng])) }
                ))
                setAreaPolygon(list);
            }
        }
    }

    useEffect(() => {
        if (form.neighborhood.length > 0) {
            getPolygon();
        } else {
            setAreaPolygon([]);
        }
    }, [form.province, form.district, JSON.stringify(form.neighborhood)])

    useEffect(() => {
        getAddress({ setProvinces, setDistricts, setNeighborhoods, province: form.province, district: form.district })
    }, [form.province, form.district])


    return (
        <CustomPopup
            buttonText={
                <span style={{ lineClamp: 2, textOverflow: "ellipsis", maxWidth: "200px", overflow: "hidden", whiteSpace: "nowrap", display: "inline-block" }}>
                    {
                        form.neighborhood.length > 0 ? form.neighborhood.map((v, i) => v + (form.neighborhood.length - 1 !== i ? ", " : "")) : "Bölge Seç"
                    }
                </span>
            }
            buttonProps={{ startIcon: <i className="bi bi-pin-map-fill" style={{ fontSize: "inherit" }} /> }}>
            <Autocomplete
                options={provinces.map(p => p?.provinceName)}
                sx={{ width: 200 }}
                value={form.province}
                onChange={(event, newValue) => {
                    form.province = newValue;
                    form.district = null;
                    form.neighborhood = [];
                    setForm({ ...form })
                }}
                isOptionEqualToValue={(option, value) => option === value}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label='İl'
                    />}
            />
            <Autocomplete
                multiple
                limitTags={2}
                disabled={!form.province}
                options={districts.map(d => d?.districtName)}
                isOptionEqualToValue={(option, value) => option === value}
                sx={{ width: 200 }}
                value={form.district || []}
                onChange={(event, newValue) => {
                    form.district = newValue;
                    form.neighborhood = [];
                    setForm({ ...form })
                }}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label='İlçe'
                        fullWidth
                    />}
            />
            <Autocomplete
                multiple
                limitTags={2}
                disabled={!form.district}
                options={neighborhoods.map(n => n?.neighborhoodName)}
                isOptionEqualToValue={(option, value) => option === value}
                sx={{ width: 200 }}
                value={form.neighborhood || []}
                onChange={(event, newValue) => {
                    setForm({ ...form, neighborhood: newValue })
                }}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label='Mahalle'
                        fullWidth
                    />}
            />
        </CustomPopup>
    )
}

export default FilterArea;