import { Avatar, Button, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import PageLayout from '../../../components/templates/PageLayout';
import CustomDataGrid from '../../../components/atoms/CustomDataGrid';
import { getDailyTaskCustomerReportByVehicleId, } from '../../../api/controllers/daily-task-controller';
import styles from './servisler.module.scss';
import { Box } from '@mui/system';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';
import { useNavigate, useParams } from 'react-router-dom';
import { CheckRounded, CloseRounded } from '@mui/icons-material';
import { getVehicleRequest } from '../../../api/controllers/vehicle-controller';

const Page = () => {
    const navigate = useNavigate()
    const { vehicleId, date } = useParams();
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [data, setData] = useState([]);
    const [data2, setData2] = useState([]);
    const [totalVoyagePrice, setTotalVoyagePrice] = useState(null)
    const [licensePlate, setLicensePlate] = useState(null)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);
    const columns = [
        {
            field: 'driverConfirm',
            headerName: "Durum",
            sortable: false,
            renderCell: ({ value }) => (
                <Tooltip title={value ? "Tamamlandı" : "Tamamlanmayı Bekliyor"}>
                    <Avatar sx={{ width: 24, height: 24, fontSize: 14, bgcolor: value ? "var(--success_12)" : "var(--danger_12)" }}                >
                        {value ? <CheckRounded htmlColor="var(--success)" fontSize="inherit" /> : <CloseRounded htmlColor="var(--danger)" fontSize="inherit" />}
                    </Avatar>
                </Tooltip>
            ),
        },
        {
            field: 'company',
            headerName: "Firma Adı",
            flex: 1,
            minWidth: 150,
            renderCell: ({ row }) => row.workTime.customer.title || "-"
        },
        {
            field: 'planTitle',
            headerName: "Sefer Başlığı",
            flex: 1,
            minWidth: 150,
            renderCell: ({ row }) => <Tooltip title={row.planTitle || "-"} >
                <p>
                    {row.planTitle || "-"}
                </p>
            </Tooltip>
        },
        {
            field: 'date',
            headerName: "Tarih",
            flex: 1,
            minWidth: 120,
            valueGetter: ({ value }) => value ? value : "-",
        },
        {
            field: 'workTime',
            headerName: "Zaman",
            flex: 1,
            minWidth: 100,
            valueGetter: ({ value, row }) => value ? row.planDirectionType === 'ARRIVAL' ? row.endTime.slice(0, 5) : row.startTime.slice(0, 5) : "-",
        },
        {
            field: 'dailyTaskType',
            headerName: "Sefer Tipi",
            flex: 1,
            minWidth: 150,
            valueGetter: ({ value }) => value ? value === 'REGULAR' ? 'Tek Yön' : value === 'DELAYED' ? 'Tek Yön Ertelenmiş' : value === 'OVERTIME' ? 'Ek Mesai' : value === 'VOYAGE' ? 'Ek Mesai' : value : "-",
        },
        {
            field: 'voyagePrice',
            headerName: "Ücreti",
            flex: 1,
            minWidth: 120,
            valueGetter: ({ value }) => value ? value + " ₺" : "-",
        },
    ]

    const getReportByVehicleId = async () => {
        setIsTableLoading(true);
        try {
            let res = await getDailyTaskCustomerReportByVehicleId(vehicleId, moment(date).format("yyyy-MM-DD"));
            if (res) {
                setData(res.data.dailyTasks)
                setData2([...res.data.dailyTasks, { price: res.data.totalVoyagePrice, id: "priceTotal", driverConfirm: 'none' }]);
                setTotalVoyagePrice(res.data.totalVoyagePrice)
            }

        } catch (error) { }
        setIsTableLoading(false);
    }

    const getVehicle = async () => {
        let res = await getVehicleRequest(vehicleId)
        if (res) {
            setLicensePlate(res.data.licensePlate)
        }
    }

    useEffect(() => {
        if (vehicleId) {
            getReportByVehicleId();
        }
        if (!licensePlate) {
            getVehicle()
        }
    }, [])

    //Excel Raporlama
    const dataExcel = data2.map((elt) => (
        { planTitle: elt.planTitle || "", date: elt.date || "", time: elt.planDirectionType === 'ARRIVAL' ? elt.endTime || "" : elt.startTime || "", dailyTaskType: elt.dailyTaskType === 'REGULAR' ? "Tek Yön" : elt.dailyTaskType === 'DELAYED' ? 'Tek Yön Ertelenmiş' : elt.dailyTaskType === 'OVERTIME' ? 'Ek Mesai' : elt.dailyTaskType === 'VOYAGE' ? 'Ek Mesai' : "", voyagePrice: elt.voyagePrice || "", driverConfirm: elt.driverConfirm === true ? 'Tamamlandı' : elt.driverConfirm === false ? 'Tamamlanmadı' : elt.driverConfirm === 'none' ? "" : "-", totalVoyagePrice: elt.price === 0 ? "0" : elt.price || "", space: "" }
    ))
    const headersExcel = [
        { label: "Tarih", key: "date" },
        { label: "Zaman", key: "time" },
        { label: "Açıklama", key: "planTitle" },
        { label: "Sefer Tipi", key: "dailyTaskType" },
        { label: "Ücreti", key: "voyagePrice" },
        { label: "Durum", key: "driverConfirm" },
        { label: "", key: "space" },
        { label: "Toplam Hak Edilen Sefer Ücreti", key: "totalVoyagePrice" }
    ]

    return (
        <PageLayout
            title={`${licensePlate} - ${moment(date).format("MMMM")} Raporu`}
            buttons={[]}>
            <>
                <Box className={styles.layout}>
                    <Box className={styles.header}>
                        <Typography
                            variant="h6"
                            sx={{}}
                        >
                            | Raporlar
                        </Typography>
                    </Box>
                    <Box className={styles.right}>
                        <Box className={styles.priceLayout}>
                            Hak Edilen Sefer Ücreti = {totalVoyagePrice} ₺
                        </Box>
                        <CSVLink data={dataExcel} headers={headersExcel} separator={";"} filename={"hakediş.csv"}>
                            <Button startIcon={<DownloadIcon />} variant="outlined" sx={{ color: "#4CAF50", borderColor: "#4CAF50", minWidth: "100px" }} >Excel</Button>
                        </CSVLink>
                    </Box>
                </Box>
                <CustomDataGrid
                    data={data}
                    columns={columns}
                    loading={isTableLoading}
                    rowId={(params) => params?.id}
                />
            </>
        </PageLayout >
    );
}

export default Page;