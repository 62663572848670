import { useState } from 'react'
import { Alert, Box } from '@mui/material';
import CustomDialog from '../atoms/CustomDialog';
import DetailTag from '../atoms/DetailTag'
import DocumentView from '../atoms/DocumentView'
import axios from 'axios';

function DriverDocumentModal({ text, driverId }) {
    const [modal, setModal] = useState(false);
    const [documents, setDocuments] = useState([]);

    const handleClick = async () => {
        let res = await axios.get(`/document/driver/${driverId}`);
        if (res) {
            setDocuments(res.data);
            setModal(true);
        }
    }

    const handleClose = () => {
        setModal(false);
        setDocuments([]);
    }

    return (
        <>
            <Box onClick={handleClick} sx={{ cursor: "pointer" }}>
                <DetailTag
                    icon={<i className="bi bi-person-fill" />}
                    text={text}
                />
            </Box>
            <CustomDialog
                open={modal}
                closeFunc={handleClose}
                title="Sürücüye ait belgeler"
            >
                <Box display="flex" gap="1.5rem" flexWrap="wrap">
                    {
                        documents.length > 0
                            ?
                            documents.map((val, i) =>
                                <DocumentView
                                    key={i}
                                    name={val.documentType.name}
                                    document={val}
                                />
                            )
                            :
                            <Alert sx={{ width: "100%" }} severity="warning">Belge bulunamadı!</Alert>
                    }
                </Box>
            </CustomDialog >
        </>
    )
}

export default DriverDocumentModal