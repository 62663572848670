import { Link } from 'react-router-dom';
import styles from './sayfa-bulunamadi.module.scss';
import NotFoundImage from '../assets/not-found.svg';

function Page() {
    return (
        <main>
            <img src={NotFoundImage} alt="" />

            <p id={styles.errorText}>Aradığınız sayfa bulunamadı.</p>
            <Link to="/" id={styles.errorLink}>Anasayfaya dön</Link>
        </main>
    )
}

export default Page;