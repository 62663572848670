import React, { useEffect, useState } from 'react';
import { TextField, Autocomplete } from '@mui/material';
import { getDistrictsByProvinceIdRequest, getNeighborhoodsByDistrictIdRequest } from '../../../../../api/controllers/addresses-controller';

export const ProvinceInput = React.memo(({ value, row, provinces, uploadResult, setUploadResult }) => {
    return (
        <Autocomplete
            fullWidth
            options={provinces.map(p => p.provinceName)}
            value={value.province || null}
            onChange={(event, newValue) => {
                uploadResult.content[row.index].address.province = newValue;
                setUploadResult({ ...uploadResult });
            }}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) =>
                <TextField
                    {...params}
                    size="small"
                    placeholder="İl"
                    error={!value.province || !provinces.some(p => p.provinceName === value.province)}
                />}
        />
    )
})


export const DistrictInput = React.memo(({ value, row, provinces, uploadResult, setUploadResult }) => {
    const [districts, setDistricts] = useState([]);
    useEffect(() => {
        (async () => {
            let selectedProvince = provinces.find(v => v.provinceName === value.province);
            let res = await getDistrictsByProvinceIdRequest(selectedProvince.id);
            if (res) {
                setDistricts(res.data);
            }
        })()
    }, [value.province])

    return (
        <Autocomplete
            fullWidth
            options={districts.map(d => d.districtName)}
            value={value.district || null}
            onChange={(event, newValue) => {
                uploadResult.content[row.index].address.district = newValue;
                setUploadResult({ ...uploadResult });
            }}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) =>
                <TextField
                    {...params}
                    size="small"
                    placeholder="İlçe"
                    error={!value.district || !districts.some(d => d.districtName === value.district)}
                />}
        />
    )
})


export const NeighborhoodInput = React.memo(({ value, row, provinces, uploadResult, setUploadResult }) => {
    const [neighborhoods, setNeighborhoods] = useState([]);
    useEffect(() => {
        (async () => {
            let selectedProvince = provinces.find(p => p.provinceName === value.province);
            let districts = await getDistrictsByProvinceIdRequest(selectedProvince.id);
            if (districts) {
                let selectedDistrict = districts.data.find(d => d.districtName === value.district);
                let res = await getNeighborhoodsByDistrictIdRequest(selectedDistrict.id);
                if (res) {
                    setNeighborhoods(res.data)
                }
            }
        })()
    }, [value.district])

    return (
        <Autocomplete
            fullWidth
            options={neighborhoods.map(n => n.neighborhoodName)}
            value={value.neighborhood || null}
            onChange={(event, newValue) => {
                uploadResult.content[row.index].address.neighborhood = newValue;
                setUploadResult({ ...uploadResult });
            }}
            isOptionEqualToValue={(option, value) => option === value}
            renderInput={(params) =>
                <TextField
                    {...params}
                    size="small"
                    placeholder="Mahalle"
                    error={!value.neighborhood || !neighborhoods.some(n => n.neighborhoodName === value.neighborhood)}
                />}
        />
    )
})