import { useEffect } from 'react'
import { Grid, IconButton, List, ListItem, ListItemText, ListSubheader, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import CustomDialog from '../../../components/atoms/CustomDialog';
import { DeleteRounded } from '@mui/icons-material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { getTravelersByIdListRequest, } from '../../../api/controllers/traveler-controller';
import moment from 'moment';
import { saveOverTimeRequest } from '../../../api/controllers/overtime-controller';
import { saveStudyRequest } from '../../../api/controllers/study-controller';
import { toast } from 'react-toastify';

function CreateModal({ open, setOpen, setSelectedRows, selectedRows, type}) {
    
    const messageList = {
        "ETUT": {
          success: "Etüt oluşturma isteği gönderildi.",
          create: "Etüt Oluştur",
          day:"Etüt uygulanacağı günleri seçin",
          time:"Etüt zamanını seçin",
          person:"Etüt için seçilen kişiler"
        },
        "MESAI": {
          success: "Mesai oluşturma isteği gönderildi.",
          create: "Mesai Oluştur",
          day:"Mesainin uygulanacağı günleri seçin",
          time:"Mesai zamanını seçin",
          person:"Mesai için seçilen kişiler"
        }
      };

    const messages = messageList[type];
      
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [travelerList, setTravelerList] = useState([])
    const [form, setForm] = useState({
        endDate: new Date(),
        startDate: new Date(),
        workTime: {
            endTime: "00:00",
            startTime: "00:00"
        },
        travelerIds: []
    })
    const filteredList = travelerList?.length > 0 && travelerList.filter(v => v.firstName.toLocaleUpperCase().includes(searchText.toLocaleUpperCase()) || v.lastName.toLocaleUpperCase().includes(searchText.toLocaleUpperCase()));

    const createHandler = async () => {
        setIsSubmitting(true)
        try {
            let res;
            if(type === "MESAI"){
                res = await saveOverTimeRequest({
                    ...form,
                    startDate: moment(form.startDate).format("yyyy-MM-DD"),
                    endDate: moment(form.endDate).format("yyyy-MM-DD"),
                    travelerIds: travelerList.map(v => v.id)
                })
            }else if(type === "ETUT"){
                res = await saveStudyRequest({
                    ...form,
                    startDate: moment(form.startDate).format("yyyy-MM-DD"),
                    endDate: moment(form.endDate).format("yyyy-MM-DD"),
                    travelerIds: travelerList.map(v => v.id)
                })
            }
            if (res) {
                setOpen(false)
                setSelectedRows([])
                toast.success(messages.success)
            }
        } catch (error) { }
        setIsSubmitting(false)
    }

    const getTravelers = async () => {
        let res = await getTravelersByIdListRequest(selectedRows)
        if (res) {
            setTravelerList(res.data.filter((v) => selectedRows.some(val => val === v.id)))
        }
    }

    useEffect(() => {
        getTravelers()
    }, [])

    useEffect(() => {
        if (travelerList?.length > 0) {
            setTravelerList(travelerList.filter((v) => selectedRows.some(val => val === v.id)))
        }
    }, [selectedRows])

    return (
        <CustomDialog
            title={messages.create}
            size="xs"
            open={open}
            setOpen={setOpen}
            buttons={[
                {
                    type: "button",
                    onClick: createHandler,
                    variant: "contained",
                    loading: isSubmitting,
                    text: "Kaydet",
                }
            ]}
        >
            <Grid container spacing={4}>
                <Grid item container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='subtitle2'>| {messages.day}</Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <DatePicker
                            renderInput={(props) => <TextField {...props} fullWidth />}
                            label="Başlangıç Tarihi"
                            value={form.startDate}
                            onChange={(newValue) => setForm((prev) => ({ ...prev, startDate: newValue }))}
                            minDate={new Date()}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <DatePicker
                            renderInput={(props) => <TextField {...props} fullWidth />}
                            label="Bitiş Tarihi"
                            value={form.endDate}
                            onChange={(newValue) => setForm((prev) => ({ ...prev, endDate: newValue }))}
                            minDate={new Date()}
                        />
                    </Grid>
                </Grid>
                <Grid item container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant='subtitle2'>| {messages.time} </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <TimePicker
                            label="Başlangıç Zamanı"
                            value={new Date(0, 0, 0, form.workTime.startTime.slice(0, 2), form.workTime.startTime.slice(3, 5))}
                            onChange={(newValue) => setForm((prev) => ({ ...prev, workTime: { ...prev.workTime, startTime: moment(newValue).format("HH:mm:ss") } }))}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TimePicker
                            label="Bitiş Zamanı"
                            value={new Date(0, 0, 0, form.workTime.endTime.slice(0, 2), form.workTime.endTime.slice(3, 5))}
                            onChange={(newValue) => setForm((prev) => ({ ...prev, workTime: { ...prev.workTime, endTime: moment(newValue).format("HH:mm:ss") } }))}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <List
                        sx={{
                            width: '100%',
                            height: 225,
                            overflow: "auto",
                        }}
                        subheader={
                            <ListSubheader sx={{ p: 0, lineHeight: "1.5rem" }} component="div">
                                <Typography variant='subtitle2' mb={1}>| {messages.person} ({travelerList.length} kişi)</Typography>
                                <TextField
                                    value={searchText}
                                    onChange={e => setSearchText(e.target.value)}
                                    variant="standard"
                                    placeholder="Liste içinde arayın"
                                    size="small"
                                    fullWidth
                                />
                            </ListSubheader>
                        }
                    >
                        {
                            filteredList?.length > 0 ?
                                filteredList.map((value, i) => {
                                    return (
                                        <ListItem
                                            key={i}
                                            sx={{
                                                p: ".25rem 0",
                                                "&:hover": {
                                                    backgroundColor: "var(--bg)"
                                                }
                                            }}
                                        >
                                            <ListItemText primary={`${i + 1} - ${value.firstName || ""} ${value.lastName || ""}`} />
                                            <IconButton size='small' onClick={() => setSelectedRows(prev => {
                                                prev = prev.filter(v => v !== value.id);
                                                return prev
                                            })}>
                                                <DeleteRounded fontSize='small' />
                                            </IconButton>
                                        </ListItem>
                                    );
                                }) :
                                <ListItem disablePadding>
                                    <ListItemText primary="Sonuç bulunamadı" />
                                </ListItem>
                        }
                    </List>
                </Grid>
            </Grid>

        </CustomDialog>
    )
}

export default CreateModal;