import { RouteRounded, Close } from '@mui/icons-material';
import { Popup } from 'react-leaflet'
import styles from './style.module.scss';
import moment from 'moment';
import { IconButton } from '@mui/material';

const MapVehiclePopup = ({ log, resetHistory }) => {
    return (
        <Popup
            closeButton={false}
            closeOnClick={false}
        >
            <div className={styles.map_popup} >
                <IconButton
                    className={styles.close_button}
                    onClick={() => resetHistory()}
                >
                    <Close />
                </IconButton>
                <p className={styles.title}>{`${log.vehicle?.brand || "Bilinmiyor"} ${log.vehicle?.model || ""} - ${log.vehicle?.year || ""}`}</p>
                <div className={styles.info_box}>
                    <span>
                        <i className="bi bi-speedometer"></i>
                        <span>{log.speedKmh && log.speedKmh.toFixed(0)} km/h</span>
                    </span>
                    <span>
                        <RouteRounded />
                        <span>
                            {log.vehicle?.dailyDistance && new Intl.NumberFormat('tr-TR').format(log.vehicle?.dailyDistance)} km
                        </span>
                    </span>
                </div>
                <p style={{ marginBottom: ".25rem" }}>
                    <i className="bi bi-clock" style={{ marginRight: ".25rem" }}></i>
                    {moment(log.mqttLogDateTime).format("LLL")}
                </p>
                <p>
                    <i className="bi bi-geo-alt-fill" style={{ marginRight: ".25rem" }}></i>
                    {log.address?.fullAddress}
                </p>
            </div>
        </Popup >
    )
}

export default MapVehiclePopup;