import { createContext, useContext, useState } from 'react';

export const Context = createContext();

export default function Provider({ children }) {
    const [customer, setCustomer] = useState(null);
    const [actionButtons, setActionButtons] = useState([]);
    const data = {
        customer,
        setCustomer,
        actionButtons,
        setActionButtons
    }
    return (
        <Context.Provider value={data}>
            {children}
        </Context.Provider>
    )
}

export const useCustomer = () => useContext(Context)
