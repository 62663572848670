import { Box, Typography } from '@mui/material';
import React from 'react';
import styles from './style.module.scss';

function Label({ icon, title, value }) {
    return (
        <Box className={styles.container}>
            <Typography className={styles.title}>
                {
                    icon
                }
                {title}
            </Typography>
            <Typography className={styles.value}>
                {value || "-"}
            </Typography>
        </Box>
    )
}

export default Label