import { Checkbox, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { useParams } from 'react-router';
import { deleteStationEmployeeRequest } from '../../api/controllers/station-employee-controller';
import { saveStationStudentRequest } from '../../api/controllers/station-student-controller';
import { usePlans } from '../../context/PlansContext';

function StudentTravelerMarkerItemMultiple({ traveler }) {
    const { plan, updateForm, setUpdateForm, getTravelers } = usePlans();
    const { workTimeId, planType } = useParams();

    const editable = window.location.pathname.includes("duzenle") && traveler?.address?.position
    const handleClick = async (traveler) => {
        if (editable) {
            const station = await updateForm.plan.stationList.find(v => v.travelers[0].id === traveler.id);
            if (station) {
                let res = await deleteStationEmployeeRequest(station.id);
                if (res) {
                    setUpdateForm(prev => ({ ...prev, plan: { ...prev.plan, stationList: prev.plan.stationList.filter(v => v.id !== station.id) } }));
                    getTravelers(workTimeId);
                }
            } else {
                let res = await saveStationStudentRequest(updateForm.plan.id, {
                    sequence: null,
                    title: `${traveler.firstName || ""} ${traveler.lastName || ""}`,
                    travelerIds: [traveler.id],
                    lat: traveler?.address?.position?.latitude,
                    lng: traveler?.address?.position?.longitude,
                });
                if (res) {
                    setUpdateForm(prev => ({ ...prev, plan: { ...prev.plan, stationList: [...prev.plan.stationList, res.data] } }));
                    getTravelers(workTimeId);
                }
            }
        }
    }

    return (
        <ListItem
            secondaryAction={
                <Checkbox
                    size="small"
                    edge="end"
                    checked={!editable ? !traveler[planType]?.enable : updateForm?.plan?.stationList?.some(v => v.travelers[0].id === traveler.id)}
                    onClick={() => editable ? handleClick(traveler) : {}}
                />
            }
            disablePadding
        >
            <ListItemButton
                onClick={() => editable ? handleClick(traveler) : {}}
                disabled={plan?.stationList?.some(v => v.travelers[0].id === traveler.id) || updateForm?.plan?.stationList?.some(v => v.travelers[0].id === traveler.id) ? false : !traveler[planType]?.enable}
            >
                <ListItemText primary={traveler?.firstName + " " + traveler?.lastName} />
            </ListItemButton>
        </ListItem >
    )
}

export default StudentTravelerMarkerItemMultiple;