import { useEffect, useState } from 'react';
import PageLayout from '../../../components/templates/PageLayout';
import CustomDataGrid from '../../../components/atoms/CustomDataGrid';
import { getAllOverTimesRequest } from '../../../api/controllers/overtime-controller';
import { getAllStudiesRequest } from '../../../api/controllers/study-controller';
import { Avatar, Tooltip } from '@mui/material';
import { CheckRounded, CloseRounded, InfoRounded } from '@mui/icons-material';
import moment from 'moment';
import { useLocation, useSearchParams } from 'react-router-dom';
import PopoverMenu from '../../../components/atoms/PopoverMenu';
import { useAuth } from '../../../context/AuthContext';
import OverTimeDetailModal from './MyRequestDetail';

function Page() {
    //States
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [data, setData] = useState([]);
    const location = useLocation();
    const lastSegment = location.pathname.split('/').pop();
    let type;
    if (lastSegment === "mesailer") {
        type = "MESAI"
    } else if (lastSegment === "etutler") {
        type = "ETUT"
    }
    const [searchParams, setSearchParams] = useSearchParams();
    const queryId = searchParams.get("id");
    const { currentUser } = useAuth()
    const role = currentUser.role

    //Tablo sütunları
    const columns = [
        {
            field: 'customerRequestStatus',
            headerName: "Durum",
            sortable: false,
            renderCell: ({ value }) => (
                <Tooltip title={value === 'PENDING' ? "Bekliyor" : value === 'DONE' ? "Tamamlandı" : "-"}>
                    <Avatar sx={{ width: 24, height: 24, fontSize: 14, bgcolor: value === 'DONE' ? "var(--success_12)" : value === 'PENDING' ? "var(--danger_12)" : "var(--danger_12)" }}                >
                        {value === 'DONE' ? <CheckRounded htmlColor="var(--success)" fontSize="inherit" /> : value === 'PENDING' ? < CloseRounded htmlColor="var(--danger)" fontSize="inherit" /> : "-"}
                    </Avatar>
                </Tooltip>
            ),
        },
        {
            field: 'customer',
            headerName: "Firma Ünvanı",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? value.title : "-",
        },
        {
            field: 'startDate',
            headerName: "Başlangıç tarihi",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? moment(value).format('LL') : "-",
        },
        {
            field: 'endDate',
            headerName: "Bitiş tarihi",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? moment(value).format('LL') : "-",
        },
        {
            field: 'travelers',
            headerName: "Kişi Sayısı",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? value.length : "-",
        },
        {
            field: 'id',
            headerName: "",
            sortable: false,
            align: 'right',
            renderCell: ({ value }) => <PopoverMenu
                items={[
                    {
                        icon: <InfoRounded fontSize="inherit" />,
                        text: "Detaylar",
                        onClick: () => setSearchParams({ id: value }),
                    },
                ]}
            />,
        },
    ]

    const getAllData = async () => {
        setIsTableLoading(true);
        try {

            let res;

            if (type === "MESAI") {
                res = await getAllOverTimesRequest();
            }
            else if (type === "ETUT") {
                res = await getAllStudiesRequest();
            }

            if (res) {
                setData(res.data)
            }
        } catch (error) { }
        setIsTableLoading(false);
    }

    useEffect(() => {
        getAllData()
    }, [])

    return (
        <PageLayout
            title={type === "MESAI" ? "Mesailer" : "Etütler"}

        >
            <CustomDataGrid
                data={data}
                columns={columns}
                loading={isTableLoading}
                rowId={(params) => params?.id}
            />
            {
                queryId && data.length > 0 &&
                <OverTimeDetailModal
                    queryId={queryId}
                    data={data}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    role={role}
                />
            }
        </PageLayout>
    )
}

export default Page;