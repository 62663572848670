import { ReactNode } from 'react';
import { Grid, Box, Grow, MenuItem, } from '@mui/material';
import styles from './PageLayout.module.scss';
import { Helmet } from 'react-helmet';
import CustomMenu from '../../molecules/CustomMenu';
import { useSearchParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

type Buttons = {
    onClick: () => void
    variant: "contained"
    startIcon: ReactNode
    text: string
}

type MenuItem = {
    onClick: () => void,
    icon: ReactNode,
    text: string,
}

type IProps = { children: ReactNode, small?: boolean, title: string, buttons: Buttons[], detailPage?: boolean, sidebar?: any, menuItems?: MenuItem[] }

const PageLayout = ({ children, small, title, buttons, detailPage, sidebar, menuItems }: IProps) => {

    const [searchParams] = useSearchParams();
    const isExcelPage = searchParams.get("type") === "multiple";

    return (
        <>
            <Helmet>
                <title>{`${title} | Turmetre Optimizasyon Sistemi`}</title>
            </Helmet>
            <Grow in={true} unmountOnExit >
                <Box className={small ? styles.container_small : styles.container}>
                    <Box className={buttons?.length > 0 || menuItems && menuItems.length > 0 ? styles.head_flex : styles.head} >
                        <h2>{title}</h2>
                        <Box className={styles.buttons}>
                            {
                                buttons?.length > 0 &&
                                buttons.map((v, index) => <LoadingButton key={index} {...v}>{v.text}</LoadingButton>)
                            }
                            {
                                menuItems &&
                                menuItems.length > 0 &&
                                <CustomMenu page>
                                    {
                                        menuItems.map((item, i) =>
                                            <MenuItem key={i} onClick={item?.onClick} disableRipple>
                                                {
                                                    item?.icon &&
                                                    <span>{item?.icon}</span>
                                                }
                                                {item?.text}
                                            </MenuItem>
                                        )
                                    }
                                </CustomMenu>
                            }
                        </Box>
                    </Box>
                    {
                        detailPage ?
                            <Grid container spacing={3}>
                                {
                                    !isExcelPage &&
                                    <Grid item xs={12} md={3}>
                                        {sidebar}
                                    </Grid>
                                }
                                <Grid item xs={12} md={isExcelPage ? 12 : 9}>
                                    <Box className={styles.content}>
                                        {children}
                                    </Box>
                                </Grid>
                            </Grid> :
                            <Box className={styles.content}>
                                {children}
                            </Box>
                    }
                </Box>
            </Grow>
            <footer style={{ textAlign: "center" }}>Powered by <a target="_blank" style={{ color: "inherit" }} href="https://aselsis.com/">Aselsis</a></footer>
        </>
    )
};

export default PageLayout;