import { useState } from 'react'
import { Box, Button, IconButton } from '@mui/material';
import { CloseRounded, SearchRounded } from '@mui/icons-material';
import styles from './style.module.scss';

function Search({ onChange }) {
    const [isFocus, setIsFocus] = useState(false);
    const [text, setText] = useState("");

    const handleChange = e => {
        setText(e.target.value);
        const timer = setTimeout(() => {
            onChange(e.target.value)
        }, e.target.value ? 750 : 0)
        return () => clearTimeout(timer)
    }

    return (
        <Box className={styles.container} onBlur={() => !text && setIsFocus(false)} >
            {
                isFocus
                    ?
                    <Box className={styles.form_container}>
                        <IconButton onClick={() => setIsFocus(false)} size="small">
                            <CloseRounded fontSize="inherit" />
                        </IconButton>
                        <input
                            type="search"
                            placeholder="Aramak için bir şeyler yazın"
                            autoFocus
                            autoComplete="off"
                            value={text}
                            onChange={handleChange}
                        />
                    </Box>
                    :
                    <Button
                        className={styles.button}
                        color="secondary"
                        startIcon={<SearchRounded />}
                        onClick={() => setIsFocus(true)}
                    >
                        Ara
                    </Button>
            }
        </Box>
    )
}

export default Search;