import axios from "axios";

export const getAllOverTimesRequest = () => axios.get(`/overtime/my`)

export const getOverTimeRequest = (id) => axios.get(`/overtime/${id}`)

export const getTravelerByAddressNeighborhoodRequest = (id, neighborhood) => axios.get(`/overtime/${id}/traveler?neighborhood=${neighborhood}`)

export const saveOverTimeRequest = (form) => axios.post(`/overtime`, form)

export const createOverTimeRequest = (form, mesaiId, isForced) => axios.post(`/overtime/${mesaiId}/addPlan?isForced=${isForced}`, form)

export const createVoyageOverTimeRequest = (form, mesaiId, isForced) => axios.post(`/overtime/${mesaiId}/addPlanVoyage?isForced=${isForced}`, form)

export const createStationRequest = (form, mesaiId, planId) => axios.post(`/overtime/${mesaiId}/plan/${planId}/station`, form)

export const updateOverTimeRequest = (form, mesaiId) => axios.put(`/overtime/${mesaiId}`, form)

export const deleteStationRequest = (overTimeId, planId, stationId) => axios.delete(`/overtime/${overTimeId}/plan/${planId}/station/${stationId}`)

export const updateStationRequest = (overTimeId, planId, stationId, form) => axios.put(`/overtime/${overTimeId}/plan/${planId}/station/${stationId}`, form)

export const updateOverTimePlanRequest = (overTimeId, planId, form, isForced) => axios.put(`/overtime/${overTimeId}/plan/${planId}?isForced=${isForced}`, form)
