import React from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import styles from "./style.module.scss";

type Props = {
  icon?: React.ReactNode;
  text?: string;
  tooltip?: string | React.ReactNode;
};

function DetailTag({ icon, text, tooltip }: Props) {
  return (
    <Tooltip title={tooltip || ""}>
      <Box component="span" className={styles.container}>
        {icon || null}
        <Typography className={styles.text}>{text || 0}</Typography>
      </Box>
    </Tooltip>
  );
}

export default DetailTag;
