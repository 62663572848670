import axios from "axios";

export const deleteVehicleRequest = (id) => axios.delete(`/vehicle/${id}`)

export const getVehiclesPageableRequest = (pageable, filter) => {
    let search_query = `licensePlate=like='${filter.search}',imei=like='${filter.search}'`;
    let query = `${search_query}`;
    return axios.get(`/vehicle/pageable?page=${pageable.page}&size=${pageable.size}&query=${query}`)
}

export const getVehiclesRequest = () => axios.get(`/vehicle/sort?sort=licensePlate,asc`)

export const getAvailableVehiclesRequest = (workTimeId, planType) => axios.get(`/vehicle/getAvailable?workTimeId=${workTimeId}&planDirectionType=${planType.toUpperCase()}`)

export const getAvailableVehiclesForOverTimeRequest = (workTime) => axios.get(`/vehicle/workTime/getAvailable?startDate=${workTime.startDate}&endDate=${workTime.endDate}&time=${workTime.time}`)

export const getVehicleRequest = (id) => axios.get(`/vehicle/${id}`)

export const saveVehicleRequest = (form) => axios.post(`/vehicle/${form.driver ? `driver/${form.driver.id}` : ""}`, form)

export const updateVehicleRequest = (form) => {
    let data = { ...form, driverId: form?.driver?.id || null, driver: null }
    console.log('data', data)
    return axios.put(`/vehicle/${form.id}`, data)
}