import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Divider, List, ListSubheader, ListItem, ListItemButton, ListItemAvatar, ListItemText, Menu, MenuItem, Popover, Typography, Alert, Badge } from '@mui/material';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import styles from './header.module.scss';
import { KeyboardArrowDown, KeyboardArrowUp, NotificationsRounded } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { useAuth } from '../../../context/AuthContext';
import { getMyNotificationsCountRequest, getMyNotificationsPageableRequest } from '../../../api/controllers/notification-controller';
import { onMessageListener, requestPermission } from '../../../messaging_init_in_sw';
import { default as Logo } from '../../../assets/logo.svg'

const Header = () => {
    const { currentUser } = useAuth();
    const { firstName, role } = currentUser;

    const navigate = useNavigate();
    const [mobileMenu, setMobileMenu] = React.useState(false);

    const CustomLink = ({ children, to }) => (
        <NavLink to={to} className={({ isActive }) =>
            isActive ? styles.active : ""
        }
        >
            <Button onClick={() => setMobileMenu(false)}>{children}</Button>
        </NavLink >
    )

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = async () => {
        await axios.get("/account/logout");
        localStorage.removeItem("token");
        navigate("/");
    }

    return (
        <header className={styles.header}>
            <Link to={currentUser.role === "ADMIN" ? "/app/servisler" : "/app/guzergahlar"} >
                <img src={Logo} alt="" />
            </Link>
            <nav className={mobileMenu ? styles.opened : ""}>
                {
                    role === "ADMIN" &&
                    <CustomLink to="/app/servisler">
                        Servisler
                    </CustomLink>
                }
                <CustomLink to="/app/guzergahlar">
                    Güzergahlar
                </CustomLink>
                {
                    (role === "ADMIN" || role === "MANAGER") &&
                    <CustomLink to="/app/musteriler">
                        Müşteriler
                    </CustomLink>
                }
                {
                    role === "ADMIN" &&
                    <CustomLink to="/app/yoneticiler">
                        Yöneticiler
                    </CustomLink>
                }
                <CustomLink to="/app/yolcular">
                    Yolcular
                </CustomLink>
                {
                    role === "ADMIN" &&
                    <CustomLink to="/app/suruculer">
                        Sürücüler
                    </CustomLink>
                }
                {
                    role === "ADMIN" &&
                    <CustomLink to="/app/mesailer">
                        Mesailer
                    </CustomLink>
                }
                {
                    role === "ADMIN" &&
                    <CustomLink to="/app/etutler">
                        Etütler
                    </CustomLink>
                }
                {/* 
                    role === "ADMIN" &&
                    <CustomLink to="/app/geziler">
                        Geziler
                    </CustomLink>
                 */}
            </nav>
            <Box className={styles.action_buttons}>
                <Notification />
                <Button
                    className={styles.menu}
                    variant="contained"
                    onClick={handleClick}
                    startIcon={
                        <div className={styles.avatar}>
                            <i className="bi bi-person"></i>
                        </div>
                    }
                    endIcon={open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                >
                    {firstName || "Kullanıcı"}
                </Button>
                <StyledMenu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                >
                    {
                        (role === "ADMIN" || role === "CUSTOMER") &&
                        <MenuItem onClick={() => {
                            navigate(role === "ADMIN" ? "/app/sirket-yonetimi" : "/app/ticari-bilgiler");
                            handleClose();
                        }} disableRipple>
                            <i style={{ marginRight: ".5rem" }} className="bi bi-briefcase-fill"></i>
                            {
                                role === "ADMIN" ? "Şirket Yönetimi" : "Turmetre Ticari Bilgiler"
                            }
                        </MenuItem>
                    }
                    {
                        role === "ADMIN" &&
                        <MenuItem onClick={() => {
                            navigate("/app/ayarlar");
                            handleClose();
                        }} disableRipple>
                            <i style={{ marginRight: ".5rem" }} className="bi bi-gear-fill"></i>
                            Sistem Ayarları
                        </MenuItem>
                    }
                    <Divider sx={{ my: 0.5 }} />
                    <MenuItem onClick={() => handleLogout()} disableRipple>
                        <i style={{ marginRight: ".5rem" }} className="bi bi-box-arrow-right"></i>
                        Çıkış Yap
                    </MenuItem>
                </StyledMenu>
                <Button onClick={() => setMobileMenu(m => !m)} color="secondary" className={styles.menu_button}>
                    <i className="bi bi-list" />
                </Button>
            </Box>
        </header >
    )
}

export default Header;

const StyledMenu = styled((props) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        {...props}
    />
))(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        marginTop: theme.spacing(1),
        minWidth: 180,
        boxShadow:
            'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
        '& .MuiMenu-list': {
        },
    },
}));

const Notification = () => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [pageable, setPageable] = useState({
        page: 0,
        size: 10,
    });
    const [data, setData] = useState([]);
    const [unreadCount, setUnreadCount] = useState(0);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        if (unreadCount > 0) {
            const list = data.map(v => v.id);
            axios.put("/notification/updateIsOpened", list).then(() => getNotifications());
        }
    };

    const getNotifications = async () => {
        const res = await getMyNotificationsPageableRequest(pageable);
        if (res) {
            setData(res.data.content);
            const count = await getMyNotificationsCountRequest();
            setUnreadCount(count.data)
        }
    }

    onMessageListener().then(() => getNotifications());

    useEffect(() => {
        getNotifications();
    }, [pageable.size])

    useEffect(() => {
        requestPermission();
    }, [])

    const open = Boolean(anchorEl);
    return (
        <>
            <Badge badgeContent={unreadCount > 9 ? "+9" : unreadCount} color="primary">
                <Button variant="outlined" color="secondary" onClick={handleClick}>
                    <NotificationsRounded />
                </Button>
            </Badge>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <List sx={{
                    width: 360,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                    height: 300
                }}
                    subheader={<ListSubheader>Bildirimler</ListSubheader>}
                >
                    {data.length > 0 ?
                        data.map((v, i) =>
                            <React.Fragment key={i}>
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar sx={!v.isOpened ? { bgcolor: "var(--primary_12)", color: "var(--primary)" } : {}}>
                                            <NotificationsRounded />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={v.title}
                                        secondary={
                                            <Typography
                                                sx={{ display: 'inline' }}
                                                component="span"
                                                variant="body2"
                                                color="text.primary"
                                            >
                                                {v.body}
                                            </Typography>
                                        }
                                    />
                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </React.Fragment>
                        ) :
                        <Box sx={{ px: "1rem" }}>
                            <Alert severity='warning' >
                                Bildiriminiz bulunmamaktadır
                            </Alert>
                        </Box>
                    }
                    {
                        data.length === pageable.size &&
                        <ListItem alignItems="flex-start">
                            <ListItemButton
                                onClick={() => setPageable(prev => ({ ...prev, size: prev.size + 10 }))}
                                sx={{ display: "flex", justifyContent: "center" }}
                            >
                                Daha fazla göster
                            </ListItemButton>
                        </ListItem>
                    }
                </List>
            </Popover>
        </>
    )
}