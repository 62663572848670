import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { getFootRouteRequest } from '../../../api/controllers/route-controller';
import styles from './style.module.scss';
import { useAuth } from '../../../context/AuthContext';

function TravelerCard({ traveler, stationPosition }) {
    const { settings, getData } = useAuth()

    const [distance, setDistance] = useState("")

    useEffect(() => {
        if (stationPosition.lat && stationPosition.lng && traveler.address) {
            (async () => {
                let res = await getFootRouteRequest({ lat: stationPosition.lat, lng: stationPosition.lng }, { lat: traveler.address.position.latitude, lng: traveler.address.position.longitude });
                if (res) {
                    setDistance(res.data);
                }
            })()
        }
    }, [stationPosition.lat, stationPosition.lng])

    useEffect(() => {
        getData()
    }, [])

    return (
        <button className={styles.container}>
            <Typography className={styles.fullName}>
                {traveler?.firstName || traveler?.lastName ? `${traveler.firstName} ${traveler.lastName}` : "Bilinmiyor"}
            </Typography>
            {
                distance &&
                <Typography className={`${styles.distance} ${distance > settings.maxWalkingDistance && styles.error}`}>
                    {distance + " m"}
                </Typography>
            }
        </button>
    )
}

export default TravelerCard;