import { ArrowBackRounded } from '@mui/icons-material';
import { Alert, Box, Divider, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import { toast } from 'react-toastify';
import { getGeocodeRequest } from '../../../../api/controllers/addresses-controller';
import { getTravelerByAddressNeighborhoodRequest } from '../../../../api/controllers/study-controller';
import { useOverTime } from '../../../../context/OverTimeContext';
import TravelerCard from '../../../molecules/TravelerCard';
import PlansSidebarLayout from '../../../templates/PlansSidebarLayout';
import styles from './style.module.scss';
import FilterAreaForOverTimeStation from '../../../molecules/FilterAreaForOverTimeStation';
import { createStationRequest } from '../../../../api/controllers/overtime-controller';

function CreateStationForNeighborhood() {
    const navigate = useNavigate();
    const { planUuid, planType, mesaiId } = useParams();
    const { plan, setUpdateForm, overTime, stationForm, setStationForm, resetStationForm, getOverTime, setPlan, setTravelers, setAreaPolygon } = useOverTime();
    const toast_1 = React.useRef(null);
    const toast_2 = React.useRef(null);

    const index = overTime?.plans?.findIndex((v) => v.uuid === planUuid && v.planDirectionType === planType.toUpperCase())

    const CreateStationForNeighborhood = async () => {
        const form = { ...stationForm, travelersId: stationForm.travelersId.map(v => v.id) }
        try {
            let res = await createStationRequest(form, mesaiId, plan.id)
            if (res) {
                console.log('1')
                const overtime = await getOverTime(mesaiId)
                setUpdateForm((prev) => ({ ...prev, step: 2, plan: { ...prev.plan, stations: overtime.plans[index].stations } }))
                setPlan(overtime.plans[index])
                setTravelers(overtime.travelers)
                navigate(window.location.pathname.split("/station/mahalle-olustur")[0])
                setAreaPolygon([])
            }
        } catch (error) { }
    }

    useEffect(() => {
        const notify_1 = () => toast_1.current = toast.info("Durağa yolcu ekleyip çıkarmak için yolcunun ikonuna tıklayın.", { autoClose: false });
        const update_1 = () => toast.update(toast_1.current, { autoClose: 10 });
        const notify_2 = () => toast_2.current = toast.info("Durağın konumunu değiştirmek için durak ikonuna tıklayarak sürükleyin.", { autoClose: false });
        const update_2 = () => toast.update(toast_2.current, { autoClose: 10 });
        notify_1();
        notify_2();
        return () => {
            update_1();
            update_2();
            resetStationForm();
        }
    }, [])

    const findCenterOfAddress = async () => {
        try {
            let res = await getGeocodeRequest(stationForm)
            if (res) {
                setStationForm(prev => ({ ...prev, position: res.data, title: stationForm?.neighborhood }));
            }
        } catch (error) {
        }
    }

    const getTravelersFromNeighborhood = async () => {
        try {
            let res = await getTravelerByAddressNeighborhoodRequest(mesaiId, stationForm?.neighborhood)
            if (res) {
                setStationForm({ ...stationForm, travelersId: res.data.filter((v) => v[`${planType}Enable`] === true) })
            }
        } catch (error) { }
    }

    useEffect(() => {
        if (stationForm?.neighborhood !== "") {
            findCenterOfAddress()
            getTravelersFromNeighborhood()
        }
        return () => {

        }
    }, [stationForm?.neighborhood])

    return (
        <PlansSidebarLayout
            header={{
                iconButton: {
                    icon: <ArrowBackRounded fontSize="inherit" />,
                    props: {
                        onClick: () => {
                            navigate(window.location.pathname.split("/station/mahalle-olustur")[0]);
                        },
                    }
                },
                title: {
                    text: "Durak Oluştur"
                }
            }}
            footer={{
                buttons: [
                    {
                        text: "İptal et",
                        props: {
                            variant: "outlined",
                            color: "secondary",
                            onClick: () => {
                                navigate(window.location.pathname.split("/station/mahalle-olustur")[0]);
                            }
                        }
                    },
                    {
                        text: "Oluştur",
                        props: {
                            variant: "contained",
                            disabled: !(stationForm.title && stationForm.position.latitude && stationForm.position.longitude),
                            onClick: () => CreateStationForNeighborhood(),
                        }
                    },
                ]
            }}
        >
            <Box className={styles.container}>

                <FilterAreaForOverTimeStation />

                {/* <LoadingButton loading={isSubmitting} onClick={getOptimizedStations} size="small" variant="contained">
                    Kişileri Durağa ata
                </LoadingButton> */}
                <Divider flexItem />
                <Box className={styles.element}>
                    <Typography variant="h6">
                        | Durağa bağlı yolcular
                    </Typography>
                    {
                        stationForm.travelersId.length > 0 ?
                            stationForm.travelersId.map(v =>
                                <TravelerCard
                                    key={v.id}
                                    traveler={v}
                                    stationPosition={{ lat: stationForm.lat, lng: stationForm.lng }}
                                />
                            )
                            :
                            <Alert severity="warning">
                                Durağa bağlı yolcu bulunamadı!
                            </Alert>
                    }
                </Box>
            </Box>
        </PlansSidebarLayout>
    )
}

export default React.memo(CreateStationForNeighborhood)