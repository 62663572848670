import  { useEffect, useState } from 'react';
import { Grid, TextField, Autocomplete, Box } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { getWorkTimesByCustomerIdRequest } from '../../../api/controllers/work-time-controller';
import AddressModal from '../../../components/molecules/AddressModal';
import CustomDialog from '../../../components/atoms/CustomDialog';
import { PatternFormat } from 'react-number-format';
import { getParentsRequest } from '../../../api/controllers/parent-controller';
import styles from './yolcular.module.scss';
import CreateParent from '../../../components/organisms/CustomerDetail/Travelers/CreateTraveler/CreateStudent/CreateParent';
import { saveStudentRequest } from '../../../api/controllers/student-controller';

function OgrenciEkle({ customer, open, setOpen, getTravelers, }) {
    const navigate = useNavigate();
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [parents, setParents] = useState([]);
    const [createParentModal, setCreateParentModal] = useState(false);

    const getParents = async (filter) => {
        let res = await getParentsRequest(filter || "");
        if (res) {
            setParents(res.data);
        }
    }

    useEffect(() => {
        getParents();
    }, [])


    const [workTimes, setWorkTimes] = useState([]);

    const formik = useFormik({
        validationSchema: yup.object({
            firstName: yup
                .string()
                .required('Bu alan gereklidir'),
            lastName: yup
                .string()
                .required('Bu alan gereklidir'),
            idNum: yup
                .string()
                .required('Bu alan gereklidir')
                .length(11, 'Kimlik numarası 11 haneli olmalıdır'),
            parent: yup
                .object()
                .required('Bu alan gereklidir')
                .nullable(),
            workTime: yup
                .object()
                .required('Bu alan gereklidir')
                .nullable(),
            address: yup.object({
                province: yup
                    .string()
                    .required('Bu alan gereklidir')
                    .nullable(),
                district: yup
                    .string()
                    .required('Bu alan gereklidir')
                    .nullable(),
                neighborhood: yup
                    .string()
                    .required('Bu alan gereklidir')
                    .nullable(),
                street: yup
                    .string()
                    .required('Bu alan gereklidir'),
                buildingInformation: yup
                    .string()
                    .required('Bu alan gereklidir'),
            }),
        }),
        initialValues: {
            address: {
                buildingInformation: "",
                country: "",
                district: null,
                fullAddress: "",
                neighborhood: null,
                position: {
                    latitude: null,
                    longitude: null
                },
                province: "Denizli",
                street: ""
            },
            classRoom: null,
            firstName: "",
            idNum: "",
            lastName: "",
            workTime: null,
            parent: null,
        },
        onSubmit: () => onSubmit()
    });

    const onSubmit = async () => {
        setIsSubmitting(true);
        try {
            let res = await saveStudentRequest(formik.values);
            if (res) {
                toast.success("Öğrenci kaydedildi");
                formik.resetForm();
                setOpen(false)
                getTravelers()
            }
        } catch (error) { }
        setIsSubmitting(false);
    }

    useEffect(() => {
        (async () => {
            let res = await getWorkTimesByCustomerIdRequest(customer.id);
            if (res) {
                setWorkTimes(res.data);
            }
        })()
    }, [])



    return (
        <>
            <CustomDialog
                title="Yolcu Ekle"
                open={open}
                setOpen={setOpen}
                onSubmit={formik.handleSubmit}
                buttons={[
                    {
                        type: "submit",
                        variant: "contained",
                        text: "Kaydet",
                    }
                ]}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="firstName"
                            label="İsim"
                            placeholder="Öğrenci ismini girin"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.firstName}
                            error={formik.touched.firstName && formik.errors.firstName}
                            helperText={formik.touched.firstName && formik.errors.firstName}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="lastName"
                            label="Soyisim"
                            placeholder="Öğrenci soyismini girin"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.lastName}
                            error={formik.touched.lastName && formik.errors.lastName}
                            helperText={formik.touched.lastName && formik.errors.lastName}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <PatternFormat
                            format="###########"
                            name="idNum"
                            label="T.C. Kimlik Numarası"
                            placeholder="T.C. kimlik numarasını girin"
                            fullWidth
                            onValueChange={({ value }) => formik.setFieldValue("idNum", value)}
                            value={formik.values.idNum}
                            customInput={TextField}
                            error={formik.touched.idNum && formik.errors.idNum}
                            helperText={formik.touched.idNum && formik.errors.idNum}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            name="classRoom"
                            label="Sınıf"
                            placeholder="Öğrenci sınıfını girin"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.classRoom}
                            error={formik.touched.classRoom && formik.errors.classRoom}
                            helperText={formik.touched.classRoom && formik.errors.classRoom}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            options={parents}
                            value={formik.values.parent}
                            onChange={(event, newValue) => formik.setFieldValue("parent", newValue || null)}
                            getOptionLabel={option => `${option.firstName} ${option.lastName}`}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            onBlur={() => getParents()}
                            onInputChange={(event, newValue) => getParents(newValue)}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label='Veli'
                                    fullWidth
                                    placeholder="İsim veya telefon numarası ile arama yapabilirsiniz"
                                    error={formik.touched.parent && formik.errors.parent}
                                    helperText={formik.touched.parent && formik.errors.parent}
                                />}
                        />
                        <Box display="flex">
                            <button
                                type="button"
                                onClick={() => { setCreateParentModal(true) }}
                                className={styles.input_add_new}>+ Yeni veli ekle</button>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Autocomplete
                            options={workTimes}
                            value={formik.values.workTime}
                            onChange={(event, newValue) => formik.setFieldValue("workTime", newValue)}
                            getOptionLabel={option => `${option.title} (${option.startTime.slice(0, 5)} - ${option.endTime.slice(0, 5)})`}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    label='Vardiya'
                                    fullWidth
                                    error={formik.touched.workTime && formik.errors.workTime}
                                    helperText={formik.touched.workTime && formik.errors.workTime}
                                />}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <AddressModal formik={formik} />
                    </Grid>
                </Grid>
            </CustomDialog>
            <CreateParent
                createParentModal={createParentModal}
                setCreateParentModal={setCreateParentModal}
                studentForm={formik}
                getParents={getParents}
            />
        </ >
    )
}

export default OgrenciEkle;