import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react'
import { deleteDocumentTypeRequest, getDocumentTypesOfVehicleRequest, saveDocumentTypeRequest } from '../../../api/controllers/document-type-controller';
import PageLayout from '../../../components/templates/PageLayout';
import CustomDataGrid from '../../../components/atoms/CustomDataGrid';
import CustomDialog from '../../../components/atoms/CustomDialog';
import ConfirmModal from '../../../components/atoms/DeleteConfirmModal';
import { toast } from 'react-toastify';
import PopoverMenu from '../../../components/atoms/PopoverMenu';
import { Delete } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';

const Page = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const [createModal, setCreateModal] = useState(false);

    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [data, setData] = useState([]);

    const deleteId = searchParams.get("deleteId");

    const initialValues = {
        name: "",
        documentCategory: "VEHICLE"
    }

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: () => onSubmit()
    });

    const columns = [
        {
            field: 'name',
            headerName: "Belge İsmi",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? value : "-",
        },
        {
            field: 'id',
            headerName: "",
            sortable: false,
            align: 'right',
            renderCell: ({ value }) => <PopoverMenu
                items={
                    [
                        {
                            icon: <Delete fontSize="inherit" />,
                            text: "Sil",
                            onClick: () => setSearchParams({ deleteId: value }),
                        }
                    ]
                }
            />,
        },
    ]

    const getDocumentTypes = async () => {
        setIsTableLoading(true);
        try {
            let res = await getDocumentTypesOfVehicleRequest();
            if (res) {
                setData(res.data)
            }
        } catch (error) { }
        setIsTableLoading(false);
    }

    const onSubmit = async () => {
        setIsSubmitting(true);
        try {
            let res = await saveDocumentTypeRequest(formik.values);
            if (res && res.status === 200) {
                setCreateModal(false);
                formik.resetForm();
                getDocumentTypes();
            }
        } catch (error) { }
        setIsSubmitting(false);
    }

    const deleteDocumentType = async (deleteId) => {
        try {
            let res = await deleteDocumentTypeRequest(deleteId)
            if (res) {
                getDocumentTypes()
                toast.success("Belge tipi silindi.");
                setSearchParams(false);
            }
        } catch (error) { }
    }

    useEffect(() => {
        getDocumentTypes();
    }, [])

    return (
        <PageLayout
            title="Servis Belge Yönetimi"
            buttons={[
                {
                    onClick: () => setCreateModal(true),
                    variant: "contained",
                    startIcon: < i className="bi bi-plus-lg" ></i >,
                    text: "Belge Tanımı Ekle",
                }
            ]}>
            <CustomDataGrid
                data={data}
                columns={columns}
                loading={isTableLoading}
                rowId={(params) => params?.id}
            />

            {/* Create Modal */}
            <CustomDialog
                open={createModal}
                setOpen={setCreateModal}
                title="Belge Tanımı Ekle"
                onSubmit={formik.handleSubmit}
                buttons={[
                    {
                        onClick: formik.handleSubmit,
                        variant: "contained",
                        disabled: formik.values.name.length < 1,
                        type: "submit",
                        loading: isSubmitting,
                        text: "Kaydet",
                    }
                ]}
                size="sm"
            >
                <TextField
                    required
                    name="name"
                    label="Belge İsmi"
                    placeholder="Ruhsat"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.name}
                />
            </CustomDialog >

            {/* Delete Confirm Modal */}
            {
                deleteId &&
                <ConfirmModal
                    open={deleteId}
                    title={"Emin misiniz?"}
                    setOpen={setSearchParams}
                    message={"Belge tipini silerseniz o belge tipine ait belgeler de silinecek!"}
                    handleConfirm={() => deleteDocumentType(deleteId)}
                />
            }
        </PageLayout >
    );
}

export default Page;