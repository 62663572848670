import { Grid, Box, Typography } from '@mui/material';
import styles from '../../tabs.module.scss';
import { useCustomer } from '../../../../../context/CustomerContext';
import { useSearchParams } from 'react-router-dom';
import CreateStudent from './CreateStudent';
import CreateEmployee from './CreateEmployee';
import ActionPageTitle from '../../../../atoms/ActionPageTitle';

export default function CreateTraveler() {

    const { customer } = useCustomer();
    const [searchParams, setSearchParams] = useSearchParams();

    return (
        <Box className={styles.create_user_page}>
            {
                searchParams.get("type") &&
                <>
                    {
                        customer.customerType === "School" &&
                        <CreateStudent />
                    }
                    {
                        customer.customerType === "Company" &&
                        <CreateEmployee />
                    }
                </>
            }
            {
                !searchParams.get("type") &&
                <>
                    <ActionPageTitle title="Yolcu Ekle" />

                    <Typography variant="h6" my="1rem">
                        | Yolcu eklemek için bir yöntem seçin
                    </Typography>

                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <button
                                type="button"
                                onClick={() => setSearchParams({ type: "single" })}
                                className={styles.option_button}>
                                <i className="bi bi-file-earmark-plus"></i>
                                Tekli ekle
                            </button>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <button
                                type="button"
                                onClick={() => setSearchParams({ type: "multiple" })}
                                className={styles.option_button} >
                                <i className="bi bi-file-earmark-spreadsheet"></i>
                                Excel’den toplu ekle
                            </button>
                        </Grid>
                    </Grid>
                </>
            }
        </Box>
    )
}