import { Checkbox, ListItem, ListItemButton, ListItemText } from '@mui/material';
import { useParams } from 'react-router';
import { useOverTime } from '../../context/OverTimeContext';

function EmployeeTravelerMarkerItemMultipleForOverTime({ traveler }) {
    const { stationForm, setStationForm } = useOverTime();
    const { planType } = useParams();

    const selected = stationForm.travelersId.some(v => v.id == traveler.id);
    const editable = window.location.pathname.includes("station")

    const handleClick = () => {
        if (editable) {
            if (selected) {
                setStationForm(prev => ({ ...prev, travelersId: prev.travelersId.filter(v => v.id !== traveler.id) }));
            } else {
                setStationForm(prev => ({ ...prev, travelersId: [...prev.travelersId, traveler] }));
            }
        }
    }

    return (
        <ListItem
            secondaryAction={
                <Checkbox
                    size="small"
                    edge="end"
                    checked={!editable ? !traveler[`${planType}Enable`] : selected}
                    onClick={() => editable ? handleClick(traveler) : {}}
                    disabled={selected ? false : !traveler[`${planType}Enable`]}
                />
            }
            //onClick={() => editable ? handleClick(traveler) : {}}
            disablePadding
        >
            <ListItemButton
                disabled={selected ? false : !traveler[`${planType}Enable`]}
                onClick={() => editable ? handleClick(traveler) : {}}
            >
                <ListItemText primary={traveler?.firstName + " " + traveler?.lastName} />
            </ListItemButton>
        </ListItem>
    )
}

export default EmployeeTravelerMarkerItemMultipleForOverTime;