import { TextField, Grid } from '@mui/material';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import CustomDialog from '../../../components/atoms/CustomDialog';
import { updateDriverRequest } from '../../../api/controllers/driver-controller';
import { toast } from 'react-toastify';
import PhoneTextfield from '../../../components/atoms/PhoneTextfield';
import AddressModal from '../../../components/molecules/AddressModal';

const UpdateDriverModal = ({ updateModal, setUpdateModal, getDriver, driver }) => {

    const [isSubmitting, setIsSubmitting] = useState(false);

    const formik = useFormik({
        initialValues: driver,
        onSubmit: () => onSubmit(),
    });

    const handleClose = () => {
        setUpdateModal(false);
        formik.resetForm();
    }

    const onSubmit = async () => {
        setIsSubmitting(true);
        try {
            let res = await updateDriverRequest(formik.values);
            if (res && res.status === 200) {
                handleClose();
                getDriver();
                toast.success("Sürücü başarıyla güncellendi!")
            }
        } catch (error) { }
        setIsSubmitting(false);
    }

    useEffect(() => {
        if (updateModal) {
            formik.setValues(driver)
        }
    }, [updateModal])

    return (
        <CustomDialog
            open={updateModal}
            closeFunc={handleClose}
            title="Sürücü Güncelle"
            onSubmit={formik.handleSubmit}
            buttons={[
                {
                    onClick: formik.handleSubmit,
                    type: "submit",
                    loading: isSubmitting,
                    variant: "contained",
                    text: "Kaydet",
                }
            ]}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="firstName"
                        label="İsim"
                        placeholder="Mehmet"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.firstName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="lastName"
                        label="Soyisim"
                        placeholder="Yılmaz"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.lastName}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <PhoneTextfield
                        name="phone.number"
                        phone={formik.values.phone}
                        onChange={({ value }) => formik.setFieldValue("phone.number", value)}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="email"
                        label="Mail Adresi"
                        placeholder="mehmet@yilmaz.com"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.email}
                    />
                </Grid>
                <Grid item xs={12}>
                    <AddressModal formik={formik} />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="password"
                        label="Şifre"
                        fullWidth
                        type="password"
                        onChange={formik.handleChange}
                        value={formik.values.password}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="rePassword"
                        label="Şifre (Tekrar)"
                        fullWidth
                        type="password"
                        onChange={formik.handleChange}
                        value={formik.values.rePassword}
                    />
                </Grid>
            </Grid>
        </CustomDialog >
    )
};

export default UpdateDriverModal;