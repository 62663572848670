import Create from "./Create";
import View from "./View";

function ImageUpload(props) {
    switch (props.type) {
        case "create":
            return (
                <Create {...props} />
            )

        case "view":
            return (
                <View {...props} />
            )

        default:
            break;
    }
};

export default ImageUpload;