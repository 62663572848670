import axios from "axios";

export const deleteCustomerRequest = (id) => axios.delete(`/customer/${id}`)

export const getCustomersRequest = () => axios.get(`/customer`)

export const getCustomersPageableRequest = (pageable, filter) => {
    let search_query = `title=like='${filter.search}'`;
    let manager_filter_query = `manager.id=in=${filter.managerIdList?.length > 0 ? `(${filter.managerIdList})` : "''"}`;
    let query = `${search_query};${manager_filter_query}`;
    return axios.get(`/customer/pageable?page=${pageable.page}&size=${pageable.size}&query=${query}`)
}

export const getCustomersByManagerIdPageableRequest = (managerId, pageable) => axios.get(`/customer/manager/${managerId}?page=${pageable.page}&size=${pageable.size}`)

export const getCustomerByIdRequest = (id) => axios.get(`/customer/${id}`)

export const createCustomerRequest = (form) => axios.post(`/customer${form.managerId ? `/manager/${form.managerId}` : ""}`, form)

export const updateCustomerRequest = (form) => axios.put(`/customer/${form.id}`, form)

export const updateCustomerManagerRequest = (customerId, managerId) => axios.put(`/customer/${customerId}/manager/${managerId}`)

export const getCustomerStatisticRequest = (customerId) => axios.get(`/customer/getStatistics/${customerId}`)