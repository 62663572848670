import { Box, Typography } from '@mui/material'
import React from 'react'

const Cost = ({ cost }) => {
    return (
        <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Typography sx={{ fontSize: "12px", color: "white" }}>1. Aylık Bakım Ücreti = {cost.monthlyMaintenance} ₺</Typography>
            <Typography sx={{ fontSize: "12px", color: "white" }}>2. Aylık Muayene Ücreti = {cost.monthlyInspection} ₺</Typography>
            <Typography sx={{ fontSize: "12px", color: "white" }}>3. Aylık Kasko Ücreti = {cost.monthlyKasko} ₺</Typography>
            <Typography sx={{ fontSize: "12px", color: "white" }}>4. Aylık Sigorta Ücreti = {cost.monthlyInsurance} ₺</Typography>
            <Typography sx={{ fontSize: "12px", color: "white" }}>5. Aylık Sürücü Ücreti = {cost.monthlyDriver} ₺</Typography>
            <Typography sx={{ fontSize: "12px", color: "white" }}>6. Aylık Lastik Ücreti = {cost.monthlyTyre} ₺</Typography>
            <Typography sx={{ fontSize: "12px", color: "white" }}>7. Yakıt Fiyatı = {cost.fuelPrice} ₺</Typography>
            <Typography sx={{ fontSize: "12px", color: "white" }}>8. Ortalama Yakıt Tüketimi = {cost.fuelConsumptionHundredKm} lt</Typography>
            <Typography sx={{ fontSize: "12px", color: "white" }}>9. Kilometre Başına Yakıt Ücreti = {cost.fuelCostKm} ₺</Typography>
            <Typography sx={{ fontSize: "12px", color: "white" }}>10. Kilometre Başına Maliyet = {cost.costKm.toFixed(2)} ₺</Typography>
            <Typography sx={{ fontSize: "12px", color: "white" }}>11. Aylık Toplam Kilometre = {cost.monthlyTotalKm} km</Typography>
        </Box>
    )
}

export default Cost
