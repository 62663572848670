import React, { useEffect, useState } from 'react'
import { Box, Divider, TextField, Typography, InputAdornment, Alert, Grid, Autocomplete, Button } from '@mui/material'
import styles from './style.module.scss'
import { getAvailableVehiclesForOverTimeRequest, getVehiclesRequest, updateVehicleRequest } from '../../../../api/controllers/vehicle-controller';
import VehicleCard from '../../../molecules/VehicleCard';
import PlansSidebarLayout from '../../../templates/PlansSidebarLayout';
import { ArrowBackRounded } from '@mui/icons-material';
import CustomDialog from '../../../atoms/CustomDialog'
import { getDriversRequest } from '../../../../api/controllers/driver-controller';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router';
import { useOverTime } from '../../../../context/OverTimeContext';
import { createOverTimeRequest, createVoyageOverTimeRequest } from '../../../../api/controllers/overtime-controller';

function CreatePlan() {
    const navigate = useNavigate();
    const { planType, mesaiId } = useParams();
    const { createForm, setCreateForm, setUpdateForm, resetCreateForm, overTime, getOverTime } = useOverTime();
    const [vehicles, setVehicles] = useState([]);
    const [searchText, setSearchText] = useState("")
    const [assignVehicleModal, setAssignVehicleModal] = useState(false)
    const [conflictVehicleModal, setConflictVehicleModal] = useState(false)
    const [drivers, setDrivers] = useState([])
    const [selectedVehicle, setSelectedVehicle] = useState({})
    const [selectedDriver, setSelectedDriver] = useState(null)
    const [createType, setCreateType] = useState('ARRIVAL')

    const createPlan = async (isForced) => {
        let res
        try {
            if (createType === 'DEPARTURE-ARRIVAL') {
                res = await createVoyageOverTimeRequest({ ...createForm, planDirectionType: "DEPARTURE" }, mesaiId, isForced)
            } else {
                res = await createOverTimeRequest({ ...createForm, planDirectionType: createType }, mesaiId, isForced)
            }
            if (res) {
                getOverTime(mesaiId)
                const data = createType === 'DEPARTURE-ARRIVAL' ? res.data[0] : res.data
                setUpdateForm((prev) => ({ ...prev, step: 2, plan: data }))
                navigate(window.location.pathname.replace(`${planType}/olustur`, `${data.planDirectionType.toLowerCase()}/plan/${data.uuid}/duzenle`))
            }
        } catch (error) {
            if (error?.response?.status === 406) {
                setConflictVehicleModal(true)
            }
        }
    }

    const filteredVehicles = vehicles.filter(v => v.licensePlate.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));

    //Araçları getirir
    const getVehicles = async () => {
        let res = await getVehiclesRequest(/* {
            startDate: overTime.startDate,
            endDate: overTime.endDate,
            time: planType === 'departure' ? overTime.workTime.startTime : planType === 'arrival' ? overTime.workTime.endTime : null,
        } */);
        if (res) {
            setVehicles(res.data)
        }
    }

    useEffect(() => {
        if (mesaiId, overTime) {
            getVehicles()
            getDrivers()
        }
        return () =>
            resetCreateForm()
    }, [mesaiId, overTime])

    //Sürücüleri getirir
    const getDrivers = async () => {
        let res = await getDriversRequest();
        if (res) {
            setDrivers(res.data);
        }
    }

    const selectVehicle = (vehicle) => {
        if (vehicle.driver) {
            setCreateForm(prev => ({ ...prev, vehicleId: vehicle.id }))
        } else {
            setCreateForm(prev => ({ ...prev, vehicleId: null }))
            openAssignVehicleModal(vehicle)
            setSelectedVehicle(vehicle)
        }
    }

    const openAssignVehicleModal = () => {
        setAssignVehicleModal(true)
    }

    const closeAssignVehicleModal = () => {
        setAssignVehicleModal(false)
    }

    const openConflictVehicleModal = () => {
        setConflictVehicleModal(true)
    }

    const closeConflictVehicleModal = () => {
        setConflictVehicleModal(false)
    }

    const assignDriverToVehicle = async () => {
        let res = await updateVehicleRequest({ ...selectedVehicle, driver: selectedDriver });
        if (res && res.status === 200) {
            closeAssignVehicleModal();
            getVehicles()
            toast.success("Araca sürücü başarıyla atandı!")
        }
    }

    return (
        <>
            <PlansSidebarLayout
                header={{
                    iconButton: {
                        icon: <ArrowBackRounded fontSize="inherit" />,
                        props: {
                            onClick: () => {
                                navigate(window.location.pathname.split("/olustur")[0]);
                            }
                        }
                    },
                    title: {
                        text: "Güzergah Oluştur"
                    }
                }}
                footer={{
                    buttons: [
                        {
                            text: "İptal et",
                            props: {
                                variant: "outlined",
                                color: "secondary",
                                onClick: () => {
                                    navigate(window.location.pathname.split("/olustur")[0]);
                                }
                            }
                        },
                        {
                            text: "Devam et",
                            props: {
                                variant: "contained",
                                disabled: !(createForm.title && createForm.vehicleId),
                                onClick: () => createPlan(false),
                            }
                        },
                    ]
                }}
            >
                <Box className={styles.container}>
                    <Box className={styles.header}>
                        <Box className={`${styles.tabs}`}>
                            <Button
                                size='small'
                                onClick={() => setCreateType('DEPARTURE')}
                                variant={createType === 'DEPARTURE' ? 'contained' : 'text'}
                            >
                                Tek Gidiş
                            </Button>
                            <Button
                                size='small'
                                onClick={() => setCreateType('ARRIVAL')}
                                variant={createType === 'ARRIVAL' ? 'contained' : 'text'}
                            >
                                Tek Dönüş
                            </Button>
                            <Button
                                size='small'
                                onClick={() => setCreateType('DEPARTURE-ARRIVAL')}
                                variant={createType === 'DEPARTURE-ARRIVAL' ? 'contained' : 'text'}
                            >
                                Gidiş - Dönüş
                            </Button>
                        </Box>
                        <Typography variant="h6">
                            | Güzergah için isim belirleyin
                        </Typography>
                        <TextField
                            fullWidth
                            size="small"
                            placeholder="Güzergah ismini yazın"
                            value={createForm?.title || ""}
                            onChange={e => setCreateForm(prev => ({ ...prev, title: e.target.value }))}
                        />
                        <Divider flexItem />
                        <Typography variant="h6">
                            | Güzergah için servis seçin
                        </Typography>
                        <TextField
                            variant="standard"
                            className={styles.search}
                            fullWidth
                            size="small"
                            placeholder="Servisler içerisinde arayın..."
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><i className="bi bi-search" /></InputAdornment>,
                            }}
                        />
                    </Box>
                    <Box className={styles.list}>
                        {
                            filteredVehicles.length > 0
                                ?
                                filteredVehicles.map((v, i) =>
                                    <VehicleCard
                                        key={i}
                                        vehicle={v}
                                        onClick={() => selectVehicle(v)}
                                        isActive={createForm?.vehicleId === v.id}
                                    />
                                )
                                :
                                <Alert severity="warning">Araç bulunamadı!</Alert>
                        }
                    </Box>
                </Box>
            </PlansSidebarLayout>
            <CustomDialog
                size="sm"
                open={assignVehicleModal}
                closeFunc={closeAssignVehicleModal}
                title="Bilgi"
                buttons={[
                    {
                        type: "button",
                        onClick: assignDriverToVehicle,
                        variant: "contained",
                        text: "Kaydet",
                    }
                ]}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Alert severity='info' >
                            Seçtiğiniz aracın sürüsü bulunmamaktadır, aşağıdan sürücüsünü atayabilirsiniz.
                        </Alert>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Autocomplete
                            name="driver"
                            options={drivers}
                            renderInput={(params) =>
                                <TextField
                                    required
                                    {...params}
                                    label="Sürücü"
                                    placeholder="Listeden seçin"
                                    fullWidth
                                />}
                            getOptionLabel={(option) => option.firstName + " " + option.lastName}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            value={selectedDriver}
                            onChange={(event, newValue) =>
                                setSelectedDriver(newValue)
                            }
                        />
                    </Grid>
                </Grid>
            </CustomDialog>


            <CustomDialog
                size="sm"
                open={conflictVehicleModal}
                closeFunc={closeConflictVehicleModal}
                title="Bilgi"
                buttons={[
                    {
                        type: "button",
                        onClick: closeConflictVehicleModal,
                        variant: "text",
                        text: "Vazgeç",
                    },
                    {
                        type: "button",
                        onClick: () => createPlan(true),
                        variant: "contained",
                        text: "Devam Et",
                    },
                ]}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Alert severity='info' >
                            Seçtiğiniz araç bu vardiya zamanında başka bir mesaide kullanılmakta. Yine de devam etmek ister misiniz?
                        </Alert>
                    </Grid>
                </Grid>
            </CustomDialog>
        </>
    )
}

export default React.memo(CreatePlan)