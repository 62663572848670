import { Delete, Info } from '@mui/icons-material';
import { Typography, ListItemIcon, MenuItem, Grid, TextField } from '@mui/material';
import { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { createWorkTimeRequest, updateWorkTimeRequest, getWorkTimesByCustomerIdRequest, deleteWorkTimeRequest } from '../../../../api/controllers/work-time-controller';
import { useCustomer } from '../../../../context/CustomerContext';
import CustomDataGrid from '../../../atoms/CustomDataGrid';
import CustomDialog from '../../../atoms/CustomDialog';
import CustomMenu from '../../../molecules/CustomMenu';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { TimePicker } from '@mui/x-date-pickers';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import moment from 'moment';
import ConfirmModal from '../../../atoms/DeleteConfirmModal';

export default function WorkTimes() {
    const { customer, setActionButtons } = useCustomer();
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [deleteId, setDeleteId] = useState(false)
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [data, setData] = useState([]);

    const formik = useFormik({
        validationSchema: yup.object({
            title: yup
                .string()
                .required('Bu alan gereklidir'),
        }),
        initialValues: {
            endTime: "00:00",
            sequence: 0,
            sequenceSize: 0,
            startTime: "00:00",
            title: "",
        },
        onSubmit: () => onSubmit()
    });

    const getData = async () => {
        setIsTableLoading(true);
        try {
            let res = await getWorkTimesByCustomerIdRequest(customer.id);
            if (res) {
                setData(res.data)
            }
        } catch (error) { }
        setIsTableLoading(false);
    }

    const handleDelete = async () => {
        try {
            let res = await deleteWorkTimeRequest(deleteId);
            if (res) {
                getData();
                setDeleteId(false);
                toast.success("Vardiya silindi");
            }
        } catch (error) { }
    }

    const onSubmit = async () => {
        setIsSubmitting(true);
        try {
            let res
            if (searchParams.get("action") === "create") {
                res = await createWorkTimeRequest(customer.id, formik.values);
            } else if (searchParams.get("action") === "update") {
                res = await updateWorkTimeRequest(formik.values);
            }
            if (res) {
                getData();
                handleClose();
                toast("İşlem başarıyla gerçekleşti", { type: "success" });
            }
        } catch (error) { }
        setIsSubmitting(false);
    }

    const handleClose = () => {
        formik.resetForm();
        if (searchParams.has('action')) {
            searchParams.delete('action');
            setSearchParams(searchParams);
        }
    };

    useEffect(() => {
        getData();
    }, [])

    useLayoutEffect(() => {
        setActionButtons([
            {
                onClick: () => setSearchParams({ action: "create" }),
                variant: "contained",
                startIcon: <i className="bi bi-plus-lg" />,
                text: "Vardiya Ekle",
            }
        ])

        return () => {
            setActionButtons([])
        };
    }, [])

    //Tablo sütunları
    const columns = [
        {
            field: 'title',
            headerName: "Vardiya Başlığı",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value || "-",
        },
        {
            field: 'startTime',
            headerName: "Başlangıç Zamanı",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value.slice(0, 5) || "-",
        },
        {
            field: 'endTime',
            headerName: "Bitiş Zamanı",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value.slice(0, 5) || "-",
        },
        {
            field: 'id',
            headerName: "",
            sortable: false,
            align: 'right',
            renderCell: ({ row, value }) => <CustomMenu>
                <MenuItem onClick={() => {
                    setSearchParams({ action: "update" });
                    formik.setValues(row);
                }}>
                    <ListItemIcon>
                        <Info fontSize="small" />
                    </ListItemIcon>
                    Düzenle
                </MenuItem>
                <MenuItem onClick={() => {
                    setDeleteId(value);
                }}>
                    <ListItemIcon>
                        <Delete fontSize="small" />
                    </ListItemIcon>
                    Sil
                </MenuItem>
            </CustomMenu>,
        },
    ]

    return (
        <>
            <Typography
                variant="h6"
                sx={{ marginBottom: "1.5rem" }}
            >
                | Vardiyalar
            </Typography>
            <CustomDataGrid
                data={data}
                columns={columns}
                loading={isTableLoading}
                rowId={(params) => params?.id}
            />

            <CustomDialog
                size="sm"
                open={searchParams.get("action") === "create" || searchParams.get("action") === "update"}
                closeFunc={handleClose}
                title={
                    (searchParams.get("action") === "create" && "Vardiya Oluştur")
                    || (searchParams.get("action") === "update" && "Vardiya Düzenle")}
                onSubmit={formik.handleSubmit}
                buttons={[
                    {
                        onClick: formik.handleSubmit,
                        type: "submit",
                        loading: isSubmitting,
                        variant: "contained",
                        text: "Kaydet",
                    }
                ]}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            name="title"
                            label="Vardiya Başlığı"
                            placeholder="Vardiya başlığını yazın"
                            fullWidth
                            onChange={formik.handleChange}
                            value={formik.values.title}
                            error={formik.touched.title && formik.errors.title}
                            helperText={formik.touched.title && formik.errors.title}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TimePicker
                            label="Başlangıç Zamanı"
                            value={new Date(0, 0, 0, formik.values.startTime.slice(0, 2), formik.values.startTime.slice(3, 5))}
                            onChange={(newValue) => formik.setFieldValue("startTime", moment(newValue).format("HH:mm:ss"))}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TimePicker
                            label="Bitiş Zamanı"
                            value={new Date(0, 0, 0, formik.values.endTime.slice(0, 2), formik.values.endTime.slice(3, 5))}
                            onChange={(newValue) => formik.setFieldValue("endTime", moment(newValue).format("HH:mm:ss"))}
                            renderInput={(params) => <TextField {...params} fullWidth />}
                        />
                    </Grid>
                </Grid>
            </CustomDialog >
            {/* Delete modal */}
            {
                deleteId &&
                <ConfirmModal
                    open={deleteId}
                    title={"Emin misiniz?"}
                    setOpen={setDeleteId}
                    message={"Vardiyayı silmek istediğinize emin misiniz?"}
                    handleConfirm={handleDelete}
                />
            }

        </>
    )
}