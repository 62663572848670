import { useState } from 'react'
import { Box, Button, Popover, Typography } from '@mui/material';
import styles from './style.module.scss';
import { FilterAltRounded } from '@mui/icons-material';
import { Stack } from '@mui/system';

function Filter({ children, isEmpty, onClear, onSubmit }) {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <Box className={styles.container} >
            <Button
                className={styles.button}
                startIcon={<FilterAltRounded />}
                color="secondary"
                onClick={handleClick}
            >
                Filtrele
            </Button>
            <Popover
                PaperProps={{
                    sx: {
                        maxWidth: "16rem",
                        width: "100%",
                        p: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem"
                    }
                }}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <Typography variant="overline">SONUÇLARI FİLTRELE</Typography>
                {
                    children
                }
                <Stack direction="row" spacing={2}>
                    {
                        !isEmpty &&
                        <Button
                            onClick={onClear}
                            size="small"
                            color="secondary"
                            sx={{
                                flex: 1
                            }}
                        >
                            Temizle
                        </Button>
                    }
                    <Button
                        onClick={() => {
                            onSubmit();
                            handleClose();
                        }}
                        disabled={isEmpty}
                        size="small"
                        variant="contained"
                        sx={{
                            flex: 1
                        }}
                    >
                        Filtrele
                    </Button>
                </Stack>
            </Popover>
        </Box>
    )
}

export default Filter;