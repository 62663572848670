import axios from "axios";

export const getMeRequest = ({ hideMessage }) => axios.get(`/account`, { params: { hideMessage } })

export const logoutRequest = () => axios.get(`/account/logout`)

export const controlPasswordRequest = ({ password, rememberMe, username }) => axios.post(`/account/controlPassword`, { password, rememberMe, username })

export const loginWithPasswordRequest = (form) => axios.post(`/account/login`, form)

export const setFirebaseTokenRequest = (token) => axios.put(`/account/firebase/web?firebaseToken=${token}`)

export const checkFirebaseTokenRequest = () => axios.get("/account/hasFirebaseToken/web")