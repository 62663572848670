import { TextField, Grid, Autocomplete } from '@mui/material';
import { useFormik } from 'formik';
import { useState } from 'react';
import CustomDialog from '../../../components/atoms/CustomDialog';
import { saveVehicleRequest } from '../../../api/controllers/vehicle-controller';
import { NumericFormat, PatternFormat } from 'react-number-format';
import { toast } from 'react-toastify';
import { DatePicker } from '@mui/x-date-pickers';


const CreateVehicleModal = ({ documentTypes, createModal, setCreateModal, drivers, getVehicles, setDocumentModal, setSelectedVehicleId }) => {
    const [isSubmitting, setIsSubmitting] = useState(false);

    const formik = useFormik({
        initialValues: {
            brand: "",
            capacity: null,
            imei: "",
            licensePlate: "",
            model: "",
            year: "",
            totalDistance: null,
            driver: null,
        },
        onSubmit: values => {
            onSubmit(values);
        },
    });

    const handleClose = () => {
        setCreateModal(false);
        formik.resetForm();
    }

    const onSubmit = async () => {
        setIsSubmitting(true);
        try {
            let res = await saveVehicleRequest(formik.values);
            if (res && res.status === 200) {
                handleClose();
                getVehicles();
                if (documentTypes.length > 0) {
                    setSelectedVehicleId(res.data.id);
                    setDocumentModal(true);
                }
                toast.success("Servis başarıyla eklendi")
            }
        } catch (error) {
            toast.error("Bir hata meydana geldi. Lütfen daha sonra tekrar deneyin!")
        }
        setIsSubmitting(false);
    }

    return (
        <CustomDialog
            open={createModal}
            closeFunc={handleClose}
            title="Servis Ekle"
            onSubmit={formik.handleSubmit}
            buttons={[
                {
                    onClick: formik.handleSubmit,
                    type: "submit",
                    loading: isSubmitting,
                    variant: "contained",
                    text: "Kaydet",
                }
            ]}
        >
            <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="licensePlate"
                        label="Plaka"
                        placeholder="20 AA 2020"
                        fullWidth
                        onChange={e => formik.setFieldValue("licensePlate", e.target.value.toLocaleUpperCase())}
                        value={formik.values.licensePlate}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <PatternFormat
                        format="###############"
                        required
                        name="imei"
                        label="IMEI"
                        placeholder="###############"
                        fullWidth
                        onValueChange={({ value }) => formik.setFieldValue("imei", value)}
                        value={formik.values.imei}
                        customInput={TextField}
                        error={formik.values.imei ? formik.values.imei?.length !== 15 : false}
                        helperText={formik.values.imei ? formik.values.imei?.length !== 15 && "Imei 15 haneli olmalıdır" : null}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        required
                        name="brand"
                        label="Marka"
                        placeholder="Mercedes"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.brand}
                    />
                </Grid>
                <Grid item xs={10} md={4}>
                    <TextField
                        required
                        name="model"
                        label="Model"
                        placeholder="Sprinter"
                        fullWidth
                        onChange={formik.handleChange}
                        value={formik.values.model}
                    />
                </Grid>
                <Grid item xs={2} md={2}>
                    <NumericFormat
                        required
                        name="year"
                        label="Yıl"
                        placeholder="2020"
                        fullWidth
                        minLength={4}
                        maxLength={4}
                        onValueChange={({ floatValue }) => formik.setFieldValue("year", floatValue)}
                        value={formik.values.year}
                        customInput={TextField}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <NumericFormat
                        required
                        name="capacity"
                        label="Kapasite (Kişi)"
                        placeholder="18"
                        fullWidth
                        onValueChange={({ floatValue }) => formik.setFieldValue("capacity", floatValue)}
                        value={formik.values.capacity}
                        customInput={TextField}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <NumericFormat
                        required
                        name="totalDistance"
                        label="KM"
                        placeholder="120000"
                        fullWidth
                        thousandSeparator="."
                        decimalSeparator=","
                        onValueChange={({ floatValue }) => formik.setFieldValue("totalDistance", floatValue)}
                        value={formik.values.totalDistance}
                        customInput={TextField}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <Autocomplete
                        name="driver"
                        options={drivers}
                        renderInput={(params) =>
                            <TextField
                                required
                                {...params}
                                label="Sürücü"
                                placeholder="Listeden seçin"
                                fullWidth
                            />}
                        getOptionLabel={(option) => option.firstName + " " + option.lastName}
                        value={formik.values.driver}
                        onChange={(event, newValue) => formik.setFieldValue("driver", newValue ? newValue : null)}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <NumericFormat
                        required
                        name="lastMaintenanceDistance"
                        label="Son Bakım KM'si"
                        placeholder="120000"
                        fullWidth
                        thousandSeparator="."
                        decimalSeparator=","
                        onValueChange={({ floatValue }) => {
                            formik.setFieldValue("lastMaintenanceDistance", floatValue)
                        }}
                        value={formik.values.lastMaintenanceDistance}
                        customInput={TextField}
                    />
                </Grid>
                <Grid item xs={6} md={3}>
                    <NumericFormat
                        required
                        name="maintenanceInterval"
                        label="Periyodik bakım KM'si"
                        placeholder="120000"
                        fullWidth
                        thousandSeparator="."
                        decimalSeparator=","
                        onValueChange={({ floatValue }) => {
                            formik.setFieldValue("maintenanceInterval", floatValue)
                        }}
                        value={formik.values.maintenanceInterval}
                        customInput={TextField}
                    />
                </Grid>
                <Grid item xs={6} md={6}>
                    <DatePicker
                        label="Zorunlu Sigorta Tarihi"
                        value={formik.values.compulsoryInsuranceDate}
                        onChange={(newValue) => {
                            const newDate = new Date(newValue);
                            newDate.setHours(newDate.getHours() + 3);
                            formik.setFieldValue("compulsoryInsuranceDate", newValue ? newDate : null)
                        }}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                        minDate={new Date()}
                    />
                </Grid>
                <Grid item xs={6} md={6}>
                    <DatePicker
                        label="Muayene Tarihi"
                        value={formik.values.generalInspectionDate}
                        onChange={(newValue) => {
                            const newDate = new Date(newValue);
                            newDate.setHours(newDate.getHours() + 3);
                            formik.setFieldValue("generalInspectionDate", newValue ? newDate : null)
                        }}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                        minDate={new Date()}
                    />
                </Grid>
                <Grid item xs={6} md={6}>
                    <DatePicker
                        label="Kasko Tarihi"
                        value={formik.values.ownDamageInsuranceDate}
                        onChange={(newValue) => {
                            const newDate = new Date(newValue);
                            newDate.setHours(newDate.getHours() + 3);
                            formik.setFieldValue("ownDamageInsuranceDate", newValue ? newDate : null)
                        }}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                        minDate={new Date()}
                    />
                </Grid>
            </Grid>
        </CustomDialog>
    )
};

export default CreateVehicleModal;