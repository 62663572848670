import { Autocomplete, TextField } from '@mui/material';
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { useCustomer } from '../../../../context/CustomerContext';
import { getManagersRequest } from '../../../../api/controllers/manager-controller';
import { updateCustomerManagerRequest } from '../../../../api/controllers/customer-controller';
import CustomDialog from '../../../atoms/CustomDialog';

function EditManager({ open, setOpen }) {
    const { customer, setCustomer } = useCustomer();
    const [managers, setManagers] = useState([]);
    const [manager, setManager] = useState(customer.manager);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const getManagers = async () => {
        let res = await getManagersRequest();
        if (res) {
            setManagers(res.data)
        }
    }

    const handleUpdate = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            let res = await updateCustomerManagerRequest(customer.id, manager.id);
            if (res) {
                setOpen(false);
                setCustomer(prev => ({ ...prev, manager: manager }))
                toast.success("Yönetici değiştirildi");
            }
        } catch (error) { }
        setIsSubmitting(false);
    }

    useEffect(() => {
        getManagers();
    }, [])

    return (
        <CustomDialog
            size="sm"
            open={open}
            closeFunc={() => setOpen(false)}
            title="Yöneticiyi değiştir"
            onSubmit={handleUpdate}
            buttons={[
                {
                    type: "submit",
                    loading: isSubmitting,
                    variant: "contained",
                    text: "Kaydet",
                }
            ]}
        >
            <Autocomplete
                options={managers}
                value={manager}
                onChange={(event, newValue) => setManager(newValue)}
                getOptionLabel={option => option.firstName + " " + option.lastName}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) =>
                    <TextField
                        {...params}
                        label='Yönetici'
                        fullWidth
                    />}
            />
        </CustomDialog>
    )
}

export default EditManager