import React from 'react'
import MultipleTravelerMarker from './MultipleTravelerMarker';
import { Popup } from 'react-leaflet';
import { List } from '@mui/material';
import MultipleEmployeeTravelerMarkerItem from './EmployeeTravelerMarkerItemMultiple';
import { usePlans } from '../../context/PlansContext';
import { useParams } from 'react-router';

function EmployeeTravelerMarkerMultiple({ travelers }) {
    const { stationForm } = usePlans();
    const { planType } = useParams();
    return (
        <MultipleTravelerMarker
            position={[travelers[0].address.position.latitude, travelers[0].address.position.longitude]}
            isSelected={travelers.some(traveler => stationForm.travelerIds.some(v => v.id == traveler.id))}
            isPassive={!travelers.filter(traveler => traveler[planType]?.enable)?.length > 0}
            count={travelers.length}
        >
            <Popup>
                <List dense sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {
                        travelers.map((traveler, i) => <MultipleEmployeeTravelerMarkerItem key={i} traveler={traveler} />)
                    }
                </List>
            </Popup>
        </MultipleTravelerMarker >
    )
}

export default EmployeeTravelerMarkerMultiple;