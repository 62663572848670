import React, { useMemo } from 'react'
import { Marker } from 'react-leaflet';
import L from 'leaflet';
import { renderToStaticMarkup } from 'react-dom/server';
import styles from './style.module.scss';

const CustomerMarker = ({ position, vehicle, color }) => useMemo(() => {
    const icon = renderToStaticMarkup(
        <div className={styles.marker_container}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" fill={color || "#F39C12"} />
                <path d="M9.3334 19.7895C9.3334 20.5306 9.66183 21.1958 10.1755 21.659V22.7369C10.1755 23.4358 10.7397 24 11.4387 24C12.1376 24 12.7018 23.4358 12.7018 22.7369V22.3158H19.4387V22.7369C19.4387 23.4274 20.0029 24 20.7018 24C21.3924 24 21.965 23.4358 21.965 22.7369V21.659C22.4787 21.1958 22.8071 20.5306 22.8071 19.7895V11.3685C22.8071 8.42108 19.7924 8.00003 16.0702 8.00003C12.3481 8.00003 9.3334 8.42108 9.3334 11.3685V19.7895ZM12.2808 20.6316C11.5818 20.6316 11.0176 20.0674 11.0176 19.3685C11.0176 18.6695 11.5818 18.1053 12.2808 18.1053C12.9797 18.1053 13.5439 18.6695 13.5439 19.3685C13.5439 20.0674 12.9797 20.6316 12.2808 20.6316ZM19.8597 20.6316C19.1608 20.6316 18.5966 20.0674 18.5966 19.3685C18.5966 18.6695 19.1608 18.1053 19.8597 18.1053C20.5587 18.1053 21.1229 18.6695 21.1229 19.3685C21.1229 20.0674 20.5587 20.6316 19.8597 20.6316ZM21.1229 15.579H11.0176V11.3685H21.1229V15.579Z" fill="#FEFEFE" />
                <rect x="0.5" y="0.5" width="31" height="31" rx="15.5" stroke="#FEFEFE" />
            </svg>
            <span>{vehicle.licensePlate}</span>
        </div >
    )

    const markerIcon = new L.divIcon({
        shadowAnchor: null,
        className: styles.vehicle_marker,
        html: icon,
    });
    return (
        <Marker
            position={position}
            icon={markerIcon}
        />
    )
}, [vehicle.id])

export default CustomerMarker;

