import styles from './style.module.scss';
import { Box, IconButton, Typography } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { DeleteRounded, EditRounded } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

const StationCard = ({ station, index, deleteOnClick, updateOnClick }) => {

    const LightTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} placement="left" classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.secondary.main,
            boxShadow: theme.shadows[1],
            fontSize: 12,
        },
    }));

    return (
        <LightTooltip
            title={
                station?.travelers?.length > 0 || station?.traveler?.length > 0 ?
                    station.travelers.map((v, i) => (
                        <ul key={i}>
                            <li>{`${i + 1} - ${v.firstName || ""} ${v.lastName || ""}`} {v.isReceive ? <span style={{ color: "var(--success)" }}>(Alındı)</span> : ""}</li>
                        </ul>
                    ))
                    : ""
            }>
            <Box className={styles.container}>
                <Typography variant="body2">
                    {index + 1} - {station?.title || "Bilinmiyor"} {updateOnClick ? `(${station?.travelerCount || 0} kişi)` : ""} {station?.traveler ? <span style={{ color: "var(--success)" }}>{station.traveler[0].isReceive ? "(Alındı)" : ""}</span> : ""}
                </Typography>
                <Box className={styles.actions}>
                    {
                        deleteOnClick &&
                        <Tooltip title="Sil">
                            <IconButton onClick={deleteOnClick} size="small" color="inherit">
                                <DeleteRounded fontSize="inherit" color="inherit" />
                            </IconButton>
                        </Tooltip>
                    }
                    {
                        updateOnClick &&
                        <Tooltip title="Düzenle">
                            <IconButton onClick={updateOnClick} size="small" color="inherit">
                                <EditRounded fontSize="inherit" color="inherit" />
                            </IconButton>
                        </Tooltip>
                    }
                </Box>
            </Box>
        </LightTooltip>
    )
}

export default StationCard;