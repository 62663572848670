import { MenuItem, ListItemIcon } from '@mui/material';
import { useEffect, useState } from 'react';
import CustomDataGrid from '../../../components/atoms/CustomDataGrid';
import PageLayout from '../../../components/templates/PageLayout';
import { getDriversPageableRequest, driverDeleteRequest} from '../../../api/controllers/driver-controller';
import { useNavigate, useSearchParams} from 'react-router-dom';
import { getDocumentTypesOfDriverRequest } from '../../../api/controllers/document-type-controller';
import { toast } from 'react-toastify';
import CustomMenu from '../../../components/molecules/CustomMenu';
import { DescriptionRounded, Info, Delete } from '@mui/icons-material';
import Search from '../../../components/molecules/Search';
import { Stack } from '@mui/system';
import CreateDriverModal from './surucu-ekle';
import DocumentModal from './surucu-dokuman';
import ConfirmModal from '../../../components/atoms/DeleteConfirmModal';

const Page = () => {
    const navigate = useNavigate();

    //States
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [data, setData] = useState([]);
    const [pageable, setPageable] = useState({ page: 0, size: 10, total: 0 });

    const [createModal, setCreateModal] = useState(false);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [documentModal, setDocumentModal] = useState(false);
    const [selectedDriverId, setSelectedDriverId] = useState(null);
    const [filter, setFilter] = useState({
        search: "",
    });

    const [searchParams, setSearchParams] = useSearchParams();
    const deleteId = searchParams.get("deleteId");

    //Döküman tiplerini getirir
    const getDocumentTypes = async () => {
        let res = await getDocumentTypesOfDriverRequest();
        if (res) {
            setDocumentTypes(res.data);
        }
    }
    
    const deleteDriver = async (deleteId) => {
        let res = await driverDeleteRequest(deleteId);
        if (res) {
            setData(data.filter(item => item.id?.toString() !== deleteId));
            setSearchParams(false);
            toast.success("Sürücü silindi");
        }
    }

    //Tablo sütunları
    const columns = [
        {
            field: 'firstName',
            headerName: "İsim",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? value : "-",
        },
        {
            field: 'lastName',
            headerName: "Soyisim",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? value : "-",
        },
        {
            field: 'phone',
            headerName: "Telefon",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? value?.areaCode + value?.number : "-",
        },
        {
            field: 'email',
            headerName: "Mail Adresi",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value ? value : "-",
        },
        {
            field: 'id',
            headerName: "",
            sortable: false,
            align: 'right',
            minWidth: 200,
            renderCell: ({ value }) => <CustomMenu>
                <MenuItem onClick={() => navigate(`/app/suruculer/detay/${value}`)}>
                    <ListItemIcon>
                        <Info fontSize="small" />
                    </ListItemIcon>
                    Detaylar
                </MenuItem>
                <MenuItem onClick={async () => {
                    if (documentTypes.length > 0) {
                        await setSelectedDriverId(value);
                        setDocumentModal(true);
                    } else {
                        toast.warn(<p>Öncelikle <b>Belge Yönetimi</b> sayfasından belge tanımı ekleyin!</p>)
                    }
                }}>
                    <ListItemIcon>
                        <DescriptionRounded fontSize="small" />
                    </ListItemIcon>
                    Belgeler
                </MenuItem>

                <MenuItem onClick={() =>setSearchParams({deleteId:value})}>
                    <ListItemIcon>
                        <Delete fontSize="small" />
                    </ListItemIcon>
                    Sil
                </MenuItem>
                
            </CustomMenu >
        },
    ]


    //Sürücüleri getirir
    const getDrivers = async () => {
        setIsTableLoading(true);
        try {
            let res = await getDriversPageableRequest(pageable, filter);
            if (res) {
                setData(res.data.content);
                setPageable({ ...pageable, total: res.data.totalElements });
            }
        } catch (error) { }
        setIsTableLoading(false);
    }

    useEffect(() => {
        getDocumentTypes();
    }, [])

    useEffect(() => {
        getDrivers();
    }, [pageable.page, pageable.size, filter.search])

    return (
        <PageLayout
            title="Sürücüler"
            buttons={[
                {
                    onClick: () => navigate("/app/suruculer/belge-yonetimi"),
                    variant: "outlined",
                    color: "secondary",
                    startIcon: <i className="bi bi-archive"></i>,
                    text: "Belge Yönetimi",
                },
                {
                    onClick: () => setCreateModal(true),
                    variant: "contained",
                    startIcon: <i className="bi bi-plus-lg"></i>,
                    text: "Sürücü Ekle",
                }
            ]}>
            <Stack spacing={2}>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                    <Search
                        onChange={e => setFilter(prev => ({ ...prev, search: e }))}
                    />
                </Stack>
                <CustomDataGrid
                    data={data}
                    columns={columns}
                    loading={isTableLoading}
                    rowId={(params) => params?.id}
                    pageable={pageable}
                    setPageable={setPageable}
                />
            </Stack>

            {/* Ekleme modalı */}
            {
                createModal &&
                <CreateDriverModal
                    createModal={createModal}
                    setCreateModal={setCreateModal}
                    getDrivers={getDrivers}
                    setDocumentModal={setDocumentModal}
                    setSelectedDriverId={setSelectedDriverId}
                    documentTypes={documentTypes}
                />
            }

            {/* Döküman modalı */}
            {
                documentModal &&
                <DocumentModal
                    documentModal={documentModal}
                    setDocumentModal={setDocumentModal}
                    documentTypes={documentTypes}
                    selectedDriverId={selectedDriverId}
                    setSelectedDriverId={setSelectedDriverId}
                />
            }

            {/* Delete modal */}
            {
                deleteId && 
                <ConfirmModal 
                    open={deleteId}
                    title={"Emin misiniz?"}
                    setOpen={setSearchParams}
                    message={"Sürücüyü silmek istediğinize emin misiniz?"}
                    handleConfirm = {()=>deleteDriver(deleteId)}
                />
            }
            
        </PageLayout>
    );
}

export default Page;