import React, { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { Marker, Polyline, useMap } from 'react-leaflet';
import Player from '../../molecules/Player';
import styles from './style.module.scss';
import L from 'leaflet';
import VehicleMarker from '../../molecules/VehicleMarkerForHistory';

function VehicleHistory({ vehicleHistory, setVehicleHistory, resetHistory }) {
    const { data, activeIndex } = vehicleHistory;
    const map = useMap();
    const prevClick = () => {
        if (!activeIndex < 1) {
            setVehicleHistory(prev => ({ ...prev, activeIndex: prev.activeIndex - 1 }));
        }
    }
    const nextClick = () => {
        if (activeIndex < data.length - 1) {
            setVehicleHistory(prev => ({ ...prev, activeIndex: prev.activeIndex + 1 }));
        }
    }

    useEffect(() => {
        if (data[activeIndex].latLng && map) {
            let zoom = map.getZoom();
            map.flyTo([data[activeIndex].latLng.lat, data[activeIndex].latLng.lng], zoom);
            map.panInsideBounds([[data[activeIndex].latLng.lat, data[activeIndex].latLng.lng]]);
        }
    }, [activeIndex])

    return (
        <>
            {
                data.map((v, i) =>
                    <React.Fragment key={i}>
                        {
                            data[i + 1] &&
                            <MyPolyline position={[v.latLng.lat, v.latLng.lng]} nextPosition={[data[i + 1].latLng.lat, data[i + 1].latLng.lng]} />
                        }
                        <BallMarker position={[v.latLng.lat, v.latLng.lng]} index={i} setVehicleHistory={setVehicleHistory} />
                    </React.Fragment>
                )
            }
            <VehicleMarker log={data[activeIndex]} resetHistory={resetHistory} />
            <Box className={styles.player}>
                <Player prevClick={prevClick} nextClick={nextClick} vehicleHistory={vehicleHistory} setVehicleHistory={setVehicleHistory} />
            </Box>
        </>
    )
}

export default VehicleHistory;

const MyPolyline = ({ position, nextPosition }) => useMemo(() => {
    return (
        <Polyline opacity={0.75} color={"var(--primary)"} positions={[position, nextPosition]} />
    )
}, [JSON.stringify(position)])


const BallMarker = ({ position, index, setVehicleHistory }) => useMemo(() => {
    const ballIcon = new L.divIcon({
        iconAnchor: [6, 6],
        iconSize: [12, 12],
        shadowAnchor: null,
        className: styles.marker,
    });
    return (
        <Marker eventHandlers={{ click: () => setVehicleHistory(prev => ({ ...prev, activeIndex: index })) }} position={position} icon={ballIcon} />
    )
}, [JSON.stringify(position)])