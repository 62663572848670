import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import styles from "./style.module.scss";
import { BASE_URL } from "../../../api/ApiProvider";
import { CloudDownloadRounded, DateRangeRounded } from "@mui/icons-material";
import moment from "moment";
import PDF from '../../../assets/pdf-foto.png'
import { useAuth } from "../../../context/AuthContext";

function DocumentView({ name, document }) {

    const { currentUser } = useAuth()

    return (
        <Box className={styles.wrapper}>
            <p className={styles.title}>
                {name}
            </p>

            <Box className={styles.container}>
                {
                    document?.url ?
                        <>
                            <Box className={styles.actions}>
                                <a href={`${BASE_URL}/document/getData/file/${document.id}?code=${currentUser.companyCode}`} target="_blank">
                                    <Tooltip title="İndir">
                                        <IconButton>
                                            <CloudDownloadRounded />
                                        </IconButton>
                                    </Tooltip>
                                </a>
                            </Box>
                            <img
                                src={document?.fileName?.substr(document?.fileName?.length - 4) === '.pdf' ? PDF : BASE_URL + document?.url + "?code=" + currentUser.companyCode}
                            />
                        </>
                        :
                        <Typography>Belge bulunamadı</Typography>
                }
            </Box>
            {
                document?.expiration &&
                <Tooltip title="Belge geçerlilik tarihi">
                    <Typography sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "0.5rem", textAlign: "center" }}><DateRangeRounded fontSize="inherit" /> {moment(document?.expiration).format("L")}</Typography>
                </Tooltip>
            }
        </Box>
    );
};

export default DocumentView;