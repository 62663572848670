import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Polyline } from "react-leaflet";
import { useNavigate, useParams } from "react-router";
import { getRouteRequest } from "../../api/controllers/route-controller";
import { usePlans } from "../../context/PlansContext";
import { Customer, LatLng } from "../../types/CommonTypes";
import { Plan, PlanUpdateForm, UpdateForm } from "../../types/PlansContext";
import VehicleMarker from "./VehicleMarker";

type Iprops = {
    plan: PlanUpdateForm | Plan,
    customer: Customer,
    setUpdateForm?: Dispatch<SetStateAction<UpdateForm>>,
}

const RoutePolyline = (props: Iprops) => {
    const navigate = useNavigate();
    const { planUuid, planType } = useParams();
    const stationList = props.plan.stationList || [];
    const { setIsLoading } = usePlans();
    const [position, setPosition] = useState([]);
    const driver_position = props.plan?.vehicle?.driver?.address?.position ? { lat: props.plan.vehicle.driver.address.position.latitude, lng: props.plan.vehicle.driver.address.position.longitude } : null;
    const filteredStationList = stationList && stationList.length > 0 && stationList.filter((c, l) => c.position !== null) || []
    const stations_position = filteredStationList.map(v => ({ lat: v.position.latitude, lng: v.position.longitude }));
    const customer_position = props.customer?.address?.position ? { lat: props.customer.address.position.latitude, lng: props.customer.address.position.longitude } : null;
    const list = (planType === "departure" ? [...stations_position, customer_position] : [customer_position, ...stations_position]);
    const color = '#' + Math.random().toString(16).substr(-6);

    useEffect(() => {
        if (driver_position && stations_position && customer_position && list && list.length > 1) {
            setIsLoading(true);
            (async () => {
                try {
                    const { data } = await getRouteRequest(list);
                    if (data) {
                        setPosition(data.coordinates.map((v: LatLng) => [v.lat, v.lng]))
                        if (props.setUpdateForm) {
                            props.setUpdateForm((prev) => ({ ...prev, plan: { ...prev.plan, duration: data.duration, distance: data.distance } }))
                        }
                    }
                } catch (error) { }
            })()
            setIsLoading(false);
        }
    }, [JSON.stringify(list)])

    return (
        <>
            {
                props.plan?.vehicle?.driver?.address?.position &&
                <>
                    <Polyline eventHandlers={!planUuid ? { click: () => navigate(`${window.location.pathname}/plan/${props.plan.uuid}`) } : {}} color={!planUuid ? color : "var(--primary)"} positions={position} />
                    <VehicleMarker
                        vehicle={props.plan.vehicle}
                        position={[props.plan.vehicle.driver.address.position.latitude, props.plan.vehicle.driver.address.position.longitude]}
                        color={!planUuid ? color : "var(--primary)"}
                    />
                </>
            }

        </>
    )
}

export default React.memo(RoutePolyline);