import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Box,
  Tab
} from "@mui/material";
import { useEffect, useState } from "react";
import { getBgImagesRequest } from "../../api/controllers/background-controller";
import { CostInputsForms } from "../../components/atoms/Settings/CostInputsForms/CostInputForms";
import PageLayout from "../../components/templates/PageLayout";
import { LoginScreenForms } from "../../components/atoms/Settings/LoginScreenForms/LoginScreenForms";
import { AdminSettingsForms } from "../../components/atoms/Settings/AdminSettingsForms/AdminSettingsForms";

function Page() {
  const [value, setValue] = useState("1");
  const [bgImages, setBgImages] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /* const getBgImages = async () => {
    let res = await getBgImagesRequest();
    if (res.data) {
      setBgImages(res.data);
    }
  };

  useEffect(() => {
    getBgImages();
  }, []); */

  return (
    <PageLayout title="Ayarlar">
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange}>
            <Tab label="Maliyet Tablosu" value="1" />
           {/*  <Tab label="Giriş Yap Ekranı" value="2" /> */}
            <Tab label="Yönetici Ayarları" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <CostInputsForms />
        </TabPanel>
        {/* <TabPanel value="2">
          <LoginScreenForms bgImages={bgImages} getBgImages={getBgImages} />
        </TabPanel> */}
        <TabPanel value="3">
          <AdminSettingsForms />
        </TabPanel>
      </TabContext>
    </PageLayout>
  );
}

export default Page;

