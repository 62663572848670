import { useEffect, useState } from 'react';
import PageLayout from '../../components/templates/PageLayout';
import { Grid, Box, Alert, Typography, Divider } from '@mui/material';
import { getCompanyRequest } from '../../api/controllers/company-controller';
import { getDocumentTypesOfAdminRequest } from '../../api/controllers/document-type-controller';
import axios from 'axios';
import DocumentView from '../../components/atoms/DocumentView';
import Label from '../../components/atoms/Label';

function Page() {

    const [data, setData] = useState({});
    const [documentTypes, setDocumentTypes] = useState([]);
    const [documents, setDocuments] = useState([]);

    const getData = async () => {
        let res = await getCompanyRequest();
        if (res) {
            setData(res.data);
        }
    }

    const getDocumentTypes = async () => {
        let res = await getDocumentTypesOfAdminRequest();
        if (res) {
            setDocumentTypes(res.data);
        }
    }

    const getDocuments = async () => {
        let res = await axios.get(`/document/admin/getAll`)
        if (res) {
            setDocuments(res.data);
        }
    }

    useEffect(() => {
        getData();
        getDocumentTypes();
        getDocuments();
    }, []);


    return (
        <PageLayout
            title="Ticari Bilgiler"
        >
            <Grid container spacing={3}>
                <Grid container item xs={12} md={10} lg={8} spacing={3}>
                    <Grid item xs={12} md={6}>
                        <Label title="Şirket Ünvanı" value={data.title} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Label title="Telefon" value={data.phone?.number} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Label title="Vergi Dairesi" value={data.taxAdministration} />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Label title="Vergi Numarası" value={data.taxNumber} />
                    </Grid>
                    <Grid item xs={12} >
                        <Label title="Adres" value={data.address?.fullAddress} />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid container item xs={12}>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="space-between" alignItems="center">
                            <Typography variant="h6">| Belgeler</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" gap="1.5rem" flexWrap="wrap" mt="1rem">
                            {
                                documentTypes.length > 0
                                    ?
                                    documentTypes.map((val, i) =>
                                        <DocumentView
                                            key={i}
                                            name={val.name}
                                            document={documents?.find(v => v?.documentType?.id === val.id)}
                                        />
                                    )
                                    :
                                    <Alert severity="warning">Belge tanımı bulunamadı!</Alert>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </PageLayout>
    )
}

export default Page;