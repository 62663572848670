import { useEffect, useState } from 'react'
import { Box, Divider, TextField, Typography, InputAdornment, Alert, Grid, Autocomplete } from '@mui/material'
import styles from './style.module.scss'
import { getAvailableVehiclesRequest, getVehiclesRequest, updateVehicleRequest } from '../../../../../api/controllers/vehicle-controller';
import VehicleCard from '../../../../molecules/VehicleCard';
import { usePlans } from '../../../../../context/PlansContext';
import PlansSidebarLayout from '../../../../templates/PlansSidebarLayout';
import { ArrowBackRounded } from '@mui/icons-material';
import { getDriversRequest } from '../../../../../api/controllers/driver-controller';
import { toast } from 'react-toastify';
import CustomDialog from '../../../../atoms/CustomDialog';
import { useNavigate, useParams } from 'react-router';

function Step1() {
    const navigate = useNavigate();
    const { workTimeId, planType } = useParams();
    const { updateForm, setUpdateForm, resetUpdateForm } = usePlans();
    const { plan } = updateForm;
    const [vehicles, setVehicles] = useState([]);
    const [searchText, setSearchText] = useState("")
    const [assignVehicleModal, setAssignVehicleModal] = useState(false)
    const [drivers, setDrivers] = useState([])
    const [selectedVehicle, setSelectedVehicle] = useState({})
    const [selectedDriver, setSelectedDriver] = useState(null)

    const filteredVehicles = vehicles.filter(v => v.licensePlate.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));

    useEffect(() => {
        (async () => {
            getVehicles()
            getDrivers()
        })()
    }, [planType])

    //Araçları getirir
    const getVehicles = async () => {
        let res = await getVehiclesRequest();
        if (res) {
            if (plan && res.data.some(v => v.id === plan.vehicle.id)) {
                setVehicles(res.data)
            } else {
                setVehicles([plan.vehicle, ...res.data])
            }
        }
    }

    //Sürücüleri getirir
    const getDrivers = async () => {
        let res = await getDriversRequest();
        if (res) {
            setDrivers(res.data);
        }
    }

    const selectVehicle = (vehicle) => {
        if (vehicle.driver) {
            setUpdateForm(prev => ({ ...prev, plan: { ...prev.plan, vehicle: vehicle } }))

        } else {
            openAssignVehicleModal(vehicle)
            setSelectedVehicle(vehicle)
        }
    }

    const openAssignVehicleModal = () => {
        setAssignVehicleModal(true)
    }

    const closeAssignVehicleModal = () => {
        setAssignVehicleModal(false)
    }

    const assignDriverToVehicle = async () => {
        let res = await updateVehicleRequest({ ...selectedVehicle, driver: selectedDriver });
        if (res && res.status === 200) {
            closeAssignVehicleModal();
            getVehicles()
            toast.success("Araca sürücü başarıyla atandı!")
        }
    }

    return (
        <>
            <PlansSidebarLayout
                header={{
                    iconButton: {
                        icon: <ArrowBackRounded fontSize="inherit" />,
                        props: {
                            onClick: () => {
                                navigate(window.location.pathname.split("/duzenle")[0])
                                resetUpdateForm();
                            },
                        }
                    },
                    title: {
                        text: plan?.title || "Güzergahı Düzenle"
                    },
                }}
                footer={{
                    buttons: [
                        {
                            text: "İptal et",
                            props: {
                                onClick: () => {
                                    navigate(window.location.pathname.split("/duzenle")[0]);
                                    resetUpdateForm();
                                },
                                variant: "outlined",
                                color: "secondary",
                            }
                        },
                        {
                            text: "Devam et",
                            props: {
                                onClick: () => setUpdateForm(prev => ({ ...prev, step: prev.step + 1 })),
                                variant: "contained",
                            }
                        },
                    ]
                }}
            >
                <Box className={styles.container}>
                    <Box className={styles.header}>
                        <Typography variant="h6">
                            | Güzergah için isim belirleyin
                        </Typography>
                        <TextField
                            fullWidth
                            size="small"
                            placeholder="Güzergah ismini yazın"
                            value={plan?.title || ""}
                            onChange={e => setUpdateForm(prev => ({ ...prev, plan: { ...prev.plan, title: e.target.value } }))}
                        />
                        <Divider flexItem />
                        <Typography variant="h6">
                            | Güzergah için servis seçin
                        </Typography>
                        <TextField
                            variant="standard"
                            className={styles.search}
                            fullWidth
                            size="small"
                            placeholder="Servisler içerisinde arayın..."
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            InputProps={{
                                startAdornment: <InputAdornment position="start"><i className="bi bi-search" /></InputAdornment>,
                            }}
                        />
                    </Box>
                    <Box className={styles.list}>
                        {
                            filteredVehicles.length > 0
                                ?
                                filteredVehicles.map((v, i) =>
                                    <VehicleCard
                                        key={i}
                                        vehicle={v}
                                        onClick={() => selectVehicle(v)}
                                        isActive={plan?.vehicle?.id === v.id}
                                    />
                                )
                                :
                                <Alert severity="warning">Araç Bulunamadı!</Alert>
                        }
                    </Box>
                </Box>
            </PlansSidebarLayout>
            <CustomDialog
                size="sm"
                open={assignVehicleModal}
                closeFunc={closeAssignVehicleModal}
                title="Bilgi."
                buttons={[
                    {
                        type: "button",
                        onClick: assignDriverToVehicle,
                        variant: "contained",
                        text: "Kaydet",
                    }
                ]}
            >
                <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                        <Alert severity='info' >
                            Seçtiğiniz aracın sürüsü bulunmamaktadır, aşağıdan sürücüsünü atayabilirsiniz.
                        </Alert>
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <Autocomplete
                            name="driver"
                            options={drivers}
                            renderInput={(params) =>
                                <TextField
                                    required
                                    {...params}
                                    label="Sürücü"
                                    placeholder="Listeden seçin"
                                    fullWidth
                                />}
                            getOptionLabel={(option) => option.firstName + " " + option.lastName}
                            isOptionEqualToValue={(option, value) => option?.id === value?.id}
                            value={selectedDriver}
                            onChange={(event, newValue) =>
                                setSelectedDriver(newValue)
                            }
                        />
                    </Grid>
                </Grid>
            </CustomDialog>
        </>
    )
}

export default Step1;