import axios from "axios";

export const deleteWorkTimeRequest = (id) => axios.delete(`/workTime/${id}`)

export const getWorkTimesPageableRequest = (pageable) => axios.get(`/workTime?page=${pageable.page}&size=${pageable.size}`)

export const getWorkTimeRequest = (id) => axios.get(`/workTime/${id}`)

export const getWorkTimesByCustomerIdRequest = (customerId) => axios.get(`/workTime/customer/${customerId}`)

export const createWorkTimeRequest = (customerId, form) => axios.post(`/workTime/${customerId}`, form)

export const updateWorkTimeRequest = (form) => axios.put(`/workTime/${form.id}`, form)