import { Delete, InfoRounded } from '@mui/icons-material';
import EditIcon from '@mui/icons-material/Edit';
import { Alert } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from "react-toastify";
import { getCustomersRequest } from '../../../api/controllers/customer-controller';
import { getDepartmentsByCustomerIdRequest } from '../../../api/controllers/department-controller';
import { deleteTravelerRequest, getTravelersPageableRequest } from '../../../api/controllers/traveler-controller';
import CustomDataGrid from '../../../components/atoms/CustomDataGrid';
import ConfirmModal from '../../../components/atoms/DeleteConfirmModal';
import PopoverMenu from '../../../components/atoms/PopoverMenu';
import Filter from '../../../components/molecules/Filter';
import FilterList from '../../../components/molecules/FilterList';
import Search from '../../../components/molecules/Search';
import PageLayout from '../../../components/templates/PageLayout';
import { useAuth } from '../../../context/AuthContext';
import CustomerRequestModal from './customerRequestmodal';
import OgrenciEkle from './ogrenci-ekle';
import PersonelDuzenle from './personel-duzenle';
import PersonelEkle from './personel-ekle';
import DetailModal from './yolcu-detayi';

function Page() {
    const navigate = useNavigate();
    const { currentUser } = useAuth();
    const { role, customerType, id } = currentUser;

    const [searchParams, setSearchParams] = useSearchParams();
    const queryId = searchParams.get("id");
    const deleteId = searchParams.get("deleteId");
    const editId = searchParams.get("editId");
    //States
    const [selectedRows, setSelectedRows] = useState([]);
    const [isTableLoading, setIsTableLoading] = useState(true);
    const [data, setData] = useState([]);
    const [pageable, setPageable] = useState({ page: 0, size: 10, total: 0 });
    const [overTimeModal, setOverTimeModal] = useState(false)
    const [filter, setFilter] = useState({
        search: "",
        customerIdList: [],
        departmentIdList: []
    });
    const [customers, setCustomers] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [addEmployeeModal, setAddEmployeeModal] = useState(false)
    const [addStudentModal, setAddStudentModal] = useState(false)
    const [createType, setCreateType] = useState(false)

    //Tablo sütunları
    const columns = [
        {
            field: 'firstName',
            headerName: "İsim",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value || "-",
        },
        {
            field: 'lastName',
            headerName: "Soyisim",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value || "-",
        },
        {
            field: 'type',
            headerName: "Tipi",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ row }) => row?.workTime?.customer ? (row?.workTime?.customer?.customerType === "Company" ? "Personel" : "Öğrenci") : "-",
        },
        {
            field: 'workTime',
            headerName: "Kurum İsmi",
            flex: 1,
            minWidth: 200,
            valueGetter: ({ value }) => value?.customer?.title || "-",
        },
        {
            field: 'id',
            headerName: "",
            sortable: false,
            align: 'right',
            renderCell: ({ value }) => <PopoverMenu
                items={
                    role === "CUSTOMER" ? [
                        {
                            icon: <InfoRounded fontSize="inherit" />,
                            text: "Detaylar",
                            onClick: () => setSearchParams({ id: value }),
                        },
                        {
                            icon: <Delete fontSize="inherit" />,
                            text: "Sil",
                            onClick: () => setSearchParams({ deleteId: value }),
                        },
                        {
                            icon: <EditIcon fontSize="inherit" />,
                            text: "Düzenle",
                            onClick: () => setSearchParams({ editId: value }),
                        }
                    ] : [
                        {
                            icon: <InfoRounded fontSize="inherit" />,
                            text: "Detaylar",
                            onClick: () => setSearchParams({ id: value }),
                        },
                        {
                            icon: <Delete fontSize="inherit" />,
                            text: "Sil",
                            onClick: () => setSearchParams({ deleteId: value }),
                        }
                    ]
                }
            />,
        },
    ]

    const getTravelers = async (type) => {
        if (type === "clear") {
            filter.customerIdList = [];
            filter.departmentIdList = [];
            setFilter(filter);
        }
        setIsTableLoading(true);
        try {
            let res = await getTravelersPageableRequest(pageable, filter);
            if (res) {
                setPageable({ ...pageable, total: res.data.totalElements });
                setData(res.data.content)
            }
        } catch (error) { }
        setIsTableLoading(false);
    }

    const deleteTraveler = async (deleteId) => {
        let res = await deleteTravelerRequest(deleteId);
        if (res) {
            setData(data.filter(item => item.id?.toString() !== deleteId));
            setSearchParams(false);
            toast.success("Yolcu silindi");
        }
    }

    useEffect(() => {
        getTravelers()
    }, [pageable.page, pageable.size, filter.search])

    useEffect(() => {
        if (role === "ADMIN" || role === "MANAGER") {
            (async () => {
                let res = await getCustomersRequest();
                if (res) {
                    setCustomers(res.data)
                }
            })()
        } else if (role === "CUSTOMER") {
            (async () => {
                let rex = await getDepartmentsByCustomerIdRequest(id);
                if (rex) {
                    setDepartments(rex.data)
                }
            })()
        } else { }


    }, [])




    return (
        <PageLayout
            title="Yolcular"
            buttons={role === "CUSTOMER" && customerType === 'School' ? [
                {
                    onClick: () => navigate("etutler"),
                    color: "secondary",
                    variant: "outlined",
                    text: "Etütler",
                },
                {
                    onClick: () => {
                        setOverTimeModal(true);
                        setCreateType("ETUT")
                    },
                    color: "secondary",
                    variant: "contained",
                    disabled: selectedRows.length < 1,
                    text: "Etüt Oluştur",
                },
                {
                    onClick: () => customerType === 'Company' ? setAddEmployeeModal(true) : customerType === 'School' ? setAddStudentModal(true) : {},
                    variant: "contained",
                    startIcon: < i className="bi bi-plus-lg" ></i >,
                    text: "Yolcu Ekle",
                }

            ] :
                role === "CUSTOMER" && customerType === 'Company' ? [
                    {
                        onClick: () => navigate("mesailer"),
                        color: "secondary",
                        variant: "outlined",
                        text: "Mesailer",
                    },
                    {
                        onClick: () => {
                            setOverTimeModal(true);
                            setCreateType("MESAI")
                        },
                        color: "secondary",
                        variant: "contained",
                        disabled: selectedRows.length < 1,
                        text: "Mesai Oluştur",
                    },
                    {
                        onClick: () => customerType === 'Company' ? setAddEmployeeModal(true) : customerType === 'School' ? setAddStudentModal(true) : {

                        },
                        variant: "contained",
                        startIcon: < i className="bi bi-plus-lg" ></i >,
                        text: "Yolcu Ekle",
                    }

                ]
                    : role === "ADMIN" || role === "MANAGER" ? [
                        /* {
                            onClick: () => setOverTimeModal(true),
                            color: "secondary",
                            variant: "contained",
                            // startIcon: < i className="bi bi-plus-lg" ></i >,
                            text: "Mesai Oluştur",
                        }, */
                    ] : []}
        >
            <Stack spacing={2}>
                {
                    (role === "ADMIN" || role === "MANAGER") &&
                    <Alert
                        severity="info"
                        color="info"
                    >
                        Yeni yolcu kaydını <Link style={{ color: "inherit", fontWeight: 500 }} to="/app/musteriler">Müşteriler</Link> sayfasındaki ilgili müşteri detayına girerek <span style={{ fontWeight: 500 }}>Yolcular</span> sekmesi altından yapabilirsiniz!
                    </Alert>
                }
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                    <Search
                        onChange={e => setFilter(prev => ({ ...prev, search: e }))}
                    />
                    {
                        (role === "ADMIN" || role === "MANAGER" || role === "CUSTOMER") &&
                        <Filter
                            isEmpty={role === "ADMIN" || role === "MANAGER" ? filter.customerIdList.length === 0 : role === "CUSTOMER" ? filter.departmentIdList.length === 0 : false}
                            onClear={() => getTravelers("clear")}
                            onSubmit={getTravelers}
                        >
                            {
                                role === "CUSTOMER" ?
                                    <FilterList
                                        title="Departmana göre"
                                        data={departments}
                                        getLabel={v => v.title}
                                        filter={filter}
                                        setFilter={setFilter}
                                        filterKey="departmentIdList"
                                    />
                                    :
                                    <FilterList
                                        title="Müşterilere göre"
                                        data={customers}
                                        getLabel={v => v.title}
                                        filter={filter}
                                        setFilter={setFilter}
                                        filterKey="customerIdList"
                                    />
                            }
                        </Filter>
                    }
                </Stack>
                <CustomDataGrid
                    selectedRows={selectedRows}
                    setSelectedRows={setSelectedRows}
                    data={data}
                    columns={columns}
                    loading={isTableLoading}
                    rowId={(params) => params?.id}
                    pageable={pageable}
                    setPageable={setPageable}
                />
            </Stack>
            {
                queryId && data.length > 0 &&
                <DetailModal
                    queryId={queryId}
                    data={data}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    role={role}
                />
            }
            {
                deleteId &&
                <ConfirmModal
                    open={deleteId}
                    title={"Emin misiniz?"}
                    setOpen={setSearchParams}
                    message={"Yolcuyu silmek istediğinize emin misiniz?"}
                    handleConfirm={() => deleteTraveler(deleteId)}
                />
            }
            {
                editId && role === "CUSTOMER" &&
                <PersonelDuzenle
                    open={editId ? true : false}
                    setOpen={setSearchParams}
                    getTravelers={getTravelers}
                    customer={{
                        id: currentUser.id,
                        customer: currentUser,
                        editId: editId
                    }}
                />
            }
            {
                overTimeModal &&
                <CustomerRequestModal
                    open={overTimeModal}
                    setOpen={setOverTimeModal}
                    travelerList={data.filter(v => selectedRows.some(val => val === v.id))}
                    setSelectedRows={setSelectedRows}
                    selectedRows={selectedRows}
                    type={createType}
                />
            }
            {
                addEmployeeModal &&
                <PersonelEkle
                    open={addEmployeeModal}
                    setOpen={setAddEmployeeModal}
                    getTravelers={getTravelers}
                    customer={{
                        id: currentUser.id
                    }}
                />
            }
            {
                addStudentModal &&
                <OgrenciEkle
                    open={addStudentModal}
                    setOpen={setAddStudentModal}
                    getTravelers={getTravelers}
                    customer={{
                        id: currentUser.id
                    }}
                />
            }
        </PageLayout>
    )
}

export default Page;