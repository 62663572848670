import React from 'react';
import no_data from '../../../../assets/no-data.svg';
import { Box, Button, Typography } from '@mui/material';
import { AddRounded } from '@mui/icons-material';
import { usePlans } from '../../../../context/PlansContext';
import PlansSidebarLayout from '../../../templates/PlansSidebarLayout';
import PlanCard from '../../../molecules/PlanCard';
import { toast } from 'react-toastify';
import styles from './style.module.scss';
import { useAuth } from '../../../../context/AuthContext';
import { useNavigate, useParams } from 'react-router';
import { Link } from 'react-router-dom';

function ListPlans() {
    const navigate = useNavigate();
    const { workTimeId, planType } = useParams();
    const { currentUser } = useAuth();
    const { role } = currentUser;
    const { plans } = usePlans();

    return (
        <PlansSidebarLayout
            header={{
                button: (role === "ADMIN" || role === "MANAGER") ? {
                    text: "Oluştur",
                    props: {
                        onClick: () => {
                            if (!workTimeId) {
                                toast.warn("Öncelikle vardiya seçmelisiniz! Eğer yoksa oluşturmalısınız!")
                            } else {
                                navigate("olustur")
                            }
                        },
                        startIcon: <AddRounded fontSize="inherit" />,
                        variant: "contained",
                    }
                } : null
            }}
        >
            {
                plans[planType]?.length === 0 ?
                    <Empty navigate={navigate} workTimeId={workTimeId} />
                    :
                    <Box className={styles.list}>
                        {
                            plans[planType]?.map(v =>
                                <Link key={v.id} style={{ textDecoration: "none" }} to={`plan/${v.uuid}`}>
                                    <PlanCard
                                        plan={v}
                                    />
                                </Link>
                            )
                        }
                    </Box>
            }
        </PlansSidebarLayout >
    )
}

export default React.memo(ListPlans);

const Empty = ({ navigate, workTimeId }) => {
    const { currentUser } = useAuth();
    const { role } = currentUser;

    return (
        <Box display="flex" flexDirection="column" alignItems="center" gap="1rem" p="1rem">
            <img src={no_data} alt="" />
            <Typography>
                Henüz güzergah bulunmuyor!
            </Typography>
            {
                (role === "ADMIN" || role === "MANAGER") &&
                <Button onClick={() => {
                    if (!workTimeId) {
                        toast.warn("Öncelikle vardiya seçmelisiniz! Eğer yoksa oluşturmalısınız!")
                    } else {
                        navigate("olustur")
                    }
                }} variant="outlined" sx={{ bgcolor: "var(--primary_12)" }} startIcon={<AddRounded fontSize="inherit" />}>
                    Yeni güzergah oluştur
                </Button>
            }
        </Box>
    )
}