import { createContext, useContext, useState } from 'react';
import { getPlansByQueryRequest } from '../api/controllers/plan-controller';
import { getStationByPlanIdRequest } from '../api/controllers/station-controller';
import { getTravelersRequest } from '../api/controllers/traveler-controller';
import { FilterMarker, PlanType, Station } from '../types/CommonTypes';
import { ContextType, CreateForm, Plan, PlanUpdateForm, StationForm, Traveler, } from '../types/PlansContext';

const Context = createContext<ContextType | null>(null);

type Props = {
    children: React.ReactNode
}

export default function Provider({ children }: Props) {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [filterMarker, setFilterMarker] = useState<FilterMarker>({
        traveler: {
            available: true,
            unavailable: true
        },
        station: true,
        routes: true,
    })
    const [showSidebar, setShowSidebar] = useState<boolean>(true);
    const [travelers, setTravelers] = useState<Traveler[]>([]);
    const [plan, setPlan] = useState<Plan>();
    const [plans, setPlans] = useState<{ departure: Plan[]; arrival: Plan[] }>({
        departure: [],
        arrival: [],
    });
    const [station, setStation] = useState<Station>();
    const [createForm, setCreateForm] = useState<CreateForm>({
        title: "",
        vehicle: null,
    });
    const [updateForm, setUpdateForm] = useState<{ step: number, plan: PlanUpdateForm, route: any[] | null }>({
        step: 1,
        plan: {
            id: null,
            distance: null,
            duration: null,
            endTime: null,
            startTime: null,
            vehicle: null,
            workTime: null,
            stationIdList: [],
            stationList: [],
            title: "",
        },
        route: null,
    });
    const [stationForm, setStationForm] = useState<StationForm>({
        travelerIds: [],
        position: { latitude: 0, longitude: 0 },
        lat: null,
        lng: null,
        sequence: null,
        stationDirectionType: "DEPARTURE",
        title: ""
    });

    const resetCreateForm = () => setCreateForm({
        title: "",
        vehicle: null,
    });
    const resetUpdateForm = () => setUpdateForm({
        step: 1,
        plan: {
            id: null,
            distance: null,
            duration: null,
            endTime: null,
            startTime: null,
            vehicle: null,
            workTime: null,
            stationIdList: [],
            stationList: [],
            title: "",
        },
        route: null,
    });
    const resetStationForm = () => setStationForm({
        travelerIds: [],
        position: { latitude: 0, longitude: 0 },
        lat: null,
        lng: null,
        sequence: null,
        stationDirectionType: "DEPARTURE",
        title: ""
    });

    const getTravelers = async (workTimeId: number) => {
        setIsLoading(true);
        try {
            let res = await getTravelersRequest(`workTime.id=='${workTimeId}'`);
            if (res) {
                setTravelers(res.data);
            }
        } catch (error) { }
        setIsLoading(false);
    }

    const getPlans = async (workTimeId: number) => {
        let res = await getPlansByQueryRequest(`workTime.id=='${workTimeId}'`);
        if (res) {
            setPlans({
                departure: res.data.filter((v: any) => v.planDirectionType === "DEPARTURE"),
                arrival: res.data.filter((v: any) => v.planDirectionType === "ARRIVAL"),
            });
        }
    }

    const getPlan = async (planUuid: string, planType: PlanType) => {
        setIsLoading(true);
        let res = await getPlansByQueryRequest(`uuid=='${planUuid}';planDirectionType=='${planType ? planType.toUpperCase() : "DEPARTURE"}'`);
        if (res) {
            setIsLoading(false);
            let stations = await getStations(res.data[0].id);
            res.data[0].stationList = stations;
            setPlan(res.data[0]);
            return res.data[0];
        }
        setIsLoading(false);
    };

    const getStations = async (planId: any) => {
        setIsLoading(true);
        let res = await getStationByPlanIdRequest(planId);
        if (res) {
            setIsLoading(false);
            return res.data
        }
        setIsLoading(false);
    }

    const data: ContextType = {
        isLoading,
        setIsLoading,
        filterMarker,
        setFilterMarker,
        showSidebar,
        setShowSidebar,
        travelers,
        setTravelers,
        plan,
        setPlan,
        plans,
        setPlans,
        station,
        setStation,
        createForm,
        setCreateForm,
        resetCreateForm,
        updateForm,
        setUpdateForm,
        stationForm,
        setStationForm,
        resetUpdateForm,
        resetStationForm,
        getTravelers,
        getPlans,
        getPlan,
        getStations,
    }

    return (
        <Context.Provider value={data}>
            {children}
        </Context.Provider>
    )
}

export const usePlans = () => useContext(Context) as ContextType